import React,{useState,useEffect} from 'react';
import { getFormattedDate } from '../../utility/formateDateAndTime';
import { IFlight } from '../../interface/IFlight';
import { serviceProvider } from '../../provider/serviceProvider';
import  { Dayjs } from 'dayjs';
import DataGridTable from '../DataGridTable/DataGridTable';
import { Button, Tooltip } from '@mui/material';
import editIcon from "../../assets/icons/edit-icon.svg";
import { ManageFlight } from './ManageFlight';
import { formatNumber } from '../../utility/formateNumber';







const tableCellStyle = {
  borderRight: '1px solid rgba(224, 224, 224, 1)',
};
interface OfferedFlightTableProps {
  flights: IFlight[];
  pageState:any,
  setPageState:any
}



const EmptyLegTable= ({pageState,setPageState,flights,indexNumber,getEmptyLegFlights}:any) => {

  const rows = flights.map((flight:any) => ({
    ...flight,
    id: flight.flightId,
    startDt:getFormattedDate(flight.itinerary.startDt),
    startAirportId:flight?.itinerary?.startAirport?.name,
    //endAirportId:flight.itinerary.endAirport.name,
    flightDuration:flight.itinerary.flightDuration || "--",
    price:formatNumber(flight?.price),
    oldPrice:formatNumber(flight?.oldPrice)

    // startDt:getFormattedDate(flight.startDt)
  }));

  const columns = [
    //   { field: 'id',
    //    headerName: '',
    //   //  width: 90
    //  },
      {
        field: 'startDt',
        headerName: 'Start date',
        width: 200,
        editable: false,
        
      },
      {
        field: 'startAirportId',
        headerName: 'Start airport',
        width: 250,
        editable: false,
        renderCell: (params: any) => {
          return (
            <Tooltip title={params.row?.startAirportId}>
              <span>{params.row?.startAirportId}</span>
            </Tooltip>
          );
        },
      },
     
      // old new price   
      {
        field: 'oldPrice',
        headerName: 'Old Price (INR)',
        width: 200,
        // width: 150,
        editable: false,
      },
      {
        field: 'price',
        headerName: 'Price (INR)',
        width: 200,
        // width: 150,
        editable: false,
      },
      {
        field: 'manage',
        type: 'actions',
        headerName: 'Manage',
        width: 200,
        getActions: (params: any) =>  [
          <ManageFlight flightId={params.row.flightId} isActive={params.row.active} indexNumber={indexNumber} pageState={pageState} getEmptyLegFlights={getEmptyLegFlights} />
        ],
      },
      
      // {
      //   field: 'manage',
      //   type: 'actions',
      //   headerName: 'Manage',
      //   width: 200,
      //   getActions: (params: any) =>  [
      //     <Button onClick={()=>window.location.href=`empty-leg-management/edit/${params.row.flightId}`}>
      //     <img src={editIcon} alt="edit icon" height="20px" width="20px" />
      //   </Button>
      //     // <ManageUser userId={params.row.uId} roleId={params.row.roleId} />
      //     // <EditOfferedFlight flightDetails={params.row} />
      //   ],
      // },
     
    ];


  
  const newRows=  rows?.slice(pageState.page * pageState.pageSize, (pageState.page + 1) * pageState.pageSize);


 




  return (
    <>
      <DataGridTable pageState={pageState} setPageState={setPageState} rows={newRows} columns={columns}/>
    </>

  );
}
export default EmptyLegTable;
