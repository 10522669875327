import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Button, Grid, IconButton, Modal } from "@mui/material";
import { serviceProvider } from "../../provider/serviceProvider";
import EditQuotation from "./GenerateQuotation/EditQuotationModal"; // Import the EditQuotation component
import styles from "./GenerateQuotation/GenerateQuotation.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { IAirport } from "../../interface/IAirport";
import EditValidityInfo from "./EditValidityInfo";
import editIcon from "../../assets/icons/edit-icon.svg";
import GetAppIcon from "@mui/icons-material/GetApp"; // Material-UI download icon

interface IQuotation {
  instantQuotationId: number;
  recordCreatedTs: string;
  pdfS3Url: string;
  requestPayload: any; // For passing specific quotation request data
}

interface ListQuotationProps {
  pageSize?: number; // Optional prop to set initial page size
}



const ListQuotation: React.FC<ListQuotationProps> = ({ pageSize = 10 }) => {
  const [quotations, setQuotations] = useState<IQuotation[]>([]);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0); // Current page
  const [quotationGroups, setQuotationGroups] = useState<any[]>([]); 
  const [quotationValidity, setQuotationValidity] = useState<any[]>([]); 
  const [selectedQuotationGroup, setSelectedQuotationGroup] = useState<any>(null); 
  const [selectedQuotationValidity, setSelectedQuotationValidity] = useState<any>(null); 
  const [selectedInstantQuotationId, setSelectedInstantQuotationId] = useState<any>(null); 
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal for editing quotation
  const [isValidityModalOpen, setIsValidityModalOpen] = useState(false); // Modal for editing validity info
  const [selectedQuotationBaggageInfo, setSelectedQuotationBaggageInfo] = useState(""); // Modal for editing validity info

  // Fetch quotations and validity info
  const fetchQuotations = (page: number, pageSize: number) => {
    setLoading(true);
    const offset = page ;
    const param = `search?instantQuotationId=0&fromAirportId=NA&fromAirportName=NA&fromAirportIcaoCode=NA&fromAirportCity=NA&toAirportId=NA&toAirportName=NA&toAirportIcaoCode=NA&toAirportCity=NA&quotationStartDt=NA&quotationEndDt=NA&offset=${offset}&limit=${pageSize}&order=recordUpdatedTs.desc&profile=BASIC`;
    serviceProvider.InstantQuotationService.get(param)
      .then((res: any) => {
        if (res.responseCode === 200) {
          setQuotations(res.data.data || []);
          setTotalRecordCount(res.data.totalRecordCount || 0);
          const extractedQuotationRequests = res.data.data.map((item: any) => item.requestPayload.quotationsRequest);
          const extractedValidityInfo = res.data.data.map((item: any) => item.requestPayload.validityInfo);
          setQuotationGroups(extractedQuotationRequests.flat());
          setQuotationValidity(extractedValidityInfo);
        }
      })
      .catch(() => {
        setQuotations([]);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchQuotations(page, pageSize); // Fetch data for current page
  }, [page, pageSize]);
  const [airports, setAirports] = useState<IAirport[]>([]);
  const [fleetAircrafts, setFleetAircrafts] = useState<any[]>([]);
  const getAllFleetAircrafts = () => {
    const param = `aircrafts/assign?operatorId=BMJ&status=true`;
    serviceProvider.aircraftService
      .get(param)
      .then((res: any) => {
        if (res.responseCode === 200) {
          setFleetAircrafts(res.data);
        }
      })
      .catch((err: any) => {});
  };
  const getAllAirportsList = () => {
    serviceProvider.airportService
      .getAllAirports()
      .then((res) => {
        if (res.responseCode === 200) {
          setAirports(res.data);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getAllAirportsList();
    getAllFleetAircrafts();
  }, []);
  // Handle edit button click (Quotation)
  const handleEditClick = (quotationGroup: any,instantQuotationId:number,baggageInfo:any) => {
    const correspondingValidityInfo = quotationValidity.find((validity: any) => 
      validity && validity.validityNumber === quotationGroup[0]?.validityNumber
    );
    setSelectedQuotationGroup(quotationGroup); // Store selected quotation group
    setSelectedQuotationValidity(correspondingValidityInfo); // Store corresponding validity info
    setSelectedQuotationBaggageInfo(baggageInfo); // Store corresponding validity info
    setIsModalOpen(true); // Open the modal for editing quotation
    setSelectedInstantQuotationId(instantQuotationId);
  };

  const handleSaveQuotation = (updatedQuotation: any) => {
    console.log("Updated Quotation:", updatedQuotation);
    setIsModalOpen(false); // Close the first modal
    setQuotationGroups(updatedQuotation);
    setIsValidityModalOpen(true); // Open the second modal for editing validity info
  };

  // Handle validity info modal save
  const handleSaveValidityInfo = (updatedValidityInfo: any) => {
    console.log("Updated Validity Info:", updatedValidityInfo);
    setIsValidityModalOpen(false); // Close the validity modal after saving
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const closeValidityModal = () => {
    setIsValidityModalOpen(false); // Close validity modal
    setIsModalOpen(true);
  };

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "Serial Number",
      width: 200,
      renderCell: (params) => page * pageSize + (params.api.getRowIndex(params.id) + 1),
    },
    {
      field: "instantQuotationId",
      headerName: "Quotation ID",
      width: 200,
    },
    {
      field: "recordCreatedTs",
      headerName: "Generated On",
      width: 300,
      valueGetter: (params) => {
        const date = new Date(params.row.recordCreatedTs);
        return date.toLocaleString("en-GB", { timeZone: "Asia/Kolkata" });
      },
    },

{
  field: "pdf",
  headerName: "Download",
  width: 300,
  renderCell: (params) => (
    <Grid container alignItems="center" spacing={1}>
      {/* Download Icon */}
      <Grid item>
        <IconButton
          color="primary"
          href={params.row.pdfS3Url}
          target="_blank"
        >
          <GetAppIcon />
        </IconButton>
      </Grid>
      {/* Generate PDF Label */}
      <Grid item>
          Generate PDF
      </Grid>
    </Grid>
  ),
},

    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => (
        <Grid>
          <IconButton
            color="primary"
            onClick={() =>
              handleEditClick(
                params.row.requestPayload.quotationsRequest,
                params.row.instantQuotationId,
                params.row.requestPayload.baggageInfo
               )
            }
          >
            <img
              src={editIcon}
              alt="Edit"
              style={{ width: "24px", height: "24px" }} // Adjust size as needed
            />
          </IconButton>
        </Grid>
      ),
    },
   
  ];

  const rows = quotations.map((quotation, index) => ({
    id: index,
    instantQuotationId: quotation.instantQuotationId,
    recordCreatedTs: quotation.recordCreatedTs || "N/A",
    pdfS3Url: quotation.pdfS3Url || "",
    requestPayload: quotation.requestPayload,
  }));

  return (
    <Box sx={{ height: 500, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        pageSize={pageSize}
        rowCount={totalRecordCount}
        paginationMode="server"
        onPageChange={(newPage) => setPage(newPage)}
        components={{
          Toolbar: GridToolbar,
        }}
      />

      {/* Modal for editing quotation */}
      <Modal open={isModalOpen} onClose={closeModal} className={styles.modalStyling}>
        <Grid container className={styles.modalContainer}>
          <Grid item xs={12}>
            <Button className={styles.modalCloseBtn} onClick={closeModal}>
              <CloseIcon />
            </Button>
          </Grid>
          <Grid item xs={12} className="modal-body">
            <EditQuotation
              quotationGroups={selectedQuotationGroup}
              onUpdateQuotation={handleSaveQuotation}
              airports={airports}
              fleetAircrafts={fleetAircrafts}
              onClose={closeModal}
            />
          </Grid>
        </Grid>
      </Modal>

      {/* Modal for editing validity info */}
      <Modal open={isValidityModalOpen} onClose={closeValidityModal} className={styles.modalStyling}>
        <Grid container className={styles.modalContainer}>
          <Grid item xs={12}>
            <Button className={styles.modalCloseBtn} onClick={closeValidityModal}>
              <CloseIcon />
            </Button>
          </Grid>
          <Grid item xs={12} className="modal-body">
            <EditValidityInfo
            quotationGroups={quotationGroups}
            quotationId={selectedInstantQuotationId}
              validityInfo={selectedQuotationValidity}
              selectedQuotationBaggageInfo={selectedQuotationBaggageInfo}
              onSave={handleSaveValidityInfo}
              onClose={closeValidityModal}
            />
          </Grid>
        </Grid>
      </Modal>
    </Box>
  );
};

export default ListQuotation;

