import { HttpService } from '../commonService/httpService';
import { HttpMethod } from '../../enum/httpMethod';
import { sessionUtility } from '../../utility/sessionUtility';
import { IJobServices } from '../../interface/IService';

export class JobService extends HttpService implements IJobServices {
    urls: { get: string; post: string; delete: string; put: string; patch: string; };
    userDetails = sessionUtility.getAuthentication();
    constructor() {
        super();
        this.urls={
            get:"jobs",
            post:"jobs",
            put:"jobs",
            delete:"",
            patch:"",
        }
        if (!this.userDetails) {
            this.userDetails = sessionUtility.getAuthentication();
        }
    }
    post(entity: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    patch(param: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    
    header: Headers | string[][] | Record<string, string>=sessionUtility.getHeaderWithToken(this.userDetails);
    get(param: any): Promise<any> {
        const url=this.setUrlParam(param,this.urls.get);
        return this.fetch(HttpMethod.Get, url, this.header) as Promise<any>;
    }
    put<E>(param:any,entity: E): Promise<any> {
        const url = this.setUrlParam(param, this.urls.put)
        return this.putQuery(url, this.header, entity) as Promise<any>;
    }
    
    postJob(jobId: string, entity: any): Promise<any> {
        const url = `jobs/${jobId}`;
        return this.postQuery(url, this.header, entity) as Promise<any>;
      }
    UpdateStatus(id:any,status:any,actionType: any): Promise<any> {
        const url = `jobs/${id}/status?status=${status}&actionType=${actionType}`;
        return this.patchQuery(url, this.header) as Promise<any>;
    }
    delete(param: any): Promise<any> {
        const url = this.setUrlParam(param, this.urls.delete);
        return this.postQuery(url, this.header) as Promise<any>;
    }
} 