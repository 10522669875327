import { Box, Button, ButtonBase, FormControl, Grid, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import QueryTable from "./QueryTable";
import { serviceProvider } from "../../provider/serviceProvider";
import DataGridTable from "../DataGridTable/DataGridTable";
import DatePickerComponent from "../common/DatePickerComponent";
import dayjs from "dayjs";
import Filters from "./Filters";
import { getFormattedDate } from "../../utility/formateDateAndTime";
import editIcon from "../../assets/icons/edit-icon.svg";
import viewIcon from "../../assets/icons/view-query.svg";
import ButtonComponent from "../common/ButtonComponent";
import CommonModal from "../modal/CommonModal";
import { toast } from "react-toastify";

const columns = [
    //   { field: 'id',
    //    headerName: '',
    //   //  width: 90
    //  },
    {
        field: 'queryId',
        headerName: 'Query ID',
        width: 200,
        editable: false,
      
    },
    {
        field: 'fullName',
        headerName: 'Name',
        width: 200,
        editable: false,
        renderCell: (params: any) => {
          return (
            <Tooltip title={params.row?.fullName}>
              <span>{params.row?.fullName}</span>
            </Tooltip>
          );
        },

    },
    {
        field: 'email',
        headerName: 'Email',
        width: 250,
        editable: false,
        renderCell: (params: any) => {
          return (
            <Tooltip title={params.row?.email}>
              <span>{params.row?.email}</span>
            </Tooltip>
          );
        },
    },
    {
        field: 'contact',
        headerName: 'Contact',
        // type: 'number',
        width: 150,
        // width: 300,
        editable: false,
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 130,
        // width: 150,
        editable: false,
    },
    {
        field: 'queryDescription',
        headerName: 'Query',
        width: 250,
        // width: 150,
        editable: false,
        renderCell: (params: any) => {
          return (
            <Tooltip title={params.row?.queryDescription}>
              <span>{params.row?.queryDescription}</span>
            </Tooltip>
          );
        },
    },
    {
        field: "recordCreatedts",
        headerName: "Query raised on",
        width: 200,
        // width: 150,
        editable: false,
        renderCell: (params:any)=>{
        return getFormattedDate(params.row.recordCreatedTs)
    }
      },
    //   {
    //     field: 'manage',
    //     type: 'actions',
    //     headerName: 'Manage',
    //     width: 200,
    //     getActions: (params: any) =>  [
    //       <ManageUser userId={params.row.uId} roleId={params.row.roleId} isActive={params.row.isActive} />
    //     ],
    //   },

];

const GeneralQuery = () => {
  const [pageState, setPageState] = React.useState({
    loader: false,
    data: [],
    totalPages: 0,
    page: 0,
    pageSize: 5,
  });

  const [status, setStatus] = useState("PENDING");
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [queryId, setQueryId] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
 
  const handleOpenViewModal = (rowData: any) => {
    setSelectedData(rowData);
    setOpen(true);
  };
  
  const handleCloseModal = () => {
    setOpen(false);
    setSelectedData(null);
  };

  const rows = pageState?.data?.map((query: any, index: any) => ({
    ...query,
    id: query.queryId,
    recordCreatedTs: getFormattedDate(query.recordCreatedTs),
    // roleName: JSON.parse(user.role).roleName,
    // roleId : JSON.parse(user.role).roleId,
  }));

    const newColumns=[...columns,    
      {
        field: "viewDetails",
        type: "actions",
        headerName: "View Details",
        width: 200,
        getActions: (params: any) => [
          <Button onClick={() => handleOpenViewModal(params.row)}>
            <img src={viewIcon} alt="view icon" height="20px" width="20px" />
          </Button>,
        ],
      },
      {
        field: 'manage',
        type: 'actions',
        headerName: 'Manage',
        width: 200,
        getActions: (params: any) =>  [
            // console.log(params)
        //   <ManageUser userId={params.row.uId} roleId={params.row.roleId} isActive={params.row.isActive} />

        //     params?.row?.status === "PENDING" &&  <Button onClick={handleOpenConfirmModal}>
        //     <img src={editIcon} alt="edit icon" height="20px" width="20px" />
        //   </Button>

        <Button onClick={() => handleOpenConfirmModal(params.row)}>
          <img src={editIcon} alt="edit icon" height="20px" width="20px" />
        </Button>,
      ],
    },
  ];

  const handleOpenConfirmModal = (row: any) => {
    setOpenConfirmModal(true);
    setQueryId(row.queryId);
  };
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  const handleSelectChange = (e: any) => {
    setStatus(e.target.value);
  };
  const handleFromDateChange = (date: any) => {
    setFromDate(date);
  };
  const handleToDateChange = (date: any) => {
    setToDate(date);
  };

  const getAllQueries = (param: any) => {
    // const query = `search?uId=NA&email=all&fullName=all&isActive=${false}&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordUpdatedts.desc&profile=BASIC`
    // setLoader(true);
    setPageState((prev) => ({ ...prev, loader: true }));

    serviceProvider.commonService
      .get(param)
      .then((res: any) => {
        //   setLoader(false)
        if (res.responseCode === 200) {
          // setInActiveUsers(res.data.details)
          setPageState((prev) => ({
            ...prev,
            loader: false,
            data: res.data.data,
            totalPages: res.data.totalRecordCount,
          }));
        }
        setPageState((prev) => ({ ...prev, loader: false }));
      })
      .catch((err) => {
        // setLoader(false)
        setPageState((prev) => ({ ...prev, loader: false }));
        console.log({ err });
      });
  };

    const handleUpdateQueryStatus =(queryId:any)=>{
        // alert("hello")
        const param= `query/${queryId}/status?status=RESOLVED`
        setLoader(true)
        serviceProvider.commonService.put(param,"").then((res:any)=>{
          if(res.responseCode === 200){
            toast.success(res.message)
           handleCloseConfirmModal()
          //  setTimeout(()=>{
          //   // window.location.reload()
          //   window.location.href=`/query-management?index=${0}`
          //  },100)
          const param = `allqueries/search?status=${status}&queryType=GENERAL&from=all&to=all&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`;
          getAllQueries(param);
          }else{
            toast.error(res?.message || "Something Went Wrong !!")
          }
          setLoader(false)
        }).catch((err)=>{
          toast.error(err?.data?.message || "Something Went Wrong !!")
        })
    }

  //   const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //     // const searchQuery = e.target.value;
  //    const param = `allqueries?status=${status}&queryType=GENERAL&from=all&to=all&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`
  //     getAllQueries(param)
  //   }

  useEffect(() => {
    const formattedFromDate = fromDate
      ? dayjs(new Date(fromDate).toISOString()).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        )
      : "all";
    const formattedToDate = toDate
      ? dayjs(new Date(toDate).toISOString()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      : "all";
    const param = `allqueries/search?status=${status}&queryType=GENERAL&from=${formattedFromDate}&to=${formattedToDate}&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`;
    getAllQueries(param);
    // getActiveUsers();
  }, [status, fromDate, toDate, pageState.page, pageState.pageSize]);

  const confirmModalBody = (
    <Grid container rowSpacing={4} className="confirm-request-modal">
      <Grid item xs={12}>
        <Typography variant="h6" component="h6">
          Resolve Query
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" component="p" textAlign="center">
          Are you sure you want to resolve this query?
        </Typography>
      </Grid>
      <Grid item xs={12} className="btn-container">
        <ButtonComponent
          className="rounded-btn-outlined"
          fullWidth={false}
          label="Cancel"
          onClick={handleCloseConfirmModal}
        />
        <ButtonComponent
          fullWidth={false}
          label="Resolve"
          loader={loader}
          disabled={loader}
          onClick={() => handleUpdateQueryStatus(queryId)}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
<CommonModal
      open={open}
      handleCloseModal={handleCloseModal}
      modalBody={
        selectedData ? (
          <Grid container >
            <Grid item xs={12} borderBottom="1px solid #CA3657" mb={1} pb={1}>
  <Typography variant="body1" fontWeight="bold" color="#CA3657">
    Message From:{" "}
    <Typography component="span" fontWeight="bold" color="black">
      {selectedData.fullName}
    </Typography>
  </Typography>
</Grid>

<Grid container item xs={12} mb={1} pb={1} display="flex" alignItems="center">
  <Typography variant="body1" fontWeight="700" mr={1}>
    Email:
  </Typography>
  <Typography variant="body1">{selectedData.email}</Typography>
</Grid>

<Grid container item xs={12} mb={1} pb={1} display="flex" alignItems="center">
  <Typography variant="body1" fontWeight="700" mr={1}>
    Contact:
  </Typography>
  <Typography variant="body1">{selectedData.contact}</Typography>
</Grid>

<Grid container item xs={12} mb={1} pb={1} display="flex" alignItems="center">
  <Typography variant="body1" fontWeight="700" mr={1}>
    Status:
  </Typography>
  <Typography variant="body1">{selectedData.status}</Typography>
</Grid>

<Grid container item xs={12} mb={1} pb={1} display="flex" alignItems="center">
  <Typography variant="body1" fontWeight="700" mr={1}>
    Query raised on:
  </Typography>
  <Typography variant="body1">
    {getFormattedDate(selectedData.recordCreatedTs)}
  </Typography>
</Grid>

<Grid container item xs={12} mb={1} pb={1} display="flex" alignItems="center">
  <Typography variant="body1" fontWeight="700" mr={1}>
    Query:
  </Typography>
  <Typography variant="body1">{selectedData.queryDescription}</Typography>
</Grid>

          </Grid>
        ) : (
          <p>Loading...</p>
        )
      }
    />
          <CommonModal
        open={openConfirmModal}
        handleCloseModal={handleCloseConfirmModal}
        modalBody={confirmModalBody}
      />
      <Grid container rowSpacing={2}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          {/* <Grid container columnSpacing={4} display="flex" justifyContent="flex-end">
                    <Grid item xs={2}>
                        
                        <DatePickerComponent
                            className="date-input"
                            value={fromDate}
                            placeholder="From"
                            onChange={(date: any) => handleFromDateChange(date)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DatePickerComponent
                            className="date-input"
                            value={toDate}
                            placeholder="To"
                            onChange={(date: any) => handleToDateChange(date)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Box
                            sx={{
                                border: "1px solid gray",
                                borderRadius: "20px",
                                // width: "200px",
                            }}
                        >
                            <FormControl fullWidth>
                                <Select
                                    sx={{ padding: "0 10px" }}
                                    value={status}
                                    // name={name}
                                    slotProps={{
                                        input: {
                                            id: "",
                                        },
                                    }}
                                    // id={id}
                                    variant="standard"
                                    disableUnderline
                                    onChange={handleSelectChange}
                                >
                                    <MenuItem value="PENDING">PENDING</MenuItem>
                                    <MenuItem value="RESOLVED">RESOLVED</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid> */}
          {/* const Filters = ({fromDate,toDate,handleFromDateChange,handleToDateChange,handleSelectChange,status}:any) => { */}

          <Filters
            fromDate={fromDate}
            toDate={toDate}
            handleFromDateChange={handleFromDateChange}
            handleToDateChange={handleToDateChange}
            handleSelectChange={handleSelectChange}
            status={status}
          />
        </Grid>
        <Grid item xs={12}>
          <DataGridTable
            pageState={pageState}
            setPageState={setPageState}
            rows={rows}
            columns={status === "RESOLVED" ? columns : newColumns}
          />
          {/* <QueryTable
          pageState={pageState}
          setPageState={setPageState}
          queries={pageState.data}
        /> */}
        </Grid>
      </Grid>
    </>
  );
};

export default GeneralQuery;
