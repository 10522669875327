import React, { useState } from 'react'
import { Typography, Grid } from "@mui/material";
import InputFieldComponent from '../common/InputFieldComponent';
import ButtonComponent from '../common/ButtonComponent';
import { serviceProvider } from '../../provider/serviceProvider';
import { toast } from "react-toastify";
import dayjs, { Dayjs } from 'dayjs';
import DateTimePickerComponent from '../common/DateTimePickerComponent';
import { IValidationResponse } from '../../interface/IValidation';
import { ValidationType } from '../../enum/validationType';
import { ValidationUtiltiy } from '../../utility/ValidationUtility';



    

const initalUserInput = {
    active: true,
    airportHandlingCharges: 0.0,
    airportId: "",
    city: "",
    contactDetails: "",
    governingBody: "",
    iataCode: "",
    icaoCode: "",
    imageUrlList: "",
    latitude: 0.0,
    longitude: 0.0,
    name: "",
    nightLanding: true,
    operatingEndTime: "",
    operatingStartTime: "",
    otherInformation: "",
    permanentNotoms: "",
    restrictedAircraftsList: "",
    runwayLength: 0,
    state: "",
    timezone: "",
    baseLocation: true,
    handler: "",
}

const AddAirportForm = ({ handleCloseModal ,getAllAirports}: any) => {
    const [userInput, setUserInput] = useState(initalUserInput);
    const [loader, setLoader] = useState(false);
    const [startDateTime, setStartDateTime] = useState<Dayjs | any>(null);
    const [arrivalDateTime, setArrivalDateTime] = useState<Dayjs | any>(null);

    console.log(userInput)
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setUserInput({ ...userInput, [name]: value })
        validateFieldsOnInput(name)
    }

    const validateFieldsOnInput = (key: string) => {
        switch (key) {
            case 'name':
                validateName();
                break;
            case 'city':
                validateCity();
                break;
            case 'state':
                validateState();
                break;
            case 'runwayLength':
                validateRunwayLength();
                break;
            case 'latitude':
                validateLatitude();
                break;
            case 'longitude':
                validateLongtiude();
                break;
            case 'airportHandlingCharges':
                validateHandlingCharge();
                break;
            default:
                break;
        }
    };
    const handleStartDateTime = (newDate: Dayjs | null) => {
        setStartDateTime(newDate);
    }
    const handleArrivalDateTime = (newDate: Dayjs | null) => {
        setArrivalDateTime(newDate);
    }

    const handleAddAirport = () => {
        const formattedStartDateTime = startDateTime ? dayjs(new Date(startDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
        const formattedArrivalDateTime = arrivalDateTime ? dayjs(new Date(arrivalDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
        const payload = { ...userInput, operatingStartTime: formattedStartDateTime , operatingEndTime: formattedArrivalDateTime  }

        isValidRequest().then(() => {
            setLoader(true)
            serviceProvider.airportService.post(payload).then((res) => {
                // console.log({ res })
                if (res.responseCode === 200) {

                    toast.success(res.message)
                    handleCloseModal();
                    const query = `airportName=all&city=all&state=all&runwayLength=0&operatingStartTime=all&operatingEndTime=all&aircraftName=all&offset=0&limit=10&order=updatedTs.desc&profile=BASIC`
                    getAllAirports(query)
                    // setTimeout(() => {
                    //     window.location.reload()
                    // }, 100)
                } else {
                    toast.error(res?.message||"Something Went Wrong !!")
                }
                setLoader(false)

            }).catch((err) => {
                toast.error(err?.data?.message || "Something Went Wrong!!")
                console.log({ err })
                setLoader(false)
            })
        }).catch(() => {
            toast.error('Please fill all the Required Fields!');
        })
    }


    return (
        <Grid container spacing={1}>
            <Grid item xs={12} className="modal-table-container">
                <Grid container className='heading'>
                    <Grid item xs={12}>
                        <Typography variant='h6' component='h6'>Add Airport</Typography>
                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Name
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <div>
                            <InputFieldComponent
                                id="airport-name"
                                type="text"
                                name="name"
                                value={userInput.name}
                                onChange={handleInputChange}
                            />
                            <span className="modalError" id='errorDiv-airport-name'></span>
                        </div>


                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            City
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <div>
                            <InputFieldComponent
                                id="airport-city"
                                type="text"
                                name="city"
                                value={userInput.city}
                                onChange={handleInputChange}
                            />
                            <span className="modalError" id='errorDiv-airport-city'></span>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            State
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <div>
                            <InputFieldComponent
                                id="airport-state"
                                type="text"
                                name="state"
                                value={userInput.state}
                                onChange={handleInputChange}
                            />
                            <span className="modalError" id='errorDiv-airport-state'></span>
                        </div>

                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">

                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            IATA Code
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <InputFieldComponent
                        type="text"
                            name="iataCode"
                            maxLength={3}
                            value={userInput.iataCode}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            ICAO Code
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <InputFieldComponent
                            type="text"
                            name="icaoCode"
                            maxLength={4}
                            value={userInput.icaoCode}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Governing Body
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <InputFieldComponent
                        type="text"
                            name="governingBody"
                            value={userInput.governingBody}
                            onChange={handleInputChange}
                        />

                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Runway Length (in meters)
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <div>
                            <InputFieldComponent
                                id="airport-runway-length"
                                type="number"
                                name="runwayLength"
                                value={userInput.runwayLength}
                                onChange={handleInputChange}
                            />
                            <span className="modalError" id='errorDiv-airport-runway-length'></span>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">

                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Latitude
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <div>
                            <InputFieldComponent
                                id="airport-latitude"
                                type="number"
                                name="latitude"
                                value={userInput.latitude}
                                onChange={handleInputChange}
                            />
                            <span className="modalError" id='errorDiv-airport-latitude'></span>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Longitude
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <div>
                            <InputFieldComponent
                                id="airport-longitude"
                                type="number"
                                name="longitude"
                                value={userInput.longitude}
                                onChange={handleInputChange}
                            />
                            <span className="modalError" id='errorDiv-airport-longitude'></span>
                        </div>

                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Operating StartTime
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <DateTimePickerComponent
                            placeholder="Start Time"
                            value={startDateTime}
                            onChange={handleStartDateTime}
                        />

                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Operating EndTime
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <DateTimePickerComponent
                            placeholder="End Time"
                            value={arrivalDateTime}
                            onChange={handleArrivalDateTime}
                        />
                    </Grid>
                </Grid>

                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Timezone
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <InputFieldComponent
                            name="timezone"
                            type="text"
                            value={userInput.timezone}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Handling Charges
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                <div>
                <InputFieldComponent
                            id="airport-handling-charge"
                            name="airportHandlingCharges"
                            type="number"
                            value={userInput.airportHandlingCharges}
                            onChange={handleInputChange}
                        />
                        <span className="modalError" id='errorDiv-airport-handling-charge'></span>
                </div>

                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">

                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Contact Details
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <InputFieldComponent
                            name="contactDetails"
                            type="text"
                            placeholder="Mobile Number, Email"
                            // maxLength={10}
                            value={userInput.contactDetails}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>

                <Grid container className="edit-detail-item">

                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Other Information
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <InputFieldComponent
                            name="otherInformation"
                            type="text"
                            value={userInput.otherInformation}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">

                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Permanent Notoms
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <InputFieldComponent
                            name="permanentNotoms"
                            type="text"
                            value={userInput.permanentNotoms}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12} >
                <Grid container columnSpacing={2} display="flex" justifyContent="center" alignItems="center" mt="20px">
                    <Grid item xs={2}>
                        <ButtonComponent loader={loader} disabled={loader} label="Save" onClick={handleAddAirport} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AddAirportForm;




const isValidRequest = (): Promise<boolean> => {
    let responseArray: IValidationResponse[] = [];
    responseArray.push(validateName());
    responseArray.push(validateCity());
    responseArray.push(validateState());
    responseArray.push(validateRunwayLength());
    responseArray.push(validateLatitude());
    responseArray.push(validateLongtiude());
    responseArray.push(validateHandlingCharge());
    // responseArray.push(ValidationUtiltiy.validateElement("signup-confirmPassword", ValidationType.Text, true, "errorDiv-cpwd", "Confirm Password"));
    let haveErrors = responseArray.filter((x) => {
        return !x.isValid;
    }).length <= 0;
    return new Promise((resolve, reject) => {
        if (haveErrors) {
            resolve(true);
        } else {
            reject(false);
        }
    });
}

const validateName = () => {
    return ValidationUtiltiy.validateElement("airport-name", ValidationType.Text, true, "errorDiv-airport-name", "Name");
}
const validateCity = () => {
    return ValidationUtiltiy.validateElement("airport-city", ValidationType.Text, true, "errorDiv-airport-city", "City");
}
const validateState = () => {
    return ValidationUtiltiy.validateElement("airport-state", ValidationType.Text, true, "errorDiv-airport-state", "State");
}
const validateRunwayLength = () => {
    return ValidationUtiltiy.validateElement("airport-runway-length", ValidationType.Text, true, "errorDiv-airport-runway-length", "Runway Length");
}

const validateLatitude = () => {
    return ValidationUtiltiy.validateElement("airport-latitude", ValidationType.Text, true, "errorDiv-airport-latitude", "Latitude");
}
const validateLongtiude = () => {
    return ValidationUtiltiy.validateElement("airport-longitude", ValidationType.Text, true, "errorDiv-airport-longitude", "Longitude");
}
const validateHandlingCharge = () => {
    return ValidationUtiltiy.validateElement("airport-handling-charge", ValidationType.Text, true, "errorDiv-airport-handling-charge", "Handling Charges");
}



// import React, { useState } from 'react'
// import { Typography, Grid } from "@mui/material";
// import InputFieldComponent from '../common/InputFieldComponent';
// import ButtonComponent from '../common/ButtonComponent';
// import { serviceProvider } from '../../provider/serviceProvider';
// import { toast } from "react-toastify";
// import dayjs, { Dayjs } from 'dayjs';
// import DateTimePickerComponent from '../common/DateTimePickerComponent';
// import { IValidationResponse } from '../../interface/IValidation';
// import { ValidationType } from '../../enum/validationType';
// import { ValidationUtiltiy } from '../../utility/ValidationUtility';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';


// const initalUserInput = {
//     active: true,
//     airportHandlingCharges: 0,
//     airportId: "",
//     city: "",
//     contactDetails: "",
//     governingBody: "",
//     iataCode: "",
//     icaoCode: "",
//     imageUrlList: "",
//     latitude: "",
//     longitude: "",
//     name: "",
//     nightLanding: true,
//     operatingEndTime: "",
//     operatingStartTime: "",
//     otherInformation: "",
//     permanentNotoms: "",
//     restrictedAircraftsList: "",
//     runwayLength: "",
//     state: "",
//     timezone: ""
// }

// const AddAirportForm = ({ handleCloseModal }: any) => {

//     const [loader, setLoader] = useState(false);
//     const [startDateTime, setStartDateTime] = useState<Dayjs | any>(null);
//     const [arrivalDateTime, setArrivalDateTime] = useState<Dayjs | any>(null);

//     // console.log(userInput)


   

//     const formik = useFormik({
//         initialValues: initalUserInput,
//         // validationSchema: validationSchema,
//         onSubmit: (values) => {
//             // Handle form submission
//             console.log('Form submitted successfully', values);
//         },
//         validate:values=>{
            
//         }
//     });

// // console.log(formik.values)
  


//     const handleStartDateTime = (newDate: Dayjs | null) => {
//         setStartDateTime(newDate);
//         formik.setFieldValue('operatingStartTime',newDate);
//     }
//     const handleArrivalDateTime = (newDate: Dayjs | null) => {
//         setArrivalDateTime(newDate);
//         formik.setFieldValue('operatingEndTime',newDate);
//     }

//     const handleAddAirport = () => {
//         const formattedStartDateTime = startDateTime ? dayjs(new Date(startDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
//         const formattedArrivalDateTime = arrivalDateTime ? dayjs(new Date(arrivalDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
//         const payload = { ...formik.values, operatingStartTime: formattedStartDateTime + "", operatingEndTime: formattedArrivalDateTime + "" }

//         setLoader(true)
//         serviceProvider.airportService.post(payload).then((res) => {
//             // console.log({ res })
//             if (res.responseCode === 200) {

//                 toast.success(res.message)
//                 handleCloseModal()
//                 setTimeout(() => {
//                     window.location.reload()
//                 }, 300)
//             } else {
//                 toast.error("Something Went Wrong!!")
//             }
//             setLoader(false)

//         }).catch((err) => {
//             toast.error(err.data.message || "Something Went Wrong!!")
//             console.log({ err })
//             setLoader(false)
//         })
//     }


//     return (
//         <form onSubmit={formik.handleSubmit}>
//               <Grid container spacing={1}>
//             <Grid item xs={12} className="modal-table-container">
//                 <Grid container className='heading'>
//                     <Grid item xs={12}>
//                         <Typography variant='h6' component='h6'>Add Airport</Typography>
//                     </Grid>
//                 </Grid>
//                 <Grid container className="edit-detail-item">
//                     <Grid item xs={6} className='edit-item dark-bg'>
//                         <Typography variant='body1' component='p'>
//                             Name
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={6} className='edit-item'>
//                         <div>
//                             <InputFieldComponent
//                                 id="airport-name"
//                                 name="name"
//                                 value={formik.values.name}
//                                 onChange={formik.handleChange}
//                             />
//                             <span className="modalError" id='errorDiv-airport-name'></span>
//                         </div>


//                     </Grid>
//                 </Grid>
//                 <Grid container className="edit-detail-item">
//                     <Grid item xs={6} className='edit-item dark-bg'>
//                         <Typography variant='body1' component='p'>
//                             City
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={6} className='edit-item'>
//                         <div>
//                             <InputFieldComponent
//                                 id="airport-city"
//                                 name="city"
//                                 value={formik.values.city}
//                                 onChange={formik.handleChange}
//                             />
//                             <span className="modalError" id='errorDiv-airport-city'></span>
//                         </div>
//                     </Grid>
//                 </Grid>
//                 <Grid container className="edit-detail-item">
//                     <Grid item xs={6} className='edit-item dark-bg'>
//                         <Typography variant='body1' component='p'>
//                             State
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={6} className='edit-item'>
//                         <div>
//                             <InputFieldComponent
//                                 id="airport-state"
//                                 name="state"
//                                 value={formik.values.state}
//                                 onChange={formik.handleChange}
//                             />
//                             <span className="modalError" id='errorDiv-airport-state'></span>
//                         </div>

//                     </Grid>
//                 </Grid>
//                 <Grid container className="edit-detail-item">

//                     <Grid item xs={6} className='edit-item dark-bg'>
//                         <Typography variant='body1' component='p'>
//                             IATA Code
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={6} className='edit-item'>
//                         <InputFieldComponent
//                             name="iataCode"
//                             maxLength={3}
//                             value={formik.values.iataCode}
//                             onChange={formik.handleChange}
//                         />

//                     </Grid>
//                 </Grid>
//                 <Grid container className="edit-detail-item">
//                     <Grid item xs={6} className='edit-item dark-bg'>
//                         <Typography variant='body1' component='p'>
//                             ICAO Code
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={6} className='edit-item'>
//                         <InputFieldComponent
//                             name="icaoCode"
//                             maxLength={4}
//                             value={formik.values.icaoCode}
//                             onChange={formik.handleChange}
//                         />

//                     </Grid>
//                 </Grid>
//                 <Grid container className="edit-detail-item">

//                     <Grid item xs={6} className='edit-item dark-bg'>
//                         <Typography variant='body1' component='p'>
//                             Governing Body
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={6} className='edit-item'>
//                         <InputFieldComponent
//                             name="governingBody"
//                             value={formik.values.governingBody}
//                             onChange={formik.handleChange}
//                         />

//                     </Grid>
//                 </Grid>
//                 <Grid container className="edit-detail-item">
//                     <Grid item xs={6} className='edit-item dark-bg'>
//                         <Typography variant='body1' component='p'>
//                             Runway Length
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={6} className='edit-item'>
//                         <div>
//                             <InputFieldComponent
//                                 id="airport-runway-length"
//                                 name="runwayLength"
//                                 value={formik.values.runwayLength}
//                                 onChange={formik.handleChange}
//                             />
//                             <span className="modalError" id='errorDiv-airport-runway-length'></span>
//                         </div>
//                     </Grid>
//                 </Grid>
//                 <Grid container className="edit-detail-item">

//                     <Grid item xs={6} className='edit-item dark-bg'>
//                         <Typography variant='body1' component='p'>
//                             Latitude
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={6} className='edit-item'>
//                         <div>
//                             <InputFieldComponent
//                                 id="airport-latitude"
//                                 name="latitude"
//                                 value={formik.values.latitude}
//                                 onChange={formik.handleChange}
//                             />
//                             <span className="modalError" id='errorDiv-airport-latitude'></span>
//                         </div>
//                     </Grid>
//                 </Grid>
//                 <Grid container className="edit-detail-item">
//                     <Grid item xs={6} className='edit-item dark-bg'>
//                         <Typography variant='body1' component='p'>
//                             Longitude
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={6} className='edit-item'>
//                         <div>
//                             <InputFieldComponent
//                                 id="airport-longitude"
//                                 name="longitude"
//                                 value={formik.values.longitude}
//                                 onChange={formik.handleChange}
//                             />
//                             <span className="modalError" id='errorDiv-airport-longitude'></span>
//                         </div>

//                     </Grid>
//                 </Grid>
//                 <Grid container className="edit-detail-item">
//                     <Grid item xs={6} className='edit-item dark-bg'>
//                         <Typography variant='body1' component='p'>
//                             Operating StartTime
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={6} className='edit-item'>
//                         <DateTimePickerComponent
//                             placeholder="Start Time"
//                             value={startDateTime}
//                             onChange={handleStartDateTime}
//                         />

//                     </Grid>
//                 </Grid>
//                 <Grid container className="edit-detail-item">
//                     <Grid item xs={6} className='edit-item dark-bg'>
//                         <Typography variant='body1' component='p'>
//                             Operating EndTime
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={6} className='edit-item'>
//                         <DateTimePickerComponent
//                             placeholder="End Time"
//                             value={arrivalDateTime}
//                             onChange={handleArrivalDateTime}
//                         />
//                     </Grid>
//                 </Grid>

//                 <Grid container className="edit-detail-item">
//                     <Grid item xs={6} className='edit-item dark-bg'>
//                         <Typography variant='body1' component='p'>
//                             Timezone
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={6} className='edit-item'>
//                         <InputFieldComponent
//                             name="timezone"
//                             value={formik.values.timezone}
//                             onChange={formik.handleChange}
//                         />
//                     </Grid>
//                 </Grid>
//                 <Grid container className="edit-detail-item">
//                     <Grid item xs={6} className='edit-item dark-bg'>
//                         <Typography variant='body1' component='p'>
//                             Handling Charges
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={6} className='edit-item'>
//                 <div>
//                 <InputFieldComponent
//                             id="airport-handling-charge"
//                             name="airportHandlingCharges"
//                             type="number"
//                             value={formik.values.airportHandlingCharges}
//                             onChange={formik.handleChange}
//                         />
//                         <span className="modalError" id='errorDiv-airport-handling-charge'></span>
//                 </div>

//                     </Grid>
//                 </Grid>
//                 <Grid container className="edit-detail-item">

//                     <Grid item xs={6} className='edit-item dark-bg'>
//                         <Typography variant='body1' component='p'>
//                             Contact Details
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={6} className='edit-item'>
//                         <InputFieldComponent
//                             name="contactDetails"
//                             value={formik.values.contactDetails}
//                             onChange={formik.handleChange}
//                         />
//                     </Grid>
//                 </Grid>

//                 <Grid container className="edit-detail-item">

//                     <Grid item xs={6} className='edit-item dark-bg'>
//                         <Typography variant='body1' component='p'>
//                             Other Information
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={6} className='edit-item'>
//                         <InputFieldComponent
//                             name="otherInformation"
//                             value={formik.values.otherInformation}
//                             onChange={formik.handleChange}
//                         />
//                     </Grid>
//                 </Grid>
//                 <Grid container className="edit-detail-item">

//                     <Grid item xs={6} className='edit-item dark-bg'>
//                         <Typography variant='body1' component='p'>
//                             Permanent Notoms
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={6} className='edit-item'>
//                         <InputFieldComponent
//                             name="permanentNotoms"
//                             value={formik.values.permanentNotoms}
//                             onChange={formik.handleChange}
//                         />
//                     </Grid>
//                 </Grid>

//             </Grid>
//             <Grid item xs={12} >
//                 <Grid container columnSpacing={2} display="flex" justifyContent="center" alignItems="center" mt="20px">
//                     <Grid item xs={2}>
//                         <ButtonComponent type="submit" variant="contained" className="rounded-btn-fill" label="Save" 
//                         // onClick={handleAddAirport}
//                          />
//                     </Grid>
//                 </Grid>
//             </Grid>
//         </Grid>
//        {/* <button type="submit">save</button> */}
//         </form>
//     )
// }

// export default AddAirportForm;









