
import React from 'react';
import { Tooltip } from '@mui/material';
import { ManageJob } from './ManageJob';
import DataGridTable from '../../components/DataGridTable/DataGridTable';




const JobsTable = ({ pageState,setPageState,getBlogsList}:any)=> {
  const rows = pageState?.data?.map((job:any, index:any) => ({
    ...job,
    id: job.jobId,
  }));

  const jobTypes = [
    { value: "FULL_TIME", label: "Full Time" },
    { value: "PART_TIME", label: "Part Time" },
    { value: "CONTRACT_BASED", label: "Contract Based" },
    { value: "INTERNSHIP", label: "Internship" },
    { value: "FREELANCE", label: "Freelance" },
  ];

  const columns = [
   
      {
        field: 'title',
        headerName: 'Title',
        width: 400,
        editable: false,
        renderCell: (params: any) => {
          // console.log(params?.row?.title)
          return (
            <Tooltip title={params?.row?.jobTitle}>
              <span>{params?.row?.jobTitle}</span>
            </Tooltip>
          );
        },
      },
      {
        field: 'jobType',
        headerName: 'Job Type',
        width: 250,
        editable: false,
        renderCell: (params: any) => {
          const jobTypeLabel = jobTypes.find(
            (job) => job.value === params.row?.jobType
          )?.label;
      
          return (
            <Tooltip title={jobTypeLabel || 'N/A'}>
              <span>{jobTypeLabel || 'N/A'}</span>
            </Tooltip>
          );
        },
      },
      {
        field: 'jobPostDate',
        headerName: 'Posted On',
        width: 200,
        // width: 150,
        editable: false,
        renderCell: (params: any) => {
          function formatDate(inputDate: any) {
            const dateObj = new Date(inputDate);
            const day = dateObj.getDate();
            const monthName = dateObj.toLocaleString('en-US', { month: 'long' });
            const year = dateObj.getFullYear();
            const formattedDate = `${day} ${monthName}, ${year}`;
            return formattedDate;
          }

          const blogPostedDate = formatDate(params?.row?.recordCreatedTs?.substring(0, 10));
          return (
            <Tooltip title={blogPostedDate}>
              <span>{blogPostedDate}</span>
            </Tooltip>
          );
        },
      },
      
      {
        field: 'manage',
        type: 'actions',
        headerName: 'Manage',
        width: 200,
        getActions: (params: any) =>  [
          <ManageJob blogDetails={params.row} isActive={params.row.active} pageState={pageState} getBlogsList={getBlogsList} />
        ],
      },
     
    ];


 

  return <DataGridTable pageState={pageState} setPageState={setPageState} rows={rows} columns={columns} showToolbar={false}  />
}


export default JobsTable;