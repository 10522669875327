
import React,{useState} from "react"

import { Button, Grid, Tooltip } from '@mui/material';
import blogEditIcon from "../../assets/icons/edit-blog-icon.svg";
import manageApplicationIcon from "../../assets/icons/manage-application.svg"
import blogDeactivateIcon from "../../assets/icons/deactivate-blog-icon.svg";
import blogActivateIcon from "../../assets/icons/activate-blog-icon.svg";
import { useNavigate } from "react-router-dom";
import CommonModal from "../../components/modal/CommonModal";
import DeactivateBlogModalBody from "./DeactivateJob";
import ActivateBlogModalBody from "./ActivateJob";
import EditBlogModalBody from "./EditJob";
import styles from "./Job.module.scss";

export const ManageJob = ({ blogDetails,isActive,pageState,getBlogsList}:any) => {
    const [openDeactivateBlog, setOpenDeactivateBlog] = useState(false);
    const [openActivateBlog, setOpenActivateBlog] = useState(false);
    const handleOpenDeactivateBlogModal = () => setOpenDeactivateBlog(true)
    const handleCloseDeactivateBlogModal = () => setOpenDeactivateBlog(false);
    const [openEditBlog, setOpenEditBlog] = useState(false);
    const navigate= useNavigate()
   
    const handleOpenEditBlogModal = () => setOpenEditBlog(true)
    const handleCloseEditBlogModal = () => setOpenEditBlog(false);


    const handleOpenActivateBlogModal=()=> setOpenActivateBlog(true)
    const handleCloseActivateBlogModal=()=> setOpenActivateBlog(false)
    const handleManageApplications = () => {
      navigate(`/jobs-management/${blogDetails.jobId}?custUrl=${blogDetails.custUrl}`); // Ensure `blogDetails.id` contains the jobId
    };

    return (
       <>
        <CommonModal open={openDeactivateBlog} handleCloseModal={handleCloseDeactivateBlogModal} modalBody={<DeactivateBlogModalBody isActive={isActive} handleCloseModal={handleCloseDeactivateBlogModal}  blogDetails={blogDetails} pageState={pageState} getBlogsList={getBlogsList}/>} />
        <CommonModal open={openActivateBlog} handleCloseModal={handleCloseActivateBlogModal} modalBody={<ActivateBlogModalBody isActive={isActive} handleCloseModal={handleCloseActivateBlogModal} blogDetails={blogDetails}  pageState={pageState} getBlogsList={getBlogsList}/>} />
        <CommonModal className={styles.editModalStyle} open={openEditBlog} handleCloseModal={handleCloseEditBlogModal} modalBody={<EditBlogModalBody isActive={isActive} handleCloseModal={handleCloseEditBlogModal} blogDetails={blogDetails} pageState={pageState} getBlogsList={getBlogsList} />} />
      <Grid container columnSpacing={2}>
        <Grid item xs={4} spacing={2}>
          <Tooltip title="Update Job">
            <Button onClick={handleOpenEditBlogModal}>
              <img src={blogEditIcon} alt="edit icon" height="20px" width="20px" />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Tooltip title={`${isActive ? "Deactivate Job":"Activate Job"}`}>
            <Button onClick={isActive?handleOpenDeactivateBlogModal:handleOpenActivateBlogModal}>
              <img src={isActive ? blogDeactivateIcon : blogActivateIcon} alt="icon" height="20px" width="20px" />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title="Manage Applications">
            <Button onClick={handleManageApplications}>
              <img src={manageApplicationIcon} alt="manage applications icon" height="20px" width="20px" />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
       </>
    );
  };
  