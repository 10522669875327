
import React,{useState} from "react"

import { Button, FormControl, Grid, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';

import { serviceProvider } from "../../provider/serviceProvider";
import {toast} from "react-toastify";
import { sessionUtility } from "../../utility/sessionUtility";

const ActivateBlogModalBody = ({isActive,handleCloseModal,blogDetails,pageState,getBlogsList}:any) => {
const [loader,setLoader] = useState(false);

    const uId = sessionUtility.getAuthentication().session?.uId as string;

    const handleActivateBlog = (jobId: string,isActive:boolean) => {
        const payload = {
          isActive: !isActive,
        };
       
    
        serviceProvider.jobService.UpdateStatus(jobId,true,"IS_ACTIVE").then((res: any) => {

            handleCloseModal();

          if (res?.responseCode === 200) {
            toast.success("Status Updated successfully!");
            const query = `search?offset=${pageState.page}&limit=${pageState.pageSize}&isActive=true&isFeatured=false&jobId=NA&userId=NA&jobTitle=NA&custUrl=NA&minExp=0&maxExp=0&minSalary=0&maxSalary=0&offset=0&limit=10&order=recordCreatedTs.desc&profile=BASIC`;
            getBlogsList(query);
          }
          else {
            toast.error(res?.error?.message || "something went wrong!");
          }
          
        }).catch((err) => {
          toast.error(err?.message || "something went wrong!");
        }).finally(() => {
          setLoader(false);
       
        });
      }
    
    return (
        <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6" textAlign="center">Confirm to Activate job?</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={2} className="btn-container">
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              <Button variant="outlined" className="rounded-btn-outlined" onClick={handleCloseModal}>Cancel</Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" className="rounded-btn-fill" onClick={()=>handleActivateBlog(blogDetails?.jobId,isActive)}>Confirm</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };


  export default ActivateBlogModalBody;