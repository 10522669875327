
import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { ManageBlog } from './ManageBlog';
import DataGridTable from '../../components/DataGridTable/DataGridTable';
import frontendBaseUrl from "../../configuration/config.json";
import previewIcon from "../../assets/icons/preview-icon.svg";


const BlogsTable = ({ pageState,setPageState,getBlogsList}:any)=> {
  console.log("pageState"+JSON.stringify(pageState,null,2));

  const rows = pageState?.data?.map((blog:any, index:any) => ({
    ...blog,
    id: blog.blogpostId,
  }));


  const columns = [
   
      {
        field: 'title',
        headerName: 'Title',
        width: 400,
        editable: false,
        renderCell: (params: any) => {
          // console.log(params?.row?.title)
          return (
            <Tooltip title={params?.row?.title}>
              <span>{params?.row?.title}</span>
            </Tooltip>
          );
        },
      },
      {
        field: 'name',
        headerName: 'Posted By',
        // type: 'number',
        width: 250,
        // width: 300,
        editable: false,
        renderCell: (params: any) => {
          return (
            <Tooltip title={params.row?.name}>
              <span>{params.row?.name}</span>
            </Tooltip>
          );
        },
      },
      {
        field: 'blogPostDate',
        headerName: 'Posted On',
        width: 200,
        // width: 150,
        editable: false,
        renderCell: (params: any) => {
          function formatDate(inputDate: any) {
            const dateObj = new Date(inputDate);
            const day = dateObj.getDate();
            const monthName = dateObj.toLocaleString('en-US', { month: 'long' });
            const year = dateObj.getFullYear();
            const formattedDate = `${day} ${monthName}, ${year}`;
            return formattedDate;
          }

          const blogPostedDate = formatDate(params?.row?.blogPostDate?.substring(0, 10));
          return (
            <Tooltip title={blogPostedDate}>
              <span>{blogPostedDate}</span>
            </Tooltip>
          );
        },
      },
      // {
      //   field: 'noOfBookings',
      //   headerName: 'No. of Bookings',
      //   width: 200,
      //   // width: 150,
      //   editable: false,
      // },
      // {
      //   field: 'bmjRewardPoints',
      //   headerName: 'BMJ reward points',
      //   width: 200,
      //   // width: 150,
      //   editable: false,
      // },
      {
        field: "preview",
        type: "actions",
        headerName: "Preview",
        width: 150,
        getActions: (params: any) => [
          <Button
            onClick={() => {
              // let frontendBaseUrl = frontendBaseUrl.frontendBaseUrl;
              window.open(frontendBaseUrl.frontendBaseUrl + "/blogs/" + params.row.custUrl, "_blank");
            }}
          >
            <img src={previewIcon} alt="preview icon" height="20px" width="20px" />
          </Button>,
        ],
      },
      {
        field: 'manage',
        type: 'actions',
        headerName: 'Manage',
        width: 200,
        getActions: (params: any) =>  [
          <ManageBlog blogDetails={params.row} isActive={params.row.active} pageState={pageState} getBlogsList={getBlogsList} />
        ],
      },
     
    ];


 

  return <DataGridTable pageState={pageState} setPageState={setPageState} rows={rows} columns={columns} showToolbar={false}  />
}


export default BlogsTable;