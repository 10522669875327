export const cities =[
    {
      "name": "Abhaneri",
      "state": "Rajasthan"
    },
    {
      "name": "Abhayapuri",
      "state": "Assam"
    },
    {
      "name": "Abiramam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Abohar",
      "state": "Punjab"
    },
    {
      "name": "Abrama",
      "state": "Gujarat"
    },
    {
      "name": "Abu",
      "state": "Rajasthan"
    },
    {
      "name": "Abu Road",
      "state": "Rajasthan"
    },
    {
      "name": "Achalpur",
      "state": "Maharashtra"
    },
    {
      "name": "Achhnera",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Adalaj",
      "state": "Gujarat"
    },
    {
      "name": "Adampur",
      "state": "Punjab"
    },
    {
      "name": "Adawad",
      "state": "Maharashtra"
    },
    {
      "name": "Addanki",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Adilabad",
      "state": "Telangana"
    },
    {
      "name": "Adirampattinam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Adoni",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Adur",
      "state": "Kerala"
    },
    {
      "name": "Aduthurai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Afzalgarh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Afzalpur",
      "state": "Karnataka"
    },
    {
      "name": "Agar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Agar Panchaitan",
      "state": "Maharashtra"
    },
    {
      "name": "Agartala",
      "state": "Tripura"
    },
    {
      "name": "Agol",
      "state": "Gujarat"
    },
    {
      "name": "Agra",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Aheri",
      "state": "Maharashtra"
    },
    {
      "name": "Ahiri",
      "state": "Maharashtra"
    },
    {
      "name": "Ahmadnagar",
      "state": "Maharashtra"
    },
    {
      "name": "Ahmadpur",
      "state": "Maharashtra"
    },
    {
      "name": "Ahmedabad",
      "state": "Gujarat"
    },
    {
      "name": "Ahmednagar",
      "state": "Maharashtra"
    },
    {
      "name": "Ahmedpur",
      "state": "West Bengal"
    },
    {
      "name": "Ahraura",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ahwa",
      "state": "Gujarat"
    },
    {
      "name": "Aidalpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Airoli",
      "state": "Maharashtra"
    },
    {
      "name": "Airwa",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Aistala",
      "state": "West Bengal"
    },
    {
      "name": "Aizawl",
      "state": "Mizoram"
    },
    {
      "name": "Ajaigarh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Ajitgarh",
      "state": "Punjab"
    },
    {
      "name": "Ajjampur",
      "state": "Karnataka"
    },
    {
      "name": "Ajmer",
      "state": "Rajasthan"
    },
    {
      "name": "Ajnala",
      "state": "Punjab"
    },
    {
      "name": "Ajra",
      "state": "Maharashtra"
    },
    {
      "name": "Akalgarh",
      "state": "Punjab"
    },
    {
      "name": "Akalkot",
      "state": "Maharashtra"
    },
    {
      "name": "Akaltara",
      "state": "Chhattisgarh"
    },
    {
      "name": "Akasahebpet",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Akbarpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Akhnur",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Akividu",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Akkalkot",
      "state": "Maharashtra"
    },
    {
      "name": "Akkarampalle",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Aklera",
      "state": "Rajasthan"
    },
    {
      "name": "Akluj",
      "state": "Maharashtra"
    },
    {
      "name": "Aknapur",
      "state": "West Bengal"
    },
    {
      "name": "Akodia",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Akola",
      "state": "Maharashtra"
    },
    {
      "name": "Akola",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Akolner",
      "state": "Maharashtra"
    },
    {
      "name": "Akot",
      "state": "Maharashtra"
    },
    {
      "name": "Akrani",
      "state": "Maharashtra"
    },
    {
      "name": "Akrund",
      "state": "Gujarat"
    },
    {
      "name": "Alagapuram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Alampur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Alampur",
      "state": "Telangana"
    },
    {
      "name": "Aland",
      "state": "Karnataka"
    },
    {
      "name": "Alandi",
      "state": "Maharashtra"
    },
    {
      "name": "Alandur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Alanganallur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Alangayam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Alangudi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Alangulam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Alappakkam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Alappuzha",
      "state": "Kerala"
    },
    {
      "name": "Alawalpur",
      "state": "Punjab"
    },
    {
      "name": "Aldona",
      "state": "Goa"
    },
    {
      "name": "Ale",
      "state": "Maharashtra"
    },
    {
      "name": "Alibag",
      "state": "Maharashtra"
    },
    {
      "name": "Aliganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Aligarh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Alipur",
      "state": "Delhi"
    },
    {
      "name": "Alipurduar",
      "state": "West Bengal"
    },
    {
      "name": "Alirajpur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Alkuti",
      "state": "Maharashtra"
    },
    {
      "name": "Allahabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Allahganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Allapalli",
      "state": "Maharashtra"
    },
    {
      "name": "Almora",
      "state": "Uttarakhand"
    },
    {
      "name": "Alnavar",
      "state": "Karnataka"
    },
    {
      "name": "Along",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Alot",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Alur",
      "state": "Karnataka"
    },
    {
      "name": "Aluva",
      "state": "Kerala"
    },
    {
      "name": "Alwa Tirunagari",
      "state": "Tamil Nadu"
    },
    {
      "name": "Alwar",
      "state": "Rajasthan"
    },
    {
      "name": "Alwaye",
      "state": "Kerala"
    },
    {
      "name": "Amalapuram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Amalner",
      "state": "Maharashtra"
    },
    {
      "name": "Amanganj",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Amanpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Amarkantak",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Amarnath",
      "state": "Maharashtra"
    },
    {
      "name": "Amarpatan",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Amarpur",
      "state": "Bihar"
    },
    {
      "name": "Amarpur",
      "state": "Tripura"
    },
    {
      "name": "Amarwara",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Amauli",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ambad",
      "state": "Maharashtra"
    },
    {
      "name": "Ambagarh Chauki",
      "state": "Chhattisgarh"
    },
    {
      "name": "Ambah",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Ambahta",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ambajogai",
      "state": "Maharashtra"
    },
    {
      "name": "Ambala",
      "state": "Haryana"
    },
    {
      "name": "Ambarnath",
      "state": "Maharashtra"
    },
    {
      "name": "Ambasa",
      "state": "Tripura"
    },
    {
      "name": "Ambasamudram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Ambattur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Ambedkar Nagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ambegaon",
      "state": "Maharashtra"
    },
    {
      "name": "Ambikapur",
      "state": "Chhattisgarh"
    },
    {
      "name": "Ambur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Amet",
      "state": "Rajasthan"
    },
    {
      "name": "Amethi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Amgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Amguri",
      "state": "Assam"
    },
    {
      "name": "Amla",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Amlagora",
      "state": "West Bengal"
    },
    {
      "name": "Amli",
      "state": "Dadra and Nagar Haveli and Daman and Diu"
    },
    {
      "name": "Amloh",
      "state": "Punjab"
    },
    {
      "name": "Ammapettai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Amod",
      "state": "Gujarat"
    },
    {
      "name": "Amod,",
      "state": "Gujarat"
    },
    {
      "name": "Amravati",
      "state": "Maharashtra"
    },
    {
      "name": "Amravati Division",
      "state": "Maharashtra"
    },
    {
      "name": "Amreli",
      "state": "Gujarat"
    },
    {
      "name": "Amritsar",
      "state": "Punjab"
    },
    {
      "name": "Amroha",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Amroli",
      "state": "Gujarat"
    },
    {
      "name": "Amta",
      "state": "West Bengal"
    },
    {
      "name": "Amtala",
      "state": "West Bengal"
    },
    {
      "name": "Amudalavalasa",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Anakapalle",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Anamalais",
      "state": "Tamil Nadu"
    },
    {
      "name": "Anand",
      "state": "Gujarat"
    },
    {
      "name": "Anandnagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Anandpur Sahib",
      "state": "Punjab"
    },
    {
      "name": "Anantapur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Anantnag",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Andal",
      "state": "West Bengal"
    },
    {
      "name": "Andheri",
      "state": "Maharashtra"
    },
    {
      "name": "Andippatti",
      "state": "Tamil Nadu"
    },
    {
      "name": "Andol",
      "state": "Telangana"
    },
    {
      "name": "Andura",
      "state": "Maharashtra"
    },
    {
      "name": "Anekal",
      "state": "Karnataka"
    },
    {
      "name": "Angamali",
      "state": "Kerala"
    },
    {
      "name": "Angul",
      "state": "Odisha"
    },
    {
      "name": "Angul District",
      "state": "Odisha"
    },
    {
      "name": "Anjad",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Anjangaon",
      "state": "Maharashtra"
    },
    {
      "name": "Anjar",
      "state": "Gujarat"
    },
    {
      "name": "Anjarle",
      "state": "Maharashtra"
    },
    {
      "name": "Anjaw",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Ankleshwar",
      "state": "Gujarat"
    },
    {
      "name": "Ankola",
      "state": "Karnataka"
    },
    {
      "name": "Annamalainagar",
      "state": "Tamil Nadu"
    },
    {
      "name": "Annavasal",
      "state": "Tamil Nadu"
    },
    {
      "name": "Annigeri",
      "state": "Karnataka"
    },
    {
      "name": "Annur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Anshing",
      "state": "Maharashtra"
    },
    {
      "name": "Anta",
      "state": "Rajasthan"
    },
    {
      "name": "Anthiyur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Antri",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Antu",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Anupgarh",
      "state": "Rajasthan"
    },
    {
      "name": "Anuppur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Anupshahr",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Aonla",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Arag",
      "state": "Maharashtra"
    },
    {
      "name": "Arakkonam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Arambagh community development block",
      "state": "West Bengal"
    },
    {
      "name": "Arambol",
      "state": "Goa"
    },
    {
      "name": "Arang",
      "state": "Chhattisgarh"
    },
    {
      "name": "Arangaon",
      "state": "Maharashtra"
    },
    {
      "name": "Arantangi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Araria",
      "state": "Bihar"
    },
    {
      "name": "Araul",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Arcot",
      "state": "Tamil Nadu"
    },
    {
      "name": "Ardhapur",
      "state": "Maharashtra"
    },
    {
      "name": "Argaon",
      "state": "Maharashtra"
    },
    {
      "name": "Arimalam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Ariyalur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Arkalgud",
      "state": "Karnataka"
    },
    {
      "name": "Arki",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Arni",
      "state": "Tamil Nadu"
    },
    {
      "name": "Aron",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Aroor",
      "state": "Kerala"
    },
    {
      "name": "Arrah",
      "state": "Bihar"
    },
    {
      "name": "Arsikere",
      "state": "Karnataka"
    },
    {
      "name": "Artist Village",
      "state": "Maharashtra"
    },
    {
      "name": "Arukutti",
      "state": "Kerala"
    },
    {
      "name": "Arumbavur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Arumuganeri",
      "state": "Tamil Nadu"
    },
    {
      "name": "Aruppukkottai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Aruvankad",
      "state": "Tamil Nadu"
    },
    {
      "name": "Arvi",
      "state": "Maharashtra"
    },
    {
      "name": "Arwal",
      "state": "Bihar"
    },
    {
      "name": "Asalatganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Asandh",
      "state": "Haryana"
    },
    {
      "name": "Asansol",
      "state": "West Bengal"
    },
    {
      "name": "Asarganj",
      "state": "Bihar"
    },
    {
      "name": "Ashoknagar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Ashoknagar Kalyangarh",
      "state": "West Bengal"
    },
    {
      "name": "Ashta",
      "state": "Maharashtra"
    },
    {
      "name": "Ashta",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Ashti",
      "state": "Maharashtra"
    },
    {
      "name": "Asifabad",
      "state": "Telangana"
    },
    {
      "name": "Asika",
      "state": "Odisha"
    },
    {
      "name": "Asind",
      "state": "Rajasthan"
    },
    {
      "name": "Asoda",
      "state": "Maharashtra"
    },
    {
      "name": "Assaye",
      "state": "Maharashtra"
    },
    {
      "name": "Astagaon",
      "state": "Maharashtra"
    },
    {
      "name": "Atarra",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ateli Mandi",
      "state": "Haryana"
    },
    {
      "name": "Athagarh",
      "state": "Odisha"
    },
    {
      "name": "Athni",
      "state": "Karnataka"
    },
    {
      "name": "Atmakur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Atrauli",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Atraulia",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Attayyampatti",
      "state": "Tamil Nadu"
    },
    {
      "name": "Attili",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Attingal",
      "state": "Kerala"
    },
    {
      "name": "Attur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Aundh, Satara",
      "state": "Maharashtra"
    },
    {
      "name": "Aurad",
      "state": "Karnataka"
    },
    {
      "name": "Auraiya",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Aurangabad",
      "state": "Bihar"
    },
    {
      "name": "Aurangabad",
      "state": "Maharashtra"
    },
    {
      "name": "Auras",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Auroville",
      "state": "Tamil Nadu"
    },
    {
      "name": "Ausa",
      "state": "Maharashtra"
    },
    {
      "name": "Avadi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Avanigadda",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Avanoor",
      "state": "Kerala"
    },
    {
      "name": "Avinashi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Awantipur",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Ayakudi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Ayodhya",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ayyampettai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Azamgarh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Azhikkal",
      "state": "Kerala"
    },
    {
      "name": "Azizpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Babai",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Baberu",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Babina",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Babra",
      "state": "Gujarat"
    },
    {
      "name": "Babrala",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Babugarh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bachhraon",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bachhrawan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bada Barabil",
      "state": "Odisha"
    },
    {
      "name": "Badagara",
      "state": "Kerala"
    },
    {
      "name": "Badami",
      "state": "Karnataka"
    },
    {
      "name": "Badarpur",
      "state": "Assam"
    },
    {
      "name": "Badarwas",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Baddi",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Badgam",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Badhni Kalan",
      "state": "Punjab"
    },
    {
      "name": "Badkulla",
      "state": "West Bengal"
    },
    {
      "name": "Badlapur",
      "state": "Maharashtra"
    },
    {
      "name": "Badnapur",
      "state": "Maharashtra"
    },
    {
      "name": "Badnawar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Badnera",
      "state": "Maharashtra"
    },
    {
      "name": "Baduria",
      "state": "West Bengal"
    },
    {
      "name": "Badvel",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Bag",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Baga",
      "state": "Goa"
    },
    {
      "name": "Bagaha",
      "state": "Bihar"
    },
    {
      "name": "Bagalkot",
      "state": "Karnataka"
    },
    {
      "name": "Bagar",
      "state": "Rajasthan"
    },
    {
      "name": "Bagasara",
      "state": "Gujarat"
    },
    {
      "name": "Bagasra",
      "state": "Gujarat"
    },
    {
      "name": "Bagdogra",
      "state": "West Bengal"
    },
    {
      "name": "Bagepalli",
      "state": "Karnataka"
    },
    {
      "name": "Bageshwar",
      "state": "Uttarakhand"
    },
    {
      "name": "Bagewadi",
      "state": "Maharashtra"
    },
    {
      "name": "Bagha Purana",
      "state": "Punjab"
    },
    {
      "name": "Baghpat",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Baghra",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bagli",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bagnan",
      "state": "West Bengal"
    },
    {
      "name": "Bagra",
      "state": "Jharkhand"
    },
    {
      "name": "Bagula",
      "state": "West Bengal"
    },
    {
      "name": "Bah",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bahadurganj",
      "state": "Bihar"
    },
    {
      "name": "Bahadurgarh",
      "state": "Haryana"
    },
    {
      "name": "Baheri",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bahjoi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bahraich",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bahraigh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bahsuma",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bahua",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bahula",
      "state": "West Bengal"
    },
    {
      "name": "Baidyabati",
      "state": "West Bengal"
    },
    {
      "name": "Baihar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Baikunthpur",
      "state": "Chhattisgarh"
    },
    {
      "name": "Baikunthpur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bail-Hongal",
      "state": "Karnataka"
    },
    {
      "name": "Bairagnia",
      "state": "Bihar"
    },
    {
      "name": "Baisi",
      "state": "Bihar"
    },
    {
      "name": "Bajna",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bakani",
      "state": "Rajasthan"
    },
    {
      "name": "Bakewar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bakharla",
      "state": "Gujarat"
    },
    {
      "name": "Bakhtiyarpur",
      "state": "Bihar"
    },
    {
      "name": "Bakloh",
      "state": "Punjab"
    },
    {
      "name": "Bakreswar",
      "state": "West Bengal"
    },
    {
      "name": "Baksa",
      "state": "Assam"
    },
    {
      "name": "Baksar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bakshwaho",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Balachor",
      "state": "Punjab"
    },
    {
      "name": "Balagam",
      "state": "Gujarat"
    },
    {
      "name": "Balaghat",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Balamau",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Balangir",
      "state": "Odisha"
    },
    {
      "name": "Balapur",
      "state": "Maharashtra"
    },
    {
      "name": "Balapur",
      "state": "Telangana"
    },
    {
      "name": "Balapur, Akola district",
      "state": "Maharashtra"
    },
    {
      "name": "Balarampur",
      "state": "West Bengal"
    },
    {
      "name": "Balasinor",
      "state": "Gujarat"
    },
    {
      "name": "Balasore",
      "state": "Odisha"
    },
    {
      "name": "Baldeo",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Baldeogarh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Baldev",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Baleshwar",
      "state": "Odisha"
    },
    {
      "name": "Bali",
      "state": "Rajasthan"
    },
    {
      "name": "Bali Chak",
      "state": "West Bengal"
    },
    {
      "name": "Balimila",
      "state": "Odisha"
    },
    {
      "name": "Balisana",
      "state": "Gujarat"
    },
    {
      "name": "Ballalpur",
      "state": "Maharashtra"
    },
    {
      "name": "Ballard Estate",
      "state": "Maharashtra"
    },
    {
      "name": "Ballari",
      "state": "Karnataka"
    },
    {
      "name": "Ballarpur",
      "state": "Maharashtra"
    },
    {
      "name": "Ballary",
      "state": "Karnataka"
    },
    {
      "name": "Ballia",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bally",
      "state": "West Bengal"
    },
    {
      "name": "Balod",
      "state": "Chhattisgarh"
    },
    {
      "name": "Baloda",
      "state": "Chhattisgarh"
    },
    {
      "name": "Baloda Bazar",
      "state": "Chhattisgarh"
    },
    {
      "name": "Balotra",
      "state": "Rajasthan"
    },
    {
      "name": "Balrampur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Balugaon",
      "state": "Odisha"
    },
    {
      "name": "Balurghat",
      "state": "West Bengal"
    },
    {
      "name": "Bamanbore",
      "state": "Gujarat"
    },
    {
      "name": "Bamangola community development block",
      "state": "West Bengal"
    },
    {
      "name": "Bambolim",
      "state": "Goa"
    },
    {
      "name": "Bamboo Flat",
      "state": "Andaman and Nicobar Islands"
    },
    {
      "name": "Bamna",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bamor Kalan",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bamora",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Banapur",
      "state": "Odisha"
    },
    {
      "name": "Banas Kantha",
      "state": "Gujarat"
    },
    {
      "name": "Banat",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Banavar",
      "state": "Karnataka"
    },
    {
      "name": "Banbasa",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Banda",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Banda",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Banda, Maharashtra",
      "state": "Maharashtra"
    },
    {
      "name": "Bandia",
      "state": "Gujarat"
    },
    {
      "name": "Bandikui",
      "state": "Rajasthan"
    },
    {
      "name": "Bandipore",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Bandipura",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Bandora",
      "state": "Goa"
    },
    {
      "name": "Bandra",
      "state": "Maharashtra"
    },
    {
      "name": "Baner",
      "state": "Maharashtra"
    },
    {
      "name": "Baneswar",
      "state": "West Bengal"
    },
    {
      "name": "Banga",
      "state": "Punjab"
    },
    {
      "name": "Bangalore Rural",
      "state": "Karnataka"
    },
    {
      "name": "Bangalore Urban",
      "state": "Karnataka"
    },
    {
      "name": "Banganapalle",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Bangaon",
      "state": "Bihar"
    },
    {
      "name": "Bangaon",
      "state": "West Bengal"
    },
    {
      "name": "Bangarapet",
      "state": "Karnataka"
    },
    {
      "name": "Bangarmau",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Banihal",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Banjar",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Banka",
      "state": "Bihar"
    },
    {
      "name": "Banki",
      "state": "Odisha"
    },
    {
      "name": "Bankot",
      "state": "Maharashtra"
    },
    {
      "name": "Bankra",
      "state": "West Bengal"
    },
    {
      "name": "Bankura",
      "state": "West Bengal"
    },
    {
      "name": "Banmankhi",
      "state": "Bihar"
    },
    {
      "name": "Bannur",
      "state": "Karnataka"
    },
    {
      "name": "Banposh",
      "state": "Odisha"
    },
    {
      "name": "Bansberia",
      "state": "West Bengal"
    },
    {
      "name": "Bansdih",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bansgaon",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bansi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bansihari community development block",
      "state": "West Bengal"
    },
    {
      "name": "Banswada",
      "state": "Telangana"
    },
    {
      "name": "Banswara",
      "state": "Rajasthan"
    },
    {
      "name": "Banthra",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bantva",
      "state": "Gujarat"
    },
    {
      "name": "Bantval",
      "state": "Karnataka"
    },
    {
      "name": "Banur",
      "state": "Punjab"
    },
    {
      "name": "Bapatla",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Bar Bigha",
      "state": "Bihar"
    },
    {
      "name": "Bara Banki",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bara Uchana",
      "state": "Haryana"
    },
    {
      "name": "Barabazar",
      "state": "West Bengal"
    },
    {
      "name": "Baragaon",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Baragarh",
      "state": "Odisha"
    },
    {
      "name": "Baramati",
      "state": "Maharashtra"
    },
    {
      "name": "Baramula",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Baran",
      "state": "Rajasthan"
    },
    {
      "name": "Baranagar",
      "state": "West Bengal"
    },
    {
      "name": "Barasat",
      "state": "West Bengal"
    },
    {
      "name": "Barauli",
      "state": "Bihar"
    },
    {
      "name": "Baraut",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Barbil",
      "state": "Odisha"
    },
    {
      "name": "Bardhaman",
      "state": "West Bengal"
    },
    {
      "name": "Bardoli",
      "state": "Gujarat"
    },
    {
      "name": "Bareilly",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Barela",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bargarh",
      "state": "Odisha"
    },
    {
      "name": "Barghat",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bargi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Barh",
      "state": "Bihar"
    },
    {
      "name": "Barhalganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Barhi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Barhiya",
      "state": "Bihar"
    },
    {
      "name": "Bari",
      "state": "Rajasthan"
    },
    {
      "name": "Bari Sadri",
      "state": "Rajasthan"
    },
    {
      "name": "Bariarpur",
      "state": "Bihar"
    },
    {
      "name": "Barjala",
      "state": "Tripura"
    },
    {
      "name": "Barjora",
      "state": "West Bengal"
    },
    {
      "name": "Barka Kana",
      "state": "Jharkhand"
    },
    {
      "name": "Barkhera",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Barkhera Kalan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Barki Saria",
      "state": "Jharkhand"
    },
    {
      "name": "Barkot",
      "state": "Uttarakhand"
    },
    {
      "name": "Barmer",
      "state": "Rajasthan"
    },
    {
      "name": "Barnala",
      "state": "Punjab"
    },
    {
      "name": "Barokhar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Barpali",
      "state": "Odisha"
    },
    {
      "name": "Barpathar",
      "state": "Assam"
    },
    {
      "name": "Barpeta",
      "state": "Assam"
    },
    {
      "name": "Barpeta Road",
      "state": "Assam"
    },
    {
      "name": "Barrackpore",
      "state": "West Bengal"
    },
    {
      "name": "Barsana",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Barsi",
      "state": "Maharashtra"
    },
    {
      "name": "Baruipur",
      "state": "West Bengal"
    },
    {
      "name": "Baruni",
      "state": "Bihar"
    },
    {
      "name": "Barwadih",
      "state": "Jharkhand"
    },
    {
      "name": "Barwala",
      "state": "Haryana"
    },
    {
      "name": "Barwani",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Barwar (Lakhimpur Kheri)",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Basanti",
      "state": "West Bengal"
    },
    {
      "name": "Basar",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Basavakalyan",
      "state": "Karnataka"
    },
    {
      "name": "Basavana Bagevadi",
      "state": "Karnataka"
    },
    {
      "name": "Basi",
      "state": "Rajasthan"
    },
    {
      "name": "Basirhat",
      "state": "West Bengal"
    },
    {
      "name": "Basmat",
      "state": "Maharashtra"
    },
    {
      "name": "Basmath",
      "state": "Maharashtra"
    },
    {
      "name": "Basna",
      "state": "Chhattisgarh"
    },
    {
      "name": "Basni",
      "state": "Rajasthan"
    },
    {
      "name": "Basoda",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bastar",
      "state": "Chhattisgarh"
    },
    {
      "name": "Basti",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Basudebpur",
      "state": "Odisha"
    },
    {
      "name": "Basugaon",
      "state": "Assam"
    },
    {
      "name": "Baswa",
      "state": "Rajasthan"
    },
    {
      "name": "Batala",
      "state": "Punjab"
    },
    {
      "name": "Batoti",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Baud",
      "state": "Odisha"
    },
    {
      "name": "Baudh",
      "state": "Odisha"
    },
    {
      "name": "Bavdhan",
      "state": "Maharashtra"
    },
    {
      "name": "Bawal",
      "state": "Haryana"
    },
    {
      "name": "Bawali",
      "state": "West Bengal"
    },
    {
      "name": "Bawana",
      "state": "Delhi"
    },
    {
      "name": "Bawanbir",
      "state": "Maharashtra"
    },
    {
      "name": "Bayana",
      "state": "Rajasthan"
    },
    {
      "name": "Bazpur",
      "state": "Uttarakhand"
    },
    {
      "name": "Beawar",
      "state": "Rajasthan"
    },
    {
      "name": "Bedi",
      "state": "Gujarat"
    },
    {
      "name": "Beed",
      "state": "Maharashtra"
    },
    {
      "name": "Begamganj",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Begampur",
      "state": "West Bengal"
    },
    {
      "name": "Begowal",
      "state": "Punjab"
    },
    {
      "name": "Begun",
      "state": "Rajasthan"
    },
    {
      "name": "Begusarai",
      "state": "Bihar"
    },
    {
      "name": "Behat",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Behror",
      "state": "Rajasthan"
    },
    {
      "name": "Bela",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Belagavi",
      "state": "Karnataka"
    },
    {
      "name": "Belaguntha",
      "state": "Odisha"
    },
    {
      "name": "Belda",
      "state": "West Bengal"
    },
    {
      "name": "Beldanga",
      "state": "West Bengal"
    },
    {
      "name": "Beliatore",
      "state": "West Bengal"
    },
    {
      "name": "Bellampalli",
      "state": "Telangana"
    },
    {
      "name": "Belluru",
      "state": "Karnataka"
    },
    {
      "name": "Belonia",
      "state": "Tripura"
    },
    {
      "name": "Belsand",
      "state": "Bihar"
    },
    {
      "name": "Beltangadi",
      "state": "Karnataka"
    },
    {
      "name": "Belthara",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Belur",
      "state": "Karnataka"
    },
    {
      "name": "Belur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Bemetara",
      "state": "Chhattisgarh"
    },
    {
      "name": "Benaulim",
      "state": "Goa"
    },
    {
      "name": "Bengaluru",
      "state": "Karnataka"
    },
    {
      "name": "Beniganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Beohari",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Berasia",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Berhampore",
      "state": "West Bengal"
    },
    {
      "name": "Beri Khas",
      "state": "Haryana"
    },
    {
      "name": "Beswan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Betamcherla",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Betma",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bettiah",
      "state": "Bihar"
    },
    {
      "name": "Betul",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Betul Bazar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bewar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Beypore",
      "state": "Kerala"
    },
    {
      "name": "Bhabhra",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bhabhua",
      "state": "Bihar"
    },
    {
      "name": "Bhachau",
      "state": "Gujarat"
    },
    {
      "name": "Bhadarsa",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bhadarwah",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Bhadasar",
      "state": "Rajasthan"
    },
    {
      "name": "Bhadaur",
      "state": "Punjab"
    },
    {
      "name": "Bhadgaon, Maharashtra",
      "state": "Maharashtra"
    },
    {
      "name": "Bhadohi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bhadra",
      "state": "Rajasthan"
    },
    {
      "name": "Bhadrachalam",
      "state": "Telangana"
    },
    {
      "name": "Bhadradri Kothagudem",
      "state": "Telangana"
    },
    {
      "name": "Bhadrak",
      "state": "Odisha"
    },
    {
      "name": "Bhadrakh",
      "state": "Odisha"
    },
    {
      "name": "Bhadran",
      "state": "Gujarat"
    },
    {
      "name": "Bhadravati",
      "state": "Karnataka"
    },
    {
      "name": "Bhadreswar",
      "state": "West Bengal"
    },
    {
      "name": "Bhagalpur",
      "state": "Bihar"
    },
    {
      "name": "Bhagirathpur",
      "state": "Bihar"
    },
    {
      "name": "Bhagwantnagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bhainsdehi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bhaisa",
      "state": "Telangana"
    },
    {
      "name": "Bhalki",
      "state": "Karnataka"
    },
    {
      "name": "Bhandara",
      "state": "Maharashtra"
    },
    {
      "name": "Bhandardaha",
      "state": "West Bengal"
    },
    {
      "name": "Bhandardara",
      "state": "Maharashtra"
    },
    {
      "name": "Bhander",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bhandu",
      "state": "Gujarat"
    },
    {
      "name": "Bhandup",
      "state": "Maharashtra"
    },
    {
      "name": "Bhanjanagar",
      "state": "Odisha"
    },
    {
      "name": "Bhanpura",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bhanpuri",
      "state": "Chhattisgarh"
    },
    {
      "name": "Bhanvad",
      "state": "Gujarat"
    },
    {
      "name": "Bharatpur",
      "state": "Rajasthan"
    },
    {
      "name": "Bharatpura",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bhargain",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bharthana",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bharuch",
      "state": "Gujarat"
    },
    {
      "name": "Bharwari",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bhasawar",
      "state": "Rajasthan"
    },
    {
      "name": "Bhatapara",
      "state": "Chhattisgarh"
    },
    {
      "name": "Bhatgaon",
      "state": "Chhattisgarh"
    },
    {
      "name": "Bhatha",
      "state": "Gujarat"
    },
    {
      "name": "Bhatinda",
      "state": "Punjab"
    },
    {
      "name": "Bhatkal",
      "state": "Karnataka"
    },
    {
      "name": "Bhatpara",
      "state": "West Bengal"
    },
    {
      "name": "Bhattiprolu",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Bhaupur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bhavani",
      "state": "Tamil Nadu"
    },
    {
      "name": "Bhavnagar",
      "state": "Gujarat"
    },
    {
      "name": "Bhawaniganj",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bhawanigarh",
      "state": "Punjab"
    },
    {
      "name": "Bhawanipatna",
      "state": "Odisha"
    },
    {
      "name": "Bhawanipur",
      "state": "Bihar"
    },
    {
      "name": "Bhayandar",
      "state": "Maharashtra"
    },
    {
      "name": "Bhayavadar",
      "state": "Gujarat"
    },
    {
      "name": "Bhigvan",
      "state": "Maharashtra"
    },
    {
      "name": "Bhikangaon",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bhikhi",
      "state": "Punjab"
    },
    {
      "name": "Bhilai",
      "state": "Chhattisgarh"
    },
    {
      "name": "Bhildi",
      "state": "Gujarat"
    },
    {
      "name": "Bhilwara",
      "state": "Rajasthan"
    },
    {
      "name": "Bhim Tal",
      "state": "Uttarakhand"
    },
    {
      "name": "Bhimavaram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Bhimtal",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bhimunipatnam",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Bhind",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bhindar",
      "state": "Rajasthan"
    },
    {
      "name": "Bhinga",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bhinmal",
      "state": "Rajasthan"
    },
    {
      "name": "Bhitarwar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bhiwadi",
      "state": "Rajasthan"
    },
    {
      "name": "Bhiwandi",
      "state": "Maharashtra"
    },
    {
      "name": "Bhiwani",
      "state": "Haryana"
    },
    {
      "name": "Bhiwapur",
      "state": "Maharashtra"
    },
    {
      "name": "Bhognipur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bhogpur",
      "state": "Punjab"
    },
    {
      "name": "Bhojpur",
      "state": "Bihar"
    },
    {
      "name": "Bhojpur Dharampur",
      "state": "Gujarat"
    },
    {
      "name": "Bhojudih",
      "state": "Jharkhand"
    },
    {
      "name": "Bhokar",
      "state": "Maharashtra"
    },
    {
      "name": "Bhokardan",
      "state": "Maharashtra"
    },
    {
      "name": "Bholath",
      "state": "Punjab"
    },
    {
      "name": "Bhongaon",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bhongir",
      "state": "Telangana"
    },
    {
      "name": "Bhoom",
      "state": "Maharashtra"
    },
    {
      "name": "Bhopal",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bhor",
      "state": "Maharashtra"
    },
    {
      "name": "Bhowali",
      "state": "Uttarakhand"
    },
    {
      "name": "Bhuban",
      "state": "Odisha"
    },
    {
      "name": "Bhubaneshwar",
      "state": "Odisha"
    },
    {
      "name": "Bhudgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Bhugaon",
      "state": "Maharashtra"
    },
    {
      "name": "Bhuj",
      "state": "Gujarat"
    },
    {
      "name": "Bhum",
      "state": "Maharashtra"
    },
    {
      "name": "Bhuma",
      "state": "Rajasthan"
    },
    {
      "name": "Bhusaval",
      "state": "Maharashtra"
    },
    {
      "name": "Bhusawal",
      "state": "Maharashtra"
    },
    {
      "name": "Biaora",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bid",
      "state": "Maharashtra"
    },
    {
      "name": "Bidar",
      "state": "Karnataka"
    },
    {
      "name": "Bidhnu",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bidhuna",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bighapur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bighapur Khurd",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bihar Sharif",
      "state": "Bihar"
    },
    {
      "name": "Bihariganj",
      "state": "Bihar"
    },
    {
      "name": "Bihpuriagaon",
      "state": "Assam"
    },
    {
      "name": "Bijapur",
      "state": "Chhattisgarh"
    },
    {
      "name": "Bijawar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bijbehara",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Bijni",
      "state": "Assam"
    },
    {
      "name": "Bijnor",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bijrauni",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Bijur",
      "state": "Maharashtra"
    },
    {
      "name": "Bikaner",
      "state": "Rajasthan"
    },
    {
      "name": "Bikapur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bikramganj",
      "state": "Bihar"
    },
    {
      "name": "Bilara",
      "state": "Rajasthan"
    },
    {
      "name": "Bilari",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bilariaganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bilashi",
      "state": "Maharashtra"
    },
    {
      "name": "Bilasipara",
      "state": "Assam"
    },
    {
      "name": "Bilaspur",
      "state": "Chhattisgarh"
    },
    {
      "name": "Bilaspur",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Bilaspur",
      "state": "Haryana"
    },
    {
      "name": "Bilaspur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bilgi",
      "state": "Karnataka"
    },
    {
      "name": "Bilgram",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bilhaur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bilimora",
      "state": "Gujarat"
    },
    {
      "name": "Bilkha",
      "state": "Gujarat"
    },
    {
      "name": "Biloli",
      "state": "Maharashtra"
    },
    {
      "name": "Bilsanda",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bilsi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bilthra",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Binauli",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Binaur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bindki",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Binka",
      "state": "Odisha"
    },
    {
      "name": "Birbhaddar",
      "state": "Uttarakhand"
    },
    {
      "name": "Birbhum district",
      "state": "West Bengal"
    },
    {
      "name": "Birdpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Birmitrapur",
      "state": "Odisha"
    },
    {
      "name": "Birpara",
      "state": "West Bengal"
    },
    {
      "name": "Birpur",
      "state": "Bihar"
    },
    {
      "name": "Birpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Birur",
      "state": "Karnataka"
    },
    {
      "name": "Bisalpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bisanda Buzurg",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bisauli",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bisenda Buzurg",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bishnah",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Bishnupur",
      "state": "Manipur"
    },
    {
      "name": "Bishnupur",
      "state": "West Bengal"
    },
    {
      "name": "Bishunpur Urf Maharajganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bissau",
      "state": "Rajasthan"
    },
    {
      "name": "Biswan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bithur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Bobbili",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Bodhan",
      "state": "Telangana"
    },
    {
      "name": "Bodinayakkanur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Bodri",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Boisar",
      "state": "Maharashtra"
    },
    {
      "name": "Bokajan",
      "state": "Assam"
    },
    {
      "name": "Bokakhat",
      "state": "Assam"
    },
    {
      "name": "Bokaro",
      "state": "Jharkhand"
    },
    {
      "name": "Bolanikhodan",
      "state": "Odisha"
    },
    {
      "name": "Bolpur",
      "state": "West Bengal"
    },
    {
      "name": "Bomdila",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Bongaigaon",
      "state": "Assam"
    },
    {
      "name": "Borgaon Manju",
      "state": "Maharashtra"
    },
    {
      "name": "Borivali",
      "state": "Maharashtra"
    },
    {
      "name": "Borivli",
      "state": "Maharashtra"
    },
    {
      "name": "Borkhera",
      "state": "Rajasthan"
    },
    {
      "name": "Borsad",
      "state": "Gujarat"
    },
    {
      "name": "Botad",
      "state": "Gujarat"
    },
    {
      "name": "Brahmapur",
      "state": "Odisha"
    },
    {
      "name": "Brahmapuri",
      "state": "Maharashtra"
    },
    {
      "name": "Brajarajnagar",
      "state": "Odisha"
    },
    {
      "name": "Breach Candy",
      "state": "Maharashtra"
    },
    {
      "name": "Budaun",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Buddh Gaya",
      "state": "Bihar"
    },
    {
      "name": "Budge Budge",
      "state": "West Bengal"
    },
    {
      "name": "Budhana",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Budhlada",
      "state": "Punjab"
    },
    {
      "name": "Buguda",
      "state": "Odisha"
    },
    {
      "name": "Bulandshahr",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Buldana",
      "state": "Maharashtra"
    },
    {
      "name": "Buldhana",
      "state": "Maharashtra"
    },
    {
      "name": "Bundi",
      "state": "Rajasthan"
    },
    {
      "name": "Bundu",
      "state": "Jharkhand"
    },
    {
      "name": "Burhanpur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Burhar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Buriya",
      "state": "Haryana"
    },
    {
      "name": "Burla",
      "state": "Odisha"
    },
    {
      "name": "Buxar",
      "state": "Bihar"
    },
    {
      "name": "Byadgi",
      "state": "Karnataka"
    },
    {
      "name": "Byculla",
      "state": "Maharashtra"
    },
    {
      "name": "Byndoor",
      "state": "Karnataka"
    },
    {
      "name": "Cachar",
      "state": "Assam"
    },
    {
      "name": "Calangute",
      "state": "Goa"
    },
    {
      "name": "Canacona",
      "state": "Karnataka"
    },
    {
      "name": "Candolim",
      "state": "Goa"
    },
    {
      "name": "Canning",
      "state": "West Bengal"
    },
    {
      "name": "Captainganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Carapur",
      "state": "Goa"
    },
    {
      "name": "Cavelossim",
      "state": "Goa"
    },
    {
      "name": "Central Delhi",
      "state": "Delhi"
    },
    {
      "name": "Chabua",
      "state": "Assam"
    },
    {
      "name": "Chaibasa",
      "state": "Jharkhand"
    },
    {
      "name": "Chail",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chakan",
      "state": "Maharashtra"
    },
    {
      "name": "Chakapara",
      "state": "West Bengal"
    },
    {
      "name": "Chakdaha",
      "state": "West Bengal"
    },
    {
      "name": "Chakia",
      "state": "Bihar"
    },
    {
      "name": "Chakia",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chaklasi",
      "state": "Gujarat"
    },
    {
      "name": "Chakradharpur",
      "state": "Jharkhand"
    },
    {
      "name": "Chakrata",
      "state": "Uttarakhand"
    },
    {
      "name": "Chaksu",
      "state": "Rajasthan"
    },
    {
      "name": "Chakulia",
      "state": "Jharkhand"
    },
    {
      "name": "Chakur",
      "state": "Maharashtra"
    },
    {
      "name": "Chalala",
      "state": "Gujarat"
    },
    {
      "name": "Chalisgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Challakere",
      "state": "Karnataka"
    },
    {
      "name": "Challapalle",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Chaloda",
      "state": "Gujarat"
    },
    {
      "name": "Chamba",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Chamoli",
      "state": "Uttarakhand"
    },
    {
      "name": "Champa",
      "state": "Chhattisgarh"
    },
    {
      "name": "Champadanga",
      "state": "West Bengal"
    },
    {
      "name": "Champahati",
      "state": "West Bengal"
    },
    {
      "name": "Champaner",
      "state": "Gujarat"
    },
    {
      "name": "Champawat",
      "state": "Uttarakhand"
    },
    {
      "name": "Champdani",
      "state": "West Bengal"
    },
    {
      "name": "Champhai",
      "state": "Mizoram"
    },
    {
      "name": "Champua",
      "state": "Odisha"
    },
    {
      "name": "Chamrajnagar",
      "state": "Karnataka"
    },
    {
      "name": "Chanasma",
      "state": "Gujarat"
    },
    {
      "name": "Chanda",
      "state": "Maharashtra"
    },
    {
      "name": "Chandannagar",
      "state": "West Bengal"
    },
    {
      "name": "Chandauli",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chandauli District",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chandausi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chandbali",
      "state": "Odisha"
    },
    {
      "name": "Chanderi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Chandgad",
      "state": "Maharashtra"
    },
    {
      "name": "Chandia",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Chandigarh",
      "state": "Chandigarh"
    },
    {
      "name": "Chandil",
      "state": "Jharkhand"
    },
    {
      "name": "Chandla",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Chandor",
      "state": "Maharashtra"
    },
    {
      "name": "Chandpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chandrakona",
      "state": "West Bengal"
    },
    {
      "name": "Chandrapur",
      "state": "Maharashtra"
    },
    {
      "name": "Chanduasi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chandur",
      "state": "Maharashtra"
    },
    {
      "name": "Chandur",
      "state": "Telangana"
    },
    {
      "name": "Chandur Bazar",
      "state": "Maharashtra"
    },
    {
      "name": "Changanacheri",
      "state": "Kerala"
    },
    {
      "name": "Changlang",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Channagiri",
      "state": "Karnataka"
    },
    {
      "name": "Channapatna",
      "state": "Karnataka"
    },
    {
      "name": "Channarayapatna",
      "state": "Karnataka"
    },
    {
      "name": "Chapar",
      "state": "Assam"
    },
    {
      "name": "Chapra",
      "state": "Bihar"
    },
    {
      "name": "Charkhari",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Charkhi Dadri",
      "state": "Haryana"
    },
    {
      "name": "Charthawal",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chas",
      "state": "Jharkhand"
    },
    {
      "name": "Chatakonda",
      "state": "Telangana"
    },
    {
      "name": "Chatra",
      "state": "Jharkhand"
    },
    {
      "name": "Chatrapur",
      "state": "Odisha"
    },
    {
      "name": "Chaupal",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Chausala",
      "state": "Maharashtra"
    },
    {
      "name": "Chechat",
      "state": "Rajasthan"
    },
    {
      "name": "Chembur",
      "state": "Maharashtra"
    },
    {
      "name": "Chemmumiahpet",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Chengam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Chengannur",
      "state": "Kerala"
    },
    {
      "name": "Chennai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Chennimalai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Cherpulassery",
      "state": "Kerala"
    },
    {
      "name": "Cherrapunji",
      "state": "Meghalaya"
    },
    {
      "name": "Cherthala",
      "state": "Kerala"
    },
    {
      "name": "Chetput",
      "state": "Tamil Nadu"
    },
    {
      "name": "Chettipalaiyam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Chetwayi",
      "state": "Kerala"
    },
    {
      "name": "Cheyyar",
      "state": "Tamil Nadu"
    },
    {
      "name": "Cheyyur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Chhabra",
      "state": "Rajasthan"
    },
    {
      "name": "Chhachhrauli",
      "state": "Haryana"
    },
    {
      "name": "Chhala",
      "state": "Gujarat"
    },
    {
      "name": "Chhapar",
      "state": "Rajasthan"
    },
    {
      "name": "Chhaprauli",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chharra",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chhata",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chhatapur",
      "state": "Bihar"
    },
    {
      "name": "Chhatarpur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Chhibramau",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chhindwara",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Chhitauni",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chhota Udepur",
      "state": "Gujarat"
    },
    {
      "name": "Chhoti Sadri",
      "state": "Rajasthan"
    },
    {
      "name": "Chhuikhadan",
      "state": "Chhattisgarh"
    },
    {
      "name": "Chhutmalpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chicalim",
      "state": "Goa"
    },
    {
      "name": "Chichli",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Chicholi",
      "state": "Maharashtra"
    },
    {
      "name": "Chichondi Patil",
      "state": "Maharashtra"
    },
    {
      "name": "Chidambaram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Chidawa",
      "state": "Rajasthan"
    },
    {
      "name": "Chik Ballapur",
      "state": "Karnataka"
    },
    {
      "name": "Chikhli",
      "state": "Gujarat"
    },
    {
      "name": "Chikhli (Buldhana)",
      "state": "Maharashtra"
    },
    {
      "name": "Chikhli (Jalna)",
      "state": "Maharashtra"
    },
    {
      "name": "Chikitigarh",
      "state": "Odisha"
    },
    {
      "name": "Chikkaballapur",
      "state": "Karnataka"
    },
    {
      "name": "Chikkamagaluru",
      "state": "Karnataka"
    },
    {
      "name": "Chiknayakanhalli",
      "state": "Karnataka"
    },
    {
      "name": "Chikodi",
      "state": "Karnataka"
    },
    {
      "name": "Chilakalurupet",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Chillupar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chima",
      "state": "Punjab"
    },
    {
      "name": "Chimur",
      "state": "Maharashtra"
    },
    {
      "name": "Chinchani",
      "state": "Maharashtra"
    },
    {
      "name": "Chinchinim",
      "state": "Goa"
    },
    {
      "name": "Chincholi",
      "state": "Karnataka"
    },
    {
      "name": "Chinchpokli",
      "state": "Maharashtra"
    },
    {
      "name": "Chingleput",
      "state": "Tamil Nadu"
    },
    {
      "name": "Chinna Salem",
      "state": "Tamil Nadu"
    },
    {
      "name": "Chinnachowk",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Chinnamanur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Chinnasekkadu",
      "state": "Tamil Nadu"
    },
    {
      "name": "Chintamani",
      "state": "Karnataka"
    },
    {
      "name": "Chiplun",
      "state": "Maharashtra"
    },
    {
      "name": "Chipurupalle",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Chirala",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Chirang",
      "state": "Assam"
    },
    {
      "name": "Chirgaon",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chiria",
      "state": "Jharkhand"
    },
    {
      "name": "Chitapur",
      "state": "Karnataka"
    },
    {
      "name": "Chitradurga",
      "state": "Karnataka"
    },
    {
      "name": "Chitrakoot",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chitrakoot Dham",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chittaranjan",
      "state": "West Bengal"
    },
    {
      "name": "Chittarkonda",
      "state": "Odisha"
    },
    {
      "name": "Chittaurgarh",
      "state": "Rajasthan"
    },
    {
      "name": "Chittoor",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Chittur",
      "state": "Kerala"
    },
    {
      "name": "Chodavaram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Cholapuram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Chopan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Chopda",
      "state": "Maharashtra"
    },
    {
      "name": "Chorhat",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Chotila",
      "state": "Gujarat"
    },
    {
      "name": "Chowari",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Chuari Khas",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Chuda",
      "state": "Gujarat"
    },
    {
      "name": "Chunar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Churachandpur",
      "state": "Manipur"
    },
    {
      "name": "Churk",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Churu",
      "state": "Rajasthan"
    },
    {
      "name": "Churulia",
      "state": "West Bengal"
    },
    {
      "name": "Chēlakara",
      "state": "Kerala"
    },
    {
      "name": "Clement Town",
      "state": "Uttarakhand"
    },
    {
      "name": "Closepet",
      "state": "Karnataka"
    },
    {
      "name": "Cochin",
      "state": "Kerala"
    },
    {
      "name": "Coimbatore",
      "state": "Tamil Nadu"
    },
    {
      "name": "Colaba",
      "state": "Maharashtra"
    },
    {
      "name": "Colachel",
      "state": "Tamil Nadu"
    },
    {
      "name": "Colgong",
      "state": "Bihar"
    },
    {
      "name": "Colonelganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Colovale",
      "state": "Goa"
    },
    {
      "name": "Colva",
      "state": "Goa"
    },
    {
      "name": "Contai",
      "state": "West Bengal"
    },
    {
      "name": "Cooch Behar",
      "state": "West Bengal"
    },
    {
      "name": "Coondapoor",
      "state": "Karnataka"
    },
    {
      "name": "Cortalim",
      "state": "Goa"
    },
    {
      "name": "Cossimbazar",
      "state": "West Bengal"
    },
    {
      "name": "Cuddalore",
      "state": "Tamil Nadu"
    },
    {
      "name": "Cuddapah",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Cumbum",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Cumbum",
      "state": "Tamil Nadu"
    },
    {
      "name": "Cuncolim",
      "state": "Goa"
    },
    {
      "name": "Curchorem",
      "state": "Goa"
    },
    {
      "name": "Curti",
      "state": "Goa"
    },
    {
      "name": "Cuttack",
      "state": "Odisha"
    },
    {
      "name": "Dabhoda",
      "state": "Gujarat"
    },
    {
      "name": "Dabhoi",
      "state": "Gujarat"
    },
    {
      "name": "Dabhol",
      "state": "Maharashtra"
    },
    {
      "name": "Daboh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Dabra",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Dabwali",
      "state": "Haryana"
    },
    {
      "name": "Daddi",
      "state": "Maharashtra"
    },
    {
      "name": "Dadra",
      "state": "Dadra and Nagar Haveli and Daman and Diu"
    },
    {
      "name": "Dadra & Nagar Haveli",
      "state": "Dadra and Nagar Haveli and Daman and Diu"
    },
    {
      "name": "Dadri",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Dagshai",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Dahanu",
      "state": "Maharashtra"
    },
    {
      "name": "Dahegam",
      "state": "Gujarat"
    },
    {
      "name": "Dahivel",
      "state": "Maharashtra"
    },
    {
      "name": "Dahod",
      "state": "Gujarat"
    },
    {
      "name": "Daitari",
      "state": "Odisha"
    },
    {
      "name": "Dakor",
      "state": "Gujarat"
    },
    {
      "name": "Dakshin Dinajpur district",
      "state": "West Bengal"
    },
    {
      "name": "Dakshina Kannada",
      "state": "Karnataka"
    },
    {
      "name": "Dalhousie",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Dalkola",
      "state": "West Bengal"
    },
    {
      "name": "Dalmau",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Dalsingh Sarai",
      "state": "Bihar"
    },
    {
      "name": "Daltonganj",
      "state": "Jharkhand"
    },
    {
      "name": "Dam Dam",
      "state": "West Bengal"
    },
    {
      "name": "Daman",
      "state": "Dadra and Nagar Haveli and Daman and Diu"
    },
    {
      "name": "Daman District",
      "state": "Dadra and Nagar Haveli and Daman and Diu"
    },
    {
      "name": "Damnagar",
      "state": "Gujarat"
    },
    {
      "name": "Damoh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Dandeli",
      "state": "Karnataka"
    },
    {
      "name": "Dandi",
      "state": "Gujarat"
    },
    {
      "name": "Dangs (India)",
      "state": "Gujarat"
    },
    {
      "name": "Dankaur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Danta",
      "state": "Gujarat"
    },
    {
      "name": "Dapoli",
      "state": "Maharashtra"
    },
    {
      "name": "Daraganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Daranagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Darbhanga",
      "state": "Bihar"
    },
    {
      "name": "Dariba",
      "state": "Rajasthan"
    },
    {
      "name": "Darjeeling",
      "state": "West Bengal"
    },
    {
      "name": "Darlawn",
      "state": "Mizoram"
    },
    {
      "name": "Darrang",
      "state": "Assam"
    },
    {
      "name": "Darsi",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Darwha",
      "state": "Maharashtra"
    },
    {
      "name": "Daryapur",
      "state": "Maharashtra"
    },
    {
      "name": "Dasna",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Dasnapur",
      "state": "Telangana"
    },
    {
      "name": "Dasuya",
      "state": "Punjab"
    },
    {
      "name": "Dataganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Datia",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Dattapur",
      "state": "Maharashtra"
    },
    {
      "name": "Daudnagar",
      "state": "Bihar"
    },
    {
      "name": "Daulatabad",
      "state": "Maharashtra"
    },
    {
      "name": "Daulatpur",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Daulatpur",
      "state": "West Bengal"
    },
    {
      "name": "Daund",
      "state": "Maharashtra"
    },
    {
      "name": "Daurala",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Dausa",
      "state": "Rajasthan"
    },
    {
      "name": "Davanagere",
      "state": "Karnataka"
    },
    {
      "name": "Davorlim",
      "state": "Goa"
    },
    {
      "name": "Dayal Bagh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Dayapar",
      "state": "Gujarat"
    },
    {
      "name": "Debagram",
      "state": "West Bengal"
    },
    {
      "name": "Debipur",
      "state": "West Bengal"
    },
    {
      "name": "Deccan Gymkhana",
      "state": "Maharashtra"
    },
    {
      "name": "Deglur",
      "state": "Maharashtra"
    },
    {
      "name": "Dehradun",
      "state": "Uttarakhand"
    },
    {
      "name": "Dehri",
      "state": "Bihar"
    },
    {
      "name": "Dehu",
      "state": "Maharashtra"
    },
    {
      "name": "Delhi",
      "state": "Delhi"
    },
    {
      "name": "Delvada",
      "state": "Gujarat"
    },
    {
      "name": "Delwada",
      "state": "Gujarat"
    },
    {
      "name": "Denkanikota",
      "state": "Tamil Nadu"
    },
    {
      "name": "Deoband",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Deogarh",
      "state": "Jharkhand"
    },
    {
      "name": "Deogarh",
      "state": "Odisha"
    },
    {
      "name": "Deogarh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Deolali",
      "state": "Maharashtra"
    },
    {
      "name": "Deolapar",
      "state": "Maharashtra"
    },
    {
      "name": "Deoli",
      "state": "Delhi"
    },
    {
      "name": "Deoli",
      "state": "Maharashtra"
    },
    {
      "name": "Deoli",
      "state": "Rajasthan"
    },
    {
      "name": "Deoni",
      "state": "Maharashtra"
    },
    {
      "name": "Deoranian",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Deori",
      "state": "Chhattisgarh"
    },
    {
      "name": "Deori Khas",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Deoria",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Depalpur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Dera Baba Nanak",
      "state": "Punjab"
    },
    {
      "name": "Dera Bassi",
      "state": "Punjab"
    },
    {
      "name": "Dera Gopipur",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Derapur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Dergaon",
      "state": "Assam"
    },
    {
      "name": "Deshnoke",
      "state": "Rajasthan"
    },
    {
      "name": "Desur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Detroj",
      "state": "Gujarat"
    },
    {
      "name": "Deulgaon Raja",
      "state": "Maharashtra"
    },
    {
      "name": "Devadanappatti",
      "state": "Tamil Nadu"
    },
    {
      "name": "Devakottai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Devanhalli",
      "state": "Karnataka"
    },
    {
      "name": "Devaprayag",
      "state": "Uttarakhand"
    },
    {
      "name": "Devarkonda",
      "state": "Telangana"
    },
    {
      "name": "Devbhumi Dwarka",
      "state": "Gujarat"
    },
    {
      "name": "Devgadh Bariya",
      "state": "Gujarat"
    },
    {
      "name": "Devgarh",
      "state": "Rajasthan"
    },
    {
      "name": "Devrukh",
      "state": "Maharashtra"
    },
    {
      "name": "Dewa",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Dewas",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Dhaka",
      "state": "Bihar"
    },
    {
      "name": "Dhalai",
      "state": "Tripura"
    },
    {
      "name": "Dhali",
      "state": "Tamil Nadu"
    },
    {
      "name": "Dhamnod",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Dhampur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Dhamtari",
      "state": "Chhattisgarh"
    },
    {
      "name": "Dhana",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Dhanaula",
      "state": "Punjab"
    },
    {
      "name": "Dhanaura",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Dhanbad",
      "state": "Jharkhand"
    },
    {
      "name": "Dhandhuka",
      "state": "Gujarat"
    },
    {
      "name": "Dhanera",
      "state": "Gujarat"
    },
    {
      "name": "Dhanghata",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Dhaniakhali community development block",
      "state": "West Bengal"
    },
    {
      "name": "Dhansura",
      "state": "Gujarat"
    },
    {
      "name": "Dhanwar",
      "state": "Jharkhand"
    },
    {
      "name": "Dhar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Dharampur",
      "state": "Gujarat"
    },
    {
      "name": "Dharampuri",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Dharamsala",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Dharangaon",
      "state": "Maharashtra"
    },
    {
      "name": "Dharapuram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Dharasana",
      "state": "Gujarat"
    },
    {
      "name": "Dharau",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Dharavi",
      "state": "Maharashtra"
    },
    {
      "name": "Dharchula",
      "state": "Uttarakhand"
    },
    {
      "name": "Dhari",
      "state": "Gujarat"
    },
    {
      "name": "Dhariwal",
      "state": "Punjab"
    },
    {
      "name": "Dharmabad",
      "state": "Maharashtra"
    },
    {
      "name": "Dharmadam",
      "state": "Kerala"
    },
    {
      "name": "Dharmanagar",
      "state": "Tripura"
    },
    {
      "name": "Dharmapuri",
      "state": "Tamil Nadu"
    },
    {
      "name": "Dharmavaram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Dharuhera",
      "state": "Haryana"
    },
    {
      "name": "Dharur",
      "state": "Maharashtra"
    },
    {
      "name": "Dharwad",
      "state": "Karnataka"
    },
    {
      "name": "Dhasa",
      "state": "Gujarat"
    },
    {
      "name": "Dhaulpur",
      "state": "Rajasthan"
    },
    {
      "name": "Dhaurahra",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Dhawalpuri",
      "state": "Maharashtra"
    },
    {
      "name": "Dhekiajuli",
      "state": "Assam"
    },
    {
      "name": "Dhemaji",
      "state": "Assam"
    },
    {
      "name": "Dhenkanal",
      "state": "Odisha"
    },
    {
      "name": "Dhilwan",
      "state": "Punjab"
    },
    {
      "name": "Dhing",
      "state": "Assam"
    },
    {
      "name": "Dhola",
      "state": "Gujarat"
    },
    {
      "name": "Dholera",
      "state": "Gujarat"
    },
    {
      "name": "Dholka",
      "state": "Gujarat"
    },
    {
      "name": "Dhone",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Dhoraji",
      "state": "Gujarat"
    },
    {
      "name": "Dhrangadhra",
      "state": "Gujarat"
    },
    {
      "name": "Dhrol",
      "state": "Gujarat"
    },
    {
      "name": "Dhubri",
      "state": "Assam"
    },
    {
      "name": "Dhudi",
      "state": "Punjab"
    },
    {
      "name": "Dhulagari",
      "state": "West Bengal"
    },
    {
      "name": "Dhule",
      "state": "Maharashtra"
    },
    {
      "name": "Dhulia",
      "state": "Maharashtra"
    },
    {
      "name": "Dhulian",
      "state": "West Bengal"
    },
    {
      "name": "Dhupguri",
      "state": "West Bengal"
    },
    {
      "name": "Dhuri",
      "state": "Punjab"
    },
    {
      "name": "Dhuwaran",
      "state": "Gujarat"
    },
    {
      "name": "Diamond Harbour",
      "state": "West Bengal"
    },
    {
      "name": "Dibai",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Dibang Valley",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Dibrugarh",
      "state": "Assam"
    },
    {
      "name": "Dicholi",
      "state": "Goa"
    },
    {
      "name": "Didwana",
      "state": "Rajasthan"
    },
    {
      "name": "Dig",
      "state": "Rajasthan"
    },
    {
      "name": "Digapahandi",
      "state": "Odisha"
    },
    {
      "name": "Digboi",
      "state": "Assam"
    },
    {
      "name": "Digha",
      "state": "West Bengal"
    },
    {
      "name": "Dighori",
      "state": "Maharashtra"
    },
    {
      "name": "Dighwara",
      "state": "Bihar"
    },
    {
      "name": "Diglur",
      "state": "Maharashtra"
    },
    {
      "name": "Digras",
      "state": "Maharashtra"
    },
    {
      "name": "Diguvametta",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Dima Hasao District",
      "state": "Assam"
    },
    {
      "name": "Dimapur",
      "state": "Nagaland"
    },
    {
      "name": "Dina Nagar",
      "state": "Punjab"
    },
    {
      "name": "Dinapore",
      "state": "Bihar"
    },
    {
      "name": "Dindigul",
      "state": "Tamil Nadu"
    },
    {
      "name": "Dindori",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Dindori, Maharashtra",
      "state": "Maharashtra"
    },
    {
      "name": "Dinhata",
      "state": "West Bengal"
    },
    {
      "name": "Diphu",
      "state": "Assam"
    },
    {
      "name": "Dirba",
      "state": "Punjab"
    },
    {
      "name": "Disa",
      "state": "Gujarat"
    },
    {
      "name": "Dispur",
      "state": "Assam"
    },
    {
      "name": "Diu",
      "state": "Dadra and Nagar Haveli and Daman and Diu"
    },
    {
      "name": "Diveagar",
      "state": "Maharashtra"
    },
    {
      "name": "Divrasai",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Dod Ballapur",
      "state": "Karnataka"
    },
    {
      "name": "Doda",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Dohad",
      "state": "Gujarat"
    },
    {
      "name": "Dohrighat",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Doiwala",
      "state": "Uttarakhand"
    },
    {
      "name": "Domariaganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Dombivli",
      "state": "Maharashtra"
    },
    {
      "name": "Domjur",
      "state": "West Bengal"
    },
    {
      "name": "Dondaicha",
      "state": "Maharashtra"
    },
    {
      "name": "Dongargaon",
      "state": "Chhattisgarh"
    },
    {
      "name": "Dongargarh",
      "state": "Chhattisgarh"
    },
    {
      "name": "Dongri",
      "state": "Maharashtra"
    },
    {
      "name": "Doraha",
      "state": "Punjab"
    },
    {
      "name": "Dornakal",
      "state": "Telangana"
    },
    {
      "name": "Dostpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Dubrajpur",
      "state": "West Bengal"
    },
    {
      "name": "Dudhani",
      "state": "Maharashtra"
    },
    {
      "name": "Dudhi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Dugadda",
      "state": "Uttarakhand"
    },
    {
      "name": "Dugda",
      "state": "Jharkhand"
    },
    {
      "name": "Duliagaon",
      "state": "Assam"
    },
    {
      "name": "Dum Duma",
      "state": "Assam"
    },
    {
      "name": "Dumka",
      "state": "Jharkhand"
    },
    {
      "name": "Dumkhal",
      "state": "Gujarat"
    },
    {
      "name": "Dumra",
      "state": "Bihar"
    },
    {
      "name": "Dumraon",
      "state": "Bihar"
    },
    {
      "name": "Dungarpur",
      "state": "Gujarat"
    },
    {
      "name": "Dungarpur",
      "state": "Rajasthan"
    },
    {
      "name": "Durg",
      "state": "Chhattisgarh"
    },
    {
      "name": "Durgapur",
      "state": "Maharashtra"
    },
    {
      "name": "Durgapur",
      "state": "West Bengal"
    },
    {
      "name": "Durgapur, Chandrapur",
      "state": "Maharashtra"
    },
    {
      "name": "Dusi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Dwarahat",
      "state": "Uttarakhand"
    },
    {
      "name": "Dwarka",
      "state": "Gujarat"
    },
    {
      "name": "East Delhi",
      "state": "Delhi"
    },
    {
      "name": "East District",
      "state": "Sikkim"
    },
    {
      "name": "East Garo Hills",
      "state": "Meghalaya"
    },
    {
      "name": "East Godavari",
      "state": "Andhra Pradesh"
    },
    {
      "name": "East Jaintia Hills",
      "state": "Meghalaya"
    },
    {
      "name": "East Kameng",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "East Khasi Hills",
      "state": "Meghalaya"
    },
    {
      "name": "East Siang",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Edakkulam",
      "state": "Kerala"
    },
    {
      "name": "Egra",
      "state": "West Bengal"
    },
    {
      "name": "Elamanchili",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Elayirampannai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Ellenabad",
      "state": "Haryana"
    },
    {
      "name": "Ellore",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Elumalai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Elur",
      "state": "Kerala"
    },
    {
      "name": "Emmiganur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Eral",
      "state": "Tamil Nadu"
    },
    {
      "name": "Erandol",
      "state": "Maharashtra"
    },
    {
      "name": "Eraniel",
      "state": "Tamil Nadu"
    },
    {
      "name": "Erattupetta",
      "state": "Kerala"
    },
    {
      "name": "Ernakulam",
      "state": "Kerala"
    },
    {
      "name": "Erode",
      "state": "Tamil Nadu"
    },
    {
      "name": "Erraguntla",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Erumaippatti",
      "state": "Tamil Nadu"
    },
    {
      "name": "Etah",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Etawa",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Etawah",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Etikoppaka",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Etmadpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ettaiyapuram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Faizabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Faizpur",
      "state": "Maharashtra"
    },
    {
      "name": "Falakata",
      "state": "West Bengal"
    },
    {
      "name": "Farah",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Farakka",
      "state": "West Bengal"
    },
    {
      "name": "Faridabad",
      "state": "Haryana"
    },
    {
      "name": "Faridabad District",
      "state": "Haryana"
    },
    {
      "name": "Faridkot",
      "state": "Punjab"
    },
    {
      "name": "Faridnagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Faridpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Farrukhabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Farrukhnagar",
      "state": "Haryana"
    },
    {
      "name": "Farrukhnagar",
      "state": "Telangana"
    },
    {
      "name": "Fatehabad",
      "state": "Haryana"
    },
    {
      "name": "Fatehabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Fatehabad District",
      "state": "Haryana"
    },
    {
      "name": "Fatehganj West",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Fatehgarh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Fatehgarh Churian",
      "state": "Punjab"
    },
    {
      "name": "Fatehgarh Sahib",
      "state": "Punjab"
    },
    {
      "name": "Fatehpur",
      "state": "Rajasthan"
    },
    {
      "name": "Fatehpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Fatehpur (Barabanki)",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Fatehpur Chaurasi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Fatehpur Sikri",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Fatwa",
      "state": "Bihar"
    },
    {
      "name": "Fazilka",
      "state": "Punjab"
    },
    {
      "name": "Ferokh",
      "state": "Kerala"
    },
    {
      "name": "Firozabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Firozpur",
      "state": "Punjab"
    },
    {
      "name": "Firozpur District",
      "state": "Punjab"
    },
    {
      "name": "Firozpur Jhirka",
      "state": "Haryana"
    },
    {
      "name": "Forbesganj",
      "state": "Bihar"
    },
    {
      "name": "Fort",
      "state": "Maharashtra"
    },
    {
      "name": "Fort Gloster",
      "state": "West Bengal"
    },
    {
      "name": "French Rocks",
      "state": "Karnataka"
    },
    {
      "name": "Fyzabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gadag",
      "state": "Karnataka"
    },
    {
      "name": "Gadag-Betageri",
      "state": "Karnataka"
    },
    {
      "name": "Gadarwara",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Gadchiroli",
      "state": "Maharashtra"
    },
    {
      "name": "Gaddi Annaram",
      "state": "Telangana"
    },
    {
      "name": "Gadhada",
      "state": "Gujarat"
    },
    {
      "name": "Gadhinglaj",
      "state": "Maharashtra"
    },
    {
      "name": "Gadwal",
      "state": "Telangana"
    },
    {
      "name": "Gagret",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Gahlon",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gahmar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gaighata community development block",
      "state": "West Bengal"
    },
    {
      "name": "Gaini",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gairkata",
      "state": "West Bengal"
    },
    {
      "name": "Gajapati",
      "state": "Odisha"
    },
    {
      "name": "Gajendragarh",
      "state": "Karnataka"
    },
    {
      "name": "Gajraula",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gajuwaka",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Galiakot",
      "state": "Rajasthan"
    },
    {
      "name": "Gandai",
      "state": "Chhattisgarh"
    },
    {
      "name": "Gandarbal",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Ganderbal",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Gandevi",
      "state": "Gujarat"
    },
    {
      "name": "Gandhi Nagar",
      "state": "Tamil Nadu"
    },
    {
      "name": "Gandhidham",
      "state": "Gujarat"
    },
    {
      "name": "Gandhinagar",
      "state": "Gujarat"
    },
    {
      "name": "Gangadharpur",
      "state": "West Bengal"
    },
    {
      "name": "Gangaikondan",
      "state": "Tamil Nadu"
    },
    {
      "name": "Gangakher",
      "state": "Maharashtra"
    },
    {
      "name": "Ganganagar",
      "state": "Rajasthan"
    },
    {
      "name": "Gangapur",
      "state": "Maharashtra"
    },
    {
      "name": "Gangapur",
      "state": "Rajasthan"
    },
    {
      "name": "Gangarampur",
      "state": "West Bengal"
    },
    {
      "name": "Gangavalli",
      "state": "Tamil Nadu"
    },
    {
      "name": "Gangawati",
      "state": "Karnataka"
    },
    {
      "name": "Gangoh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gangolli",
      "state": "Karnataka"
    },
    {
      "name": "Gangtok",
      "state": "Sikkim"
    },
    {
      "name": "Ganguvada",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Ganj Dundawara",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ganj Dundwara",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ganj Muradabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ganjam",
      "state": "Odisha"
    },
    {
      "name": "Gannavaram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Ganpatipule",
      "state": "Maharashtra"
    },
    {
      "name": "Garautha",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gardhiwala",
      "state": "Punjab"
    },
    {
      "name": "Garha Brahman",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Garhakota",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Garhi Pukhta",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Garhmuktesar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Garhshankar",
      "state": "Punjab"
    },
    {
      "name": "Garhwa",
      "state": "Jharkhand"
    },
    {
      "name": "Garhwa",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Garhwal",
      "state": "Uttarakhand"
    },
    {
      "name": "Gariaband",
      "state": "Chhattisgarh"
    },
    {
      "name": "Gariadhar",
      "state": "Gujarat"
    },
    {
      "name": "Garui",
      "state": "West Bengal"
    },
    {
      "name": "Garulia",
      "state": "West Bengal"
    },
    {
      "name": "Gaurela",
      "state": "Chhattisgarh"
    },
    {
      "name": "Gauriganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gauripur",
      "state": "Assam"
    },
    {
      "name": "Gautam Buddha Nagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gautampura",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Gawan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gaya",
      "state": "Bihar"
    },
    {
      "name": "Gevrai",
      "state": "Maharashtra"
    },
    {
      "name": "Ghanaur",
      "state": "Punjab"
    },
    {
      "name": "Ghansor",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Gharaunda",
      "state": "Haryana"
    },
    {
      "name": "Ghargaon",
      "state": "Maharashtra"
    },
    {
      "name": "Gharghoda",
      "state": "Chhattisgarh"
    },
    {
      "name": "Ghatal",
      "state": "West Bengal"
    },
    {
      "name": "Ghatampur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ghatanji",
      "state": "Maharashtra"
    },
    {
      "name": "Ghatkesar",
      "state": "Telangana"
    },
    {
      "name": "Ghatkopar",
      "state": "Maharashtra"
    },
    {
      "name": "Ghatsila",
      "state": "Jharkhand"
    },
    {
      "name": "Ghaziabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ghazipur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ghiror",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gho Brahmanan de",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Ghodasar",
      "state": "Gujarat"
    },
    {
      "name": "Ghoga",
      "state": "Bihar"
    },
    {
      "name": "Ghogha",
      "state": "Gujarat"
    },
    {
      "name": "Ghorawal",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ghosi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ghoti Budrukh",
      "state": "Maharashtra"
    },
    {
      "name": "Ghugus",
      "state": "Maharashtra"
    },
    {
      "name": "Ghumarwin",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Gidam",
      "state": "Chhattisgarh"
    },
    {
      "name": "Giddalur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Giddarbaha",
      "state": "Punjab"
    },
    {
      "name": "Gingee",
      "state": "Tamil Nadu"
    },
    {
      "name": "Gir Somnath",
      "state": "Gujarat"
    },
    {
      "name": "Girgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Giria",
      "state": "West Bengal"
    },
    {
      "name": "Giridih",
      "state": "Jharkhand"
    },
    {
      "name": "Goa Velha",
      "state": "Goa"
    },
    {
      "name": "Goalpara",
      "state": "Assam"
    },
    {
      "name": "Gobardanga",
      "state": "West Bengal"
    },
    {
      "name": "Gobichettipalayam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Gobindapur",
      "state": "West Bengal"
    },
    {
      "name": "Gobindpur",
      "state": "Jharkhand"
    },
    {
      "name": "Godda",
      "state": "Jharkhand"
    },
    {
      "name": "Godhra",
      "state": "Gujarat"
    },
    {
      "name": "Gogapur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Gohadi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Gohana",
      "state": "Haryana"
    },
    {
      "name": "Gohand",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gohpur",
      "state": "Assam"
    },
    {
      "name": "Gokak",
      "state": "Karnataka"
    },
    {
      "name": "Gokarna",
      "state": "Karnataka"
    },
    {
      "name": "Gokavaram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Gokul",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gola Bazar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gola Gokarannath",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Golaghat",
      "state": "Assam"
    },
    {
      "name": "Golakganj",
      "state": "Assam"
    },
    {
      "name": "Gomati",
      "state": "Tripura"
    },
    {
      "name": "Gomoh",
      "state": "Jharkhand"
    },
    {
      "name": "Gonda",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gonda (Aligarh)",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gonda City",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gondal",
      "state": "Gujarat"
    },
    {
      "name": "Gondia",
      "state": "Maharashtra"
    },
    {
      "name": "Gondiya",
      "state": "Maharashtra"
    },
    {
      "name": "Gopalganj",
      "state": "Bihar"
    },
    {
      "name": "Gopalpur",
      "state": "Odisha"
    },
    {
      "name": "Gopalpur",
      "state": "West Bengal"
    },
    {
      "name": "Gopalur",
      "state": "Telangana"
    },
    {
      "name": "Gopamau",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gopinathpur",
      "state": "Jharkhand"
    },
    {
      "name": "Gopinathpur",
      "state": "West Bengal"
    },
    {
      "name": "Gorai",
      "state": "Maharashtra"
    },
    {
      "name": "Gorakhpur",
      "state": "Haryana"
    },
    {
      "name": "Gorakhpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gorantla",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Goregaon",
      "state": "Maharashtra"
    },
    {
      "name": "Goribidnur",
      "state": "Karnataka"
    },
    {
      "name": "Gorubathan",
      "state": "West Bengal"
    },
    {
      "name": "Gorur",
      "state": "Karnataka"
    },
    {
      "name": "Gorwa",
      "state": "Gujarat"
    },
    {
      "name": "Gosaba",
      "state": "West Bengal"
    },
    {
      "name": "Gosainganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gosanimari",
      "state": "West Bengal"
    },
    {
      "name": "Goshainganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Goshaingaon",
      "state": "Assam"
    },
    {
      "name": "Govardhan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Govindapuram,Chilakaluripet,Guntur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Govindgarh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Govindgarh",
      "state": "Rajasthan"
    },
    {
      "name": "Greater Noida",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gua",
      "state": "Jharkhand"
    },
    {
      "name": "Gubbi",
      "state": "Karnataka"
    },
    {
      "name": "Gudalur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Gudari",
      "state": "Odisha"
    },
    {
      "name": "Gudibanda",
      "state": "Karnataka"
    },
    {
      "name": "Gudivada",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Gudiyatham",
      "state": "Tamil Nadu"
    },
    {
      "name": "Gudlavalleru",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Gudur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Gudur",
      "state": "Telangana"
    },
    {
      "name": "Guduvancheri",
      "state": "Tamil Nadu"
    },
    {
      "name": "Guhagar",
      "state": "Maharashtra"
    },
    {
      "name": "Guirim",
      "state": "Goa"
    },
    {
      "name": "Gulabpura",
      "state": "Rajasthan"
    },
    {
      "name": "Gulaothi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Guledagudda",
      "state": "Karnataka"
    },
    {
      "name": "Gumia",
      "state": "Jharkhand"
    },
    {
      "name": "Gumla",
      "state": "Jharkhand"
    },
    {
      "name": "Gummidipundi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Guna",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Gundlupēt",
      "state": "Karnataka"
    },
    {
      "name": "Gunnaur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Guntakal Junction",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Guntur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Gunupur",
      "state": "Odisha"
    },
    {
      "name": "Gurdaha",
      "state": "West Bengal"
    },
    {
      "name": "Gurdaspur",
      "state": "Punjab"
    },
    {
      "name": "Gurgaon",
      "state": "Haryana"
    },
    {
      "name": "Gurh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Gurmatkal",
      "state": "Karnataka"
    },
    {
      "name": "Gursahaiganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Gursarai",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Guru Har Sahai",
      "state": "Punjab"
    },
    {
      "name": "Guruvayur",
      "state": "Kerala"
    },
    {
      "name": "Guskhara",
      "state": "West Bengal"
    },
    {
      "name": "Guwahati",
      "state": "Assam"
    },
    {
      "name": "Gwalior",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Gyalshing",
      "state": "Sikkim"
    },
    {
      "name": "Gyanpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Habra",
      "state": "West Bengal"
    },
    {
      "name": "Hadagalli",
      "state": "Karnataka"
    },
    {
      "name": "Hadapsar, Pune",
      "state": "Maharashtra"
    },
    {
      "name": "Hadgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Haflong",
      "state": "Assam"
    },
    {
      "name": "Hailakandi",
      "state": "Assam"
    },
    {
      "name": "Hajan",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Hajipur",
      "state": "Bihar"
    },
    {
      "name": "Hajipur",
      "state": "Punjab"
    },
    {
      "name": "Hajo",
      "state": "Assam"
    },
    {
      "name": "Haldaur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Haldia",
      "state": "West Bengal"
    },
    {
      "name": "Haldibari",
      "state": "West Bengal"
    },
    {
      "name": "Haldwani",
      "state": "Uttarakhand"
    },
    {
      "name": "Halenda",
      "state": "Gujarat"
    },
    {
      "name": "Halisahar",
      "state": "West Bengal"
    },
    {
      "name": "Haliyal",
      "state": "Karnataka"
    },
    {
      "name": "Halkarni",
      "state": "Maharashtra"
    },
    {
      "name": "Halol",
      "state": "Gujarat"
    },
    {
      "name": "Halvad",
      "state": "Gujarat"
    },
    {
      "name": "Hamirpur",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Hamirpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Hampi",
      "state": "Karnataka"
    },
    {
      "name": "Handia",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Hangal",
      "state": "Karnataka"
    },
    {
      "name": "Hansi",
      "state": "Haryana"
    },
    {
      "name": "Hansot",
      "state": "Gujarat"
    },
    {
      "name": "Hanumangarh",
      "state": "Rajasthan"
    },
    {
      "name": "Hapur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Haraipur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Haraiya",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Harangul",
      "state": "Maharashtra"
    },
    {
      "name": "Harbatpur",
      "state": "Uttarakhand"
    },
    {
      "name": "Harchandpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Harda",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Harda Khas",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Hardoi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Harduaganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Hariana",
      "state": "Punjab"
    },
    {
      "name": "Haridwar",
      "state": "Uttarakhand"
    },
    {
      "name": "Harihar",
      "state": "Karnataka"
    },
    {
      "name": "Harij",
      "state": "Gujarat"
    },
    {
      "name": "Harindanga",
      "state": "West Bengal"
    },
    {
      "name": "Haringhata",
      "state": "West Bengal"
    },
    {
      "name": "Haripur",
      "state": "West Bengal"
    },
    {
      "name": "Harnai",
      "state": "Maharashtra"
    },
    {
      "name": "Harpalpur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Harpanahalli",
      "state": "Karnataka"
    },
    {
      "name": "Harrai",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Harsol",
      "state": "Gujarat"
    },
    {
      "name": "Harsud",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Harur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Hasanpur",
      "state": "Haryana"
    },
    {
      "name": "Hasanpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Hasimara",
      "state": "West Bengal"
    },
    {
      "name": "Hassan",
      "state": "Karnataka"
    },
    {
      "name": "Hastinapur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Hata",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Hata (India)",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Hathras",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Hathuran",
      "state": "Gujarat"
    },
    {
      "name": "Hatod",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Hatta",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Haveri",
      "state": "Karnataka"
    },
    {
      "name": "Hayuliang",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Hazaribag",
      "state": "Jharkhand"
    },
    {
      "name": "Hazaribagh",
      "state": "Jharkhand"
    },
    {
      "name": "Heggadadevankote",
      "state": "Karnataka"
    },
    {
      "name": "Helwak",
      "state": "Maharashtra"
    },
    {
      "name": "Hesla",
      "state": "Jharkhand"
    },
    {
      "name": "Hilsa",
      "state": "Bihar"
    },
    {
      "name": "Himatnagar",
      "state": "Gujarat"
    },
    {
      "name": "Hindaun",
      "state": "Rajasthan"
    },
    {
      "name": "Hindoria",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Hindupur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Hindusthan Cables Town",
      "state": "West Bengal"
    },
    {
      "name": "Hinganghat",
      "state": "Maharashtra"
    },
    {
      "name": "Hingoli",
      "state": "Maharashtra"
    },
    {
      "name": "Hinjilikatu",
      "state": "Odisha"
    },
    {
      "name": "Hirakud",
      "state": "Odisha"
    },
    {
      "name": "Hiranagar",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Hirapur",
      "state": "Maharashtra"
    },
    {
      "name": "Hirapur Hamesha",
      "state": "Maharashtra"
    },
    {
      "name": "Hirekerur",
      "state": "Karnataka"
    },
    {
      "name": "Hiriyur",
      "state": "Karnataka"
    },
    {
      "name": "Hisar",
      "state": "Haryana"
    },
    {
      "name": "Hisua",
      "state": "Bihar"
    },
    {
      "name": "Hodal",
      "state": "Haryana"
    },
    {
      "name": "Hojai",
      "state": "Assam"
    },
    {
      "name": "Holalkere",
      "state": "Karnataka"
    },
    {
      "name": "Hole Narsipur",
      "state": "Karnataka"
    },
    {
      "name": "Homnabad",
      "state": "Karnataka"
    },
    {
      "name": "Honavar",
      "state": "Karnataka"
    },
    {
      "name": "Honnali",
      "state": "Karnataka"
    },
    {
      "name": "Hooghly district",
      "state": "West Bengal"
    },
    {
      "name": "Hosanagara",
      "state": "Karnataka"
    },
    {
      "name": "Hosangadi",
      "state": "Karnataka"
    },
    {
      "name": "Hosdurga",
      "state": "Karnataka"
    },
    {
      "name": "Hoshangabad",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Hoshiarpur",
      "state": "Punjab"
    },
    {
      "name": "Hoskote",
      "state": "Karnataka"
    },
    {
      "name": "Hospet",
      "state": "Karnataka"
    },
    {
      "name": "Hosur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Hotgi",
      "state": "Maharashtra"
    },
    {
      "name": "Howli",
      "state": "Assam"
    },
    {
      "name": "Howrah",
      "state": "West Bengal"
    },
    {
      "name": "Hubballi",
      "state": "Karnataka"
    },
    {
      "name": "Hukeri",
      "state": "Karnataka"
    },
    {
      "name": "Hulas",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Hungund",
      "state": "Karnataka"
    },
    {
      "name": "Hunsur",
      "state": "Karnataka"
    },
    {
      "name": "Husainabad",
      "state": "Jharkhand"
    },
    {
      "name": "Hyderabad",
      "state": "Telangana"
    },
    {
      "name": "Iawar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Ibrahimpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ichalkaranji",
      "state": "Maharashtra"
    },
    {
      "name": "Ichapur",
      "state": "West Bengal"
    },
    {
      "name": "Ichchapuram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Ichhawar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Idappadi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Idar",
      "state": "Gujarat"
    },
    {
      "name": "Idukki",
      "state": "Kerala"
    },
    {
      "name": "Igatpuri",
      "state": "Maharashtra"
    },
    {
      "name": "Iglas",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ikauna",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Iklehra",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Ilampillai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Ilkal",
      "state": "Karnataka"
    },
    {
      "name": "Iluppur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Imphal",
      "state": "Manipur"
    },
    {
      "name": "Inda Chhoi",
      "state": "Haryana"
    },
    {
      "name": "Indapur",
      "state": "Maharashtra"
    },
    {
      "name": "Indergarh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Indi",
      "state": "Karnataka"
    },
    {
      "name": "Indore",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Indpur community development block",
      "state": "West Bengal"
    },
    {
      "name": "Indragarh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Indri",
      "state": "Haryana"
    },
    {
      "name": "Ingraj Bazar",
      "state": "West Bengal"
    },
    {
      "name": "Injambakkam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Iringal",
      "state": "Kerala"
    },
    {
      "name": "Irinjalakuda",
      "state": "Kerala"
    },
    {
      "name": "Irugur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Isagarh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Ishanpur",
      "state": "Punjab"
    },
    {
      "name": "Islamnagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Islamnagar (Badaun)",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Islampur",
      "state": "Bihar"
    },
    {
      "name": "Islampur",
      "state": "West Bengal"
    },
    {
      "name": "Itanagar",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Itarsi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Itaunja",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Itimadpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jabalpur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Jafarpur",
      "state": "West Bengal"
    },
    {
      "name": "Jagadhri",
      "state": "Haryana"
    },
    {
      "name": "Jagalur",
      "state": "Karnataka"
    },
    {
      "name": "Jagannathpur",
      "state": "Jharkhand"
    },
    {
      "name": "Jagatsinghapur",
      "state": "Odisha"
    },
    {
      "name": "Jagatsinghpur",
      "state": "Odisha"
    },
    {
      "name": "Jagdalpur",
      "state": "Chhattisgarh"
    },
    {
      "name": "Jagdishpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jagdispur",
      "state": "Bihar"
    },
    {
      "name": "Jaggayyapeta",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Jagnair",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jagraon",
      "state": "Punjab"
    },
    {
      "name": "Jagtial",
      "state": "Telangana"
    },
    {
      "name": "Jahanabad",
      "state": "Bihar"
    },
    {
      "name": "Jahanabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jahanabad (Pilibhit)",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jahangirabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jahangirpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jahazpur",
      "state": "Rajasthan"
    },
    {
      "name": "Jaigaon",
      "state": "West Bengal"
    },
    {
      "name": "Jainpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jaipur",
      "state": "Rajasthan"
    },
    {
      "name": "Jais",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jaisalmer",
      "state": "Rajasthan"
    },
    {
      "name": "Jaisinghnagar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Jaisingpur",
      "state": "Maharashtra"
    },
    {
      "name": "Jaitapur",
      "state": "Maharashtra"
    },
    {
      "name": "Jaitaran",
      "state": "Rajasthan"
    },
    {
      "name": "Jaithari",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Jaito",
      "state": "Punjab"
    },
    {
      "name": "Jajpur",
      "state": "Odisha"
    },
    {
      "name": "Jakhal",
      "state": "Haryana"
    },
    {
      "name": "Jakhangaon",
      "state": "Maharashtra"
    },
    {
      "name": "Jakhau",
      "state": "Gujarat"
    },
    {
      "name": "Jalakandapuram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Jalalabad",
      "state": "Punjab"
    },
    {
      "name": "Jalalabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jalali",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jalalpore",
      "state": "Gujarat"
    },
    {
      "name": "Jalalpur",
      "state": "Gujarat"
    },
    {
      "name": "Jalalpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jalandhar",
      "state": "Punjab"
    },
    {
      "name": "Jalarpet",
      "state": "Tamil Nadu"
    },
    {
      "name": "Jalaun",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jalesar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jaleshwar",
      "state": "Odisha"
    },
    {
      "name": "Jalgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Jalgaon Jamod",
      "state": "Maharashtra"
    },
    {
      "name": "Jalia",
      "state": "Gujarat"
    },
    {
      "name": "Jalkot",
      "state": "Maharashtra"
    },
    {
      "name": "Jalna",
      "state": "Maharashtra"
    },
    {
      "name": "Jalor",
      "state": "Rajasthan"
    },
    {
      "name": "Jalore",
      "state": "Rajasthan"
    },
    {
      "name": "Jalpaiguri",
      "state": "West Bengal"
    },
    {
      "name": "Jamadoba",
      "state": "Jharkhand"
    },
    {
      "name": "Jamai",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Jamalpur",
      "state": "Bihar"
    },
    {
      "name": "Jambuda",
      "state": "Gujarat"
    },
    {
      "name": "Jambusar",
      "state": "Gujarat"
    },
    {
      "name": "Jamkhandi",
      "state": "Karnataka"
    },
    {
      "name": "Jamkhed",
      "state": "Maharashtra"
    },
    {
      "name": "Jammalamadugu",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Jammu",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Jamnagar",
      "state": "Gujarat"
    },
    {
      "name": "Jamod",
      "state": "Maharashtra"
    },
    {
      "name": "Jamshedpur",
      "state": "Jharkhand"
    },
    {
      "name": "Jamtara",
      "state": "Jharkhand"
    },
    {
      "name": "Jamui",
      "state": "Bihar"
    },
    {
      "name": "Jamuria",
      "state": "West Bengal"
    },
    {
      "name": "Jandiala",
      "state": "Punjab"
    },
    {
      "name": "Jandiala Guru",
      "state": "Punjab"
    },
    {
      "name": "Janephal",
      "state": "Maharashtra"
    },
    {
      "name": "Jangaon",
      "state": "Telangana"
    },
    {
      "name": "Janghai",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jangipur",
      "state": "West Bengal"
    },
    {
      "name": "Jangoan",
      "state": "Telangana"
    },
    {
      "name": "Janjgir",
      "state": "Chhattisgarh"
    },
    {
      "name": "Janjgir-Champa",
      "state": "Chhattisgarh"
    },
    {
      "name": "Jansath",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jaoli",
      "state": "Maharashtra"
    },
    {
      "name": "Jaora",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Jarod,",
      "state": "Gujarat"
    },
    {
      "name": "Jarwa",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jarwal",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jasdan",
      "state": "Gujarat"
    },
    {
      "name": "Jashpur",
      "state": "Chhattisgarh"
    },
    {
      "name": "Jashpurnagar",
      "state": "Chhattisgarh"
    },
    {
      "name": "Jasidih",
      "state": "Jharkhand"
    },
    {
      "name": "Jaspur",
      "state": "Uttarakhand"
    },
    {
      "name": "Jasrana",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jaswantnagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jat, Sangli",
      "state": "Maharashtra"
    },
    {
      "name": "Jatani",
      "state": "Odisha"
    },
    {
      "name": "Jatara",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Jategaon",
      "state": "Maharashtra"
    },
    {
      "name": "Jaunpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jaurian",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Jawad",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Jawala Mukhi",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Jawhar",
      "state": "Maharashtra"
    },
    {
      "name": "Jayamkondacholapuram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Jayashankar Bhupalapally",
      "state": "Telangana"
    },
    {
      "name": "Jaynagar",
      "state": "Bihar"
    },
    {
      "name": "Jaynagar Majilpur",
      "state": "West Bengal"
    },
    {
      "name": "Jaysingpur",
      "state": "Maharashtra"
    },
    {
      "name": "Jehanabad",
      "state": "Bihar"
    },
    {
      "name": "Jejur",
      "state": "West Bengal"
    },
    {
      "name": "Jejuri",
      "state": "Maharashtra"
    },
    {
      "name": "Jetalpur",
      "state": "Gujarat"
    },
    {
      "name": "Jetalsar",
      "state": "Gujarat"
    },
    {
      "name": "Jetpur",
      "state": "Gujarat"
    },
    {
      "name": "Jetpur (Navagadh)",
      "state": "Gujarat"
    },
    {
      "name": "Jevargi",
      "state": "Karnataka"
    },
    {
      "name": "Jewar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jeypore",
      "state": "Odisha"
    },
    {
      "name": "Jha-Jha",
      "state": "Bihar"
    },
    {
      "name": "Jhabua",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Jhajhar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jhajjar",
      "state": "Haryana"
    },
    {
      "name": "Jhalawar",
      "state": "Rajasthan"
    },
    {
      "name": "Jhalida",
      "state": "West Bengal"
    },
    {
      "name": "Jhalod",
      "state": "Gujarat"
    },
    {
      "name": "Jhalrapatan",
      "state": "Rajasthan"
    },
    {
      "name": "Jhalu",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jhanjharpur",
      "state": "Bihar"
    },
    {
      "name": "Jhansi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jhargram",
      "state": "West Bengal"
    },
    {
      "name": "Jharia",
      "state": "Jharkhand"
    },
    {
      "name": "Jharsuguda",
      "state": "Odisha"
    },
    {
      "name": "Jharsuguda District",
      "state": "Odisha"
    },
    {
      "name": "Jhilimili",
      "state": "West Bengal"
    },
    {
      "name": "Jhinjhak",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jhinjhana",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jhulasan",
      "state": "Gujarat"
    },
    {
      "name": "Jhunjhunun",
      "state": "Rajasthan"
    },
    {
      "name": "Jhusi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jind",
      "state": "Haryana"
    },
    {
      "name": "Jintur",
      "state": "Maharashtra"
    },
    {
      "name": "Jiran",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Jiyanpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Jobat",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Jobner",
      "state": "Rajasthan"
    },
    {
      "name": "Jodhpur",
      "state": "Gujarat"
    },
    {
      "name": "Jodhpur",
      "state": "Rajasthan"
    },
    {
      "name": "Jodhpur (Ahmedabad)",
      "state": "Gujarat"
    },
    {
      "name": "Jodia",
      "state": "Gujarat"
    },
    {
      "name": "Jodiya Bandar",
      "state": "Gujarat"
    },
    {
      "name": "Jogbani",
      "state": "Bihar"
    },
    {
      "name": "Jogeshwari",
      "state": "Maharashtra"
    },
    {
      "name": "Jogighopa",
      "state": "Assam"
    },
    {
      "name": "Jogindarnagar",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Jogulamba Gadwal",
      "state": "Telangana"
    },
    {
      "name": "Jorethang",
      "state": "Sikkim"
    },
    {
      "name": "Jorhat",
      "state": "Assam"
    },
    {
      "name": "Joshimath",
      "state": "Uttarakhand"
    },
    {
      "name": "Jua",
      "state": "Goa"
    },
    {
      "name": "Jubbal",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Jugsalai",
      "state": "Jharkhand"
    },
    {
      "name": "Juhu",
      "state": "Maharashtra"
    },
    {
      "name": "Jumri Tilaiya",
      "state": "Jharkhand"
    },
    {
      "name": "Junagadh",
      "state": "Gujarat"
    },
    {
      "name": "Junagarh",
      "state": "Chhattisgarh"
    },
    {
      "name": "Junnar",
      "state": "Maharashtra"
    },
    {
      "name": "Jutogh",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Jyotiba Phule Nagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kabeerdham",
      "state": "Chhattisgarh"
    },
    {
      "name": "Kabrai",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kachchh",
      "state": "Gujarat"
    },
    {
      "name": "Kachholi",
      "state": "Gujarat"
    },
    {
      "name": "Kachhwa",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kachurwahi",
      "state": "Maharashtra"
    },
    {
      "name": "Kadakkavoor",
      "state": "Kerala"
    },
    {
      "name": "Kadambur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kadaura",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kadayanallur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kadegaon",
      "state": "Maharashtra"
    },
    {
      "name": "Kadi",
      "state": "Gujarat"
    },
    {
      "name": "Kadipur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kadiri",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Kadod",
      "state": "Gujarat"
    },
    {
      "name": "Kadur",
      "state": "Karnataka"
    },
    {
      "name": "Kadus",
      "state": "Maharashtra"
    },
    {
      "name": "Kagal",
      "state": "Maharashtra"
    },
    {
      "name": "Kagarol",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kagaznagar",
      "state": "Telangana"
    },
    {
      "name": "Kaij",
      "state": "Maharashtra"
    },
    {
      "name": "Kaikalur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Kailaras",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Kailashahar",
      "state": "Tripura"
    },
    {
      "name": "Kaimganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kaimori",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Kaimur District",
      "state": "Bihar"
    },
    {
      "name": "Kaintragarh",
      "state": "Odisha"
    },
    {
      "name": "Kairana",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kaithal",
      "state": "Haryana"
    },
    {
      "name": "Kakching",
      "state": "Manipur"
    },
    {
      "name": "Kakdwip",
      "state": "West Bengal"
    },
    {
      "name": "Kakinada",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Kakori",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kakrala",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kalaburgi",
      "state": "Karnataka"
    },
    {
      "name": "Kaladhungi",
      "state": "Uttarakhand"
    },
    {
      "name": "Kalagarh Project Colony",
      "state": "Uttarakhand"
    },
    {
      "name": "Kalahandi",
      "state": "Odisha"
    },
    {
      "name": "Kalaikunda",
      "state": "West Bengal"
    },
    {
      "name": "Kalakkadu",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kalamassery",
      "state": "Kerala"
    },
    {
      "name": "Kalamb",
      "state": "Maharashtra"
    },
    {
      "name": "Kalamb, Osmanabad",
      "state": "Maharashtra"
    },
    {
      "name": "Kalamboli",
      "state": "Maharashtra"
    },
    {
      "name": "Kalamnuri",
      "state": "Maharashtra"
    },
    {
      "name": "Kalanaur",
      "state": "Haryana"
    },
    {
      "name": "Kalanaur",
      "state": "Punjab"
    },
    {
      "name": "Kalanwali",
      "state": "Haryana"
    },
    {
      "name": "Kalas",
      "state": "Maharashtra"
    },
    {
      "name": "Kalavad",
      "state": "Gujarat"
    },
    {
      "name": "Kalavai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kalavoor",
      "state": "Kerala"
    },
    {
      "name": "Kalghatgi",
      "state": "Karnataka"
    },
    {
      "name": "Kali(DK)",
      "state": "Maharashtra"
    },
    {
      "name": "Kaliaganj",
      "state": "West Bengal"
    },
    {
      "name": "Kalikapur",
      "state": "Jharkhand"
    },
    {
      "name": "Kalimpong",
      "state": "West Bengal"
    },
    {
      "name": "Kalinagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kalka",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Kallakkurichchi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kallidaikurichi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kallupatti",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kalmeshwar",
      "state": "Maharashtra"
    },
    {
      "name": "Kalna",
      "state": "West Bengal"
    },
    {
      "name": "Kalol",
      "state": "Gujarat"
    },
    {
      "name": "Kalpatta",
      "state": "Kerala"
    },
    {
      "name": "Kalpi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kalugumalai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kalundri",
      "state": "Maharashtra"
    },
    {
      "name": "Kalyan",
      "state": "Maharashtra"
    },
    {
      "name": "Kalyandurg",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Kalyani",
      "state": "West Bengal"
    },
    {
      "name": "Kalyani Nagar",
      "state": "Maharashtra"
    },
    {
      "name": "Kalyanpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kamakhyanagar",
      "state": "Odisha"
    },
    {
      "name": "Kamalapuram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Kamalganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kamalpur",
      "state": "Tripura"
    },
    {
      "name": "Kaman",
      "state": "Rajasthan"
    },
    {
      "name": "Kamareddi",
      "state": "Telangana"
    },
    {
      "name": "Kamareddy",
      "state": "Telangana"
    },
    {
      "name": "Kamargaon",
      "state": "Maharashtra"
    },
    {
      "name": "Kamarhati",
      "state": "West Bengal"
    },
    {
      "name": "Kamarpukur",
      "state": "West Bengal"
    },
    {
      "name": "Kamatgi",
      "state": "Maharashtra"
    },
    {
      "name": "Kampil",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kampli",
      "state": "Karnataka"
    },
    {
      "name": "Kamptee",
      "state": "Maharashtra"
    },
    {
      "name": "Kamrup",
      "state": "Assam"
    },
    {
      "name": "Kamrup Metropolitan",
      "state": "Assam"
    },
    {
      "name": "Kamthi",
      "state": "Maharashtra"
    },
    {
      "name": "Kamuthi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kanadukattan",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kancheepuram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kanchipuram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kanchrapara",
      "state": "West Bengal"
    },
    {
      "name": "Kandhamal",
      "state": "Odisha"
    },
    {
      "name": "Kandhla",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kandi",
      "state": "West Bengal"
    },
    {
      "name": "Kandla",
      "state": "Gujarat"
    },
    {
      "name": "Kandla port",
      "state": "Gujarat"
    },
    {
      "name": "Kandra",
      "state": "Jharkhand"
    },
    {
      "name": "Kandri",
      "state": "Maharashtra"
    },
    {
      "name": "Kandukur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Kangar",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Kangayam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kangra",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Kanigiri",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Kanina Khas",
      "state": "Haryana"
    },
    {
      "name": "Kankanhalli",
      "state": "Karnataka"
    },
    {
      "name": "Kankauli",
      "state": "Maharashtra"
    },
    {
      "name": "Kankavli",
      "state": "Maharashtra"
    },
    {
      "name": "Kanke",
      "state": "Jharkhand"
    },
    {
      "name": "Kanker",
      "state": "Chhattisgarh"
    },
    {
      "name": "Kankipadu",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Kankon",
      "state": "Goa"
    },
    {
      "name": "Kannad",
      "state": "Maharashtra"
    },
    {
      "name": "Kannangad",
      "state": "Kerala"
    },
    {
      "name": "Kannauj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kannavam",
      "state": "Kerala"
    },
    {
      "name": "Kanniyakumari",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kannod",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Kannur",
      "state": "Kerala"
    },
    {
      "name": "Kanodar",
      "state": "Gujarat"
    },
    {
      "name": "Kanor",
      "state": "Rajasthan"
    },
    {
      "name": "Kanpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kanpur Dehat",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kant",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kantabanji",
      "state": "Odisha"
    },
    {
      "name": "Kanth",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kantilo",
      "state": "Odisha"
    },
    {
      "name": "Kanuru",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Kapadvanj",
      "state": "Gujarat"
    },
    {
      "name": "Kapren",
      "state": "Rajasthan"
    },
    {
      "name": "Kaptanganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kapurthala",
      "state": "Punjab"
    },
    {
      "name": "Kara",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Karad",
      "state": "Maharashtra"
    },
    {
      "name": "Karaikal",
      "state": "Puducherry"
    },
    {
      "name": "Karaikkudi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Karajagi",
      "state": "Maharashtra"
    },
    {
      "name": "Karamadai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Karambakkudi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Karamsad",
      "state": "Gujarat"
    },
    {
      "name": "Karanja",
      "state": "Maharashtra"
    },
    {
      "name": "Karanja Lad",
      "state": "Maharashtra"
    },
    {
      "name": "Karanpur",
      "state": "Rajasthan"
    },
    {
      "name": "Karari",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Karauli",
      "state": "Rajasthan"
    },
    {
      "name": "Karbi Anglong",
      "state": "Assam"
    },
    {
      "name": "Karbigwan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Karchana",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kareli",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Karera",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Kargil",
      "state": "Ladakh"
    },
    {
      "name": "Karhal",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kariana",
      "state": "Gujarat"
    },
    {
      "name": "Kariapatti",
      "state": "Tamil Nadu"
    },
    {
      "name": "Karimganj",
      "state": "Assam"
    },
    {
      "name": "Karimnagar",
      "state": "Telangana"
    },
    {
      "name": "Karimpur",
      "state": "West Bengal"
    },
    {
      "name": "Karjan",
      "state": "Gujarat"
    },
    {
      "name": "Karjat",
      "state": "Maharashtra"
    },
    {
      "name": "Karkala",
      "state": "Karnataka"
    },
    {
      "name": "Karkamb",
      "state": "Maharashtra"
    },
    {
      "name": "Karmala",
      "state": "Maharashtra"
    },
    {
      "name": "Karnal",
      "state": "Haryana"
    },
    {
      "name": "Karol Bagh",
      "state": "Delhi"
    },
    {
      "name": "Karrapur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Kartarpur",
      "state": "Punjab"
    },
    {
      "name": "Karumbakkam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Karur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Karwar",
      "state": "Karnataka"
    },
    {
      "name": "Kasara",
      "state": "Maharashtra"
    },
    {
      "name": "Kasaragod",
      "state": "Kerala"
    },
    {
      "name": "Kasaragod District",
      "state": "Kerala"
    },
    {
      "name": "Kasauli",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Kasba",
      "state": "Bihar"
    },
    {
      "name": "Kasganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kashipur",
      "state": "Uttarakhand"
    },
    {
      "name": "Kasoda",
      "state": "Maharashtra"
    },
    {
      "name": "Kasrawad",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Katangi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Katghora",
      "state": "Chhattisgarh"
    },
    {
      "name": "Kathor",
      "state": "Gujarat"
    },
    {
      "name": "Kathua",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Kati",
      "state": "Maharashtra"
    },
    {
      "name": "Katihar",
      "state": "Bihar"
    },
    {
      "name": "Katni",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Katol",
      "state": "Maharashtra"
    },
    {
      "name": "Katpadi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Katpur",
      "state": "Gujarat"
    },
    {
      "name": "Katra",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Katra",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Katral",
      "state": "Maharashtra"
    },
    {
      "name": "Katras",
      "state": "Jharkhand"
    },
    {
      "name": "Kattanam",
      "state": "Kerala"
    },
    {
      "name": "Kattivakkam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kattupputtur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Katwa",
      "state": "West Bengal"
    },
    {
      "name": "Kausani",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kaushambi District",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kavali",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Kavalur",
      "state": "Karnataka"
    },
    {
      "name": "Kavant",
      "state": "Gujarat"
    },
    {
      "name": "Kavaratti",
      "state": "Lakshadweep"
    },
    {
      "name": "Kaveripatnam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kawant",
      "state": "Gujarat"
    },
    {
      "name": "Kawardha",
      "state": "Chhattisgarh"
    },
    {
      "name": "Kayalpattinam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kayankulam",
      "state": "Kerala"
    },
    {
      "name": "Kayattar",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kayavarohan",
      "state": "Gujarat"
    },
    {
      "name": "Keelakarai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kekri",
      "state": "Rajasthan"
    },
    {
      "name": "Kelamangalam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kemri",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kenda",
      "state": "West Bengal"
    },
    {
      "name": "Kendrapara",
      "state": "Odisha"
    },
    {
      "name": "Kendraparha",
      "state": "Odisha"
    },
    {
      "name": "Kenduadih",
      "state": "Jharkhand"
    },
    {
      "name": "Kendujhar",
      "state": "Odisha"
    },
    {
      "name": "Kerur",
      "state": "Karnataka"
    },
    {
      "name": "Kerwada",
      "state": "Gujarat"
    },
    {
      "name": "Keshabpur",
      "state": "West Bengal"
    },
    {
      "name": "Keshod",
      "state": "Gujarat"
    },
    {
      "name": "Keshorai Patan",
      "state": "Rajasthan"
    },
    {
      "name": "Kesinga",
      "state": "Odisha"
    },
    {
      "name": "Khachrod",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Khada",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Khadki",
      "state": "Maharashtra"
    },
    {
      "name": "Khaga",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Khagaria",
      "state": "Bihar"
    },
    {
      "name": "Khagaul",
      "state": "Bihar"
    },
    {
      "name": "Khailar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Khailar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Khair",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Khairabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Khairagarh",
      "state": "Chhattisgarh"
    },
    {
      "name": "Khajuraho Group of Monuments",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Khalapur",
      "state": "Maharashtra"
    },
    {
      "name": "Khalilabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Khallar",
      "state": "Maharashtra"
    },
    {
      "name": "Khallikot",
      "state": "Odisha"
    },
    {
      "name": "Khamanon",
      "state": "Punjab"
    },
    {
      "name": "Khamaria",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Khambhalia",
      "state": "Gujarat"
    },
    {
      "name": "Khambhat",
      "state": "Gujarat"
    },
    {
      "name": "Khamgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Khamharia",
      "state": "Chhattisgarh"
    },
    {
      "name": "Khammam",
      "state": "Telangana"
    },
    {
      "name": "Khanapur",
      "state": "Karnataka"
    },
    {
      "name": "Khanapur",
      "state": "Maharashtra"
    },
    {
      "name": "Khandala",
      "state": "Maharashtra"
    },
    {
      "name": "Khandela",
      "state": "Rajasthan"
    },
    {
      "name": "Khandwa",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Khandwa district",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Khangaon",
      "state": "Maharashtra"
    },
    {
      "name": "Khanna",
      "state": "Punjab"
    },
    {
      "name": "Khanpur",
      "state": "Rajasthan"
    },
    {
      "name": "Khanpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Khapa",
      "state": "Maharashtra"
    },
    {
      "name": "Kharagpur",
      "state": "Bihar"
    },
    {
      "name": "Kharagpur",
      "state": "West Bengal"
    },
    {
      "name": "Kharakvasla",
      "state": "Maharashtra"
    },
    {
      "name": "Kharar",
      "state": "Punjab"
    },
    {
      "name": "Kharar",
      "state": "West Bengal"
    },
    {
      "name": "Kharba",
      "state": "West Bengal"
    },
    {
      "name": "Kharda",
      "state": "Maharashtra"
    },
    {
      "name": "Khardaha",
      "state": "West Bengal"
    },
    {
      "name": "Kharela",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Khargapur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Kharghar",
      "state": "Maharashtra"
    },
    {
      "name": "Khargone",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Khargupur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kharhial",
      "state": "Odisha"
    },
    {
      "name": "Kharkhauda",
      "state": "Haryana"
    },
    {
      "name": "Kharkhauda",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kharod",
      "state": "Chhattisgarh"
    },
    {
      "name": "Kharsawan",
      "state": "Jharkhand"
    },
    {
      "name": "Kharsia",
      "state": "Chhattisgarh"
    },
    {
      "name": "Kharsundi",
      "state": "Maharashtra"
    },
    {
      "name": "Kharupatia",
      "state": "Assam"
    },
    {
      "name": "Khatauli",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Khategaon",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Khatima",
      "state": "Uttarakhand"
    },
    {
      "name": "Khatra",
      "state": "West Bengal"
    },
    {
      "name": "Khaur",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Khavda",
      "state": "Gujarat"
    },
    {
      "name": "Khawhai",
      "state": "Mizoram"
    },
    {
      "name": "Khed",
      "state": "Maharashtra"
    },
    {
      "name": "Kheda",
      "state": "Gujarat"
    },
    {
      "name": "Khedbrahma",
      "state": "Gujarat"
    },
    {
      "name": "Khede",
      "state": "Maharashtra"
    },
    {
      "name": "Khedoi",
      "state": "Gujarat"
    },
    {
      "name": "Khekra",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Khemkaran",
      "state": "Punjab"
    },
    {
      "name": "Kherali",
      "state": "Gujarat"
    },
    {
      "name": "Kheralu",
      "state": "Gujarat"
    },
    {
      "name": "Kheri",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kheri Sampla",
      "state": "Haryana"
    },
    {
      "name": "Khetia",
      "state": "Maharashtra"
    },
    {
      "name": "Khetri",
      "state": "Rajasthan"
    },
    {
      "name": "Khilchipur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Khirkiyan",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Khoni",
      "state": "Maharashtra"
    },
    {
      "name": "Khonsa",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Khopoli",
      "state": "Maharashtra"
    },
    {
      "name": "Khordha",
      "state": "Odisha"
    },
    {
      "name": "Khowai",
      "state": "Tripura"
    },
    {
      "name": "Khudaganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Khujner",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Khuldabad",
      "state": "Maharashtra"
    },
    {
      "name": "Khunti",
      "state": "Jharkhand"
    },
    {
      "name": "Khurai",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Khurda",
      "state": "Odisha"
    },
    {
      "name": "Khurja",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Khusropur",
      "state": "Bihar"
    },
    {
      "name": "Khutar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kichha",
      "state": "Uttarakhand"
    },
    {
      "name": "Kil Bhuvanagiri",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kilvelur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kinnaur",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Kinwat",
      "state": "Maharashtra"
    },
    {
      "name": "Kirakat",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kirandul",
      "state": "Chhattisgarh"
    },
    {
      "name": "Kiranur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kiraoli",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kiratpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kiri Buru",
      "state": "Odisha"
    },
    {
      "name": "Kirnahar",
      "state": "West Bengal"
    },
    {
      "name": "Kishanganj",
      "state": "Bihar"
    },
    {
      "name": "Kishangarh",
      "state": "Rajasthan"
    },
    {
      "name": "Kishanpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kishanpur baral",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kishni",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kishtwar",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Kithor",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kizhake Chalakudi",
      "state": "Kerala"
    },
    {
      "name": "Koath",
      "state": "Bihar"
    },
    {
      "name": "Kodagu",
      "state": "Karnataka"
    },
    {
      "name": "Kodaikanal",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kodala",
      "state": "Odisha"
    },
    {
      "name": "Kodar",
      "state": "Telangana"
    },
    {
      "name": "Kodarma",
      "state": "Jharkhand"
    },
    {
      "name": "Kodigenahalli",
      "state": "Karnataka"
    },
    {
      "name": "Kodinar",
      "state": "Gujarat"
    },
    {
      "name": "Kodlipet",
      "state": "Karnataka"
    },
    {
      "name": "Kodoli",
      "state": "Maharashtra"
    },
    {
      "name": "Kodumudi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kodungallur",
      "state": "Kerala"
    },
    {
      "name": "Koelwar",
      "state": "Bihar"
    },
    {
      "name": "Kohima",
      "state": "Nagaland"
    },
    {
      "name": "Kokrajhar",
      "state": "Assam"
    },
    {
      "name": "Kolanukonda",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Kolar",
      "state": "Karnataka"
    },
    {
      "name": "Kolaras",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Kolasib",
      "state": "Mizoram"
    },
    {
      "name": "Kolasib district",
      "state": "Mizoram"
    },
    {
      "name": "Kolhapur",
      "state": "Maharashtra"
    },
    {
      "name": "Kolkata",
      "state": "West Bengal"
    },
    {
      "name": "Kollam",
      "state": "Kerala"
    },
    {
      "name": "Kollegal",
      "state": "Karnataka"
    },
    {
      "name": "Kombai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Konanur",
      "state": "Karnataka"
    },
    {
      "name": "Konarka",
      "state": "Odisha"
    },
    {
      "name": "Konch",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kondagaon",
      "state": "Chhattisgarh"
    },
    {
      "name": "Kondalwadi",
      "state": "Maharashtra"
    },
    {
      "name": "Kondapalle",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Kondhali",
      "state": "Maharashtra"
    },
    {
      "name": "Konganapuram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Konnagar",
      "state": "West Bengal"
    },
    {
      "name": "Konnur",
      "state": "Karnataka"
    },
    {
      "name": "Koothanallur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kopaganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kopar Khairane",
      "state": "Maharashtra"
    },
    {
      "name": "Kopargaon",
      "state": "Maharashtra"
    },
    {
      "name": "Kopela",
      "state": "Maharashtra"
    },
    {
      "name": "Koppa",
      "state": "Karnataka"
    },
    {
      "name": "Koppal",
      "state": "Karnataka"
    },
    {
      "name": "Koradachcheri",
      "state": "Tamil Nadu"
    },
    {
      "name": "Koradi",
      "state": "Maharashtra"
    },
    {
      "name": "Korampallam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Koraput",
      "state": "Odisha"
    },
    {
      "name": "Koratagere",
      "state": "Karnataka"
    },
    {
      "name": "Koratla",
      "state": "Telangana"
    },
    {
      "name": "Korba",
      "state": "Chhattisgarh"
    },
    {
      "name": "Koregaon",
      "state": "Maharashtra"
    },
    {
      "name": "Koriya",
      "state": "Chhattisgarh"
    },
    {
      "name": "Korukollu",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Korwai",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Kosamba",
      "state": "Gujarat"
    },
    {
      "name": "Kosi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kosigi",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Kot Isa Khan",
      "state": "Punjab"
    },
    {
      "name": "Kota",
      "state": "Chhattisgarh"
    },
    {
      "name": "Kota",
      "state": "Rajasthan"
    },
    {
      "name": "Kota",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kotagiri",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kotamangalam",
      "state": "Kerala"
    },
    {
      "name": "Kotaparh",
      "state": "Chhattisgarh"
    },
    {
      "name": "Kotar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Kotdwara",
      "state": "Uttarakhand"
    },
    {
      "name": "Kothapet",
      "state": "Telangana"
    },
    {
      "name": "Kothara",
      "state": "Gujarat"
    },
    {
      "name": "Kotharia",
      "state": "Gujarat"
    },
    {
      "name": "Kothi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Kotkapura",
      "state": "Punjab"
    },
    {
      "name": "Kotkhai",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Kotla",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Kotma",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Kotputli",
      "state": "Rajasthan"
    },
    {
      "name": "Kotra",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kottagudem",
      "state": "Telangana"
    },
    {
      "name": "Kottaiyur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kottapalli",
      "state": "Telangana"
    },
    {
      "name": "Kottayam",
      "state": "Kerala"
    },
    {
      "name": "Kotturu",
      "state": "Karnataka"
    },
    {
      "name": "Kotwa",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Kovalam",
      "state": "Kerala"
    },
    {
      "name": "Kovilpatti",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kovvur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Koynanagar",
      "state": "Maharashtra"
    },
    {
      "name": "Kozhikode",
      "state": "Kerala"
    },
    {
      "name": "Krishna",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Krishnagiri",
      "state": "Tamil Nadu"
    },
    {
      "name": "Krishnanagar",
      "state": "West Bengal"
    },
    {
      "name": "Krishnapur",
      "state": "West Bengal"
    },
    {
      "name": "Krishnarajpet",
      "state": "Karnataka"
    },
    {
      "name": "Kshirpai",
      "state": "West Bengal"
    },
    {
      "name": "Kuchaiburi",
      "state": "Odisha"
    },
    {
      "name": "Kuchaman",
      "state": "Rajasthan"
    },
    {
      "name": "Kuchera",
      "state": "Rajasthan"
    },
    {
      "name": "Kuchesar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kuchinda",
      "state": "Odisha"
    },
    {
      "name": "Kud",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Kudachi",
      "state": "Karnataka"
    },
    {
      "name": "Kudal",
      "state": "Maharashtra"
    },
    {
      "name": "Kudarkot",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kudligi",
      "state": "Karnataka"
    },
    {
      "name": "Kuhi",
      "state": "Maharashtra"
    },
    {
      "name": "Kuju",
      "state": "Jharkhand"
    },
    {
      "name": "Kukarmunda",
      "state": "Gujarat"
    },
    {
      "name": "Kukatpalli",
      "state": "Telangana"
    },
    {
      "name": "Kukma",
      "state": "Gujarat"
    },
    {
      "name": "Kukshi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Kulattur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kulgam",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Kulittalai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kulpahar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kulpi",
      "state": "West Bengal"
    },
    {
      "name": "Kultali",
      "state": "West Bengal"
    },
    {
      "name": "Kulti",
      "state": "West Bengal"
    },
    {
      "name": "Kulu",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Kumaralingam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kumbakonam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kumbalam",
      "state": "Kerala"
    },
    {
      "name": "Kumbhraj",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Kumhari",
      "state": "Chhattisgarh"
    },
    {
      "name": "Kumher",
      "state": "Rajasthan"
    },
    {
      "name": "Kumsi",
      "state": "Karnataka"
    },
    {
      "name": "Kumta",
      "state": "Karnataka"
    },
    {
      "name": "Kunda",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kundarkhi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kundarki",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kundgol",
      "state": "Karnataka"
    },
    {
      "name": "Kundla",
      "state": "Gujarat"
    },
    {
      "name": "Kunigal",
      "state": "Karnataka"
    },
    {
      "name": "Kunnamangalam",
      "state": "Kerala"
    },
    {
      "name": "Kunnamkulam",
      "state": "Kerala"
    },
    {
      "name": "Kunnattur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kunnumma",
      "state": "Kerala"
    },
    {
      "name": "Kuppam",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Kupwara",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Kurandvad",
      "state": "Maharashtra"
    },
    {
      "name": "Kurankhed",
      "state": "Maharashtra"
    },
    {
      "name": "Kurara",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kurduvadi",
      "state": "Maharashtra"
    },
    {
      "name": "Kurebhar,saidkhanpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kurgunta",
      "state": "Karnataka"
    },
    {
      "name": "Kurinjippadi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kurnool",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Kurseong",
      "state": "West Bengal"
    },
    {
      "name": "Kurud",
      "state": "Chhattisgarh"
    },
    {
      "name": "Kurukshetra",
      "state": "Haryana"
    },
    {
      "name": "Kurung Kumey",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Kushalgarh",
      "state": "Rajasthan"
    },
    {
      "name": "Kushalnagar",
      "state": "Karnataka"
    },
    {
      "name": "Kushinagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kushtagi",
      "state": "Karnataka"
    },
    {
      "name": "Kusmara",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kusumba",
      "state": "Maharashtra"
    },
    {
      "name": "Kutch district",
      "state": "Gujarat"
    },
    {
      "name": "Kuthaund",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Kutiatodu",
      "state": "Kerala"
    },
    {
      "name": "Kutiyana",
      "state": "Gujarat"
    },
    {
      "name": "Kuttalam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kuttampuzha",
      "state": "Kerala"
    },
    {
      "name": "Kuzhithurai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Kyathampalle",
      "state": "Telangana"
    },
    {
      "name": "Kyelang",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Lachhmangarh Sikar",
      "state": "Rajasthan"
    },
    {
      "name": "Ladakh",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Ladnun",
      "state": "Rajasthan"
    },
    {
      "name": "Ladol",
      "state": "Gujarat"
    },
    {
      "name": "Ladwa",
      "state": "Haryana"
    },
    {
      "name": "Lahar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Laharpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Lahul and Spiti",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Lakhandur",
      "state": "Maharashtra"
    },
    {
      "name": "Lakheri",
      "state": "Rajasthan"
    },
    {
      "name": "Lakhimpur",
      "state": "Assam"
    },
    {
      "name": "Lakhimpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Lakhipur",
      "state": "Assam"
    },
    {
      "name": "Lakhisarai",
      "state": "Bihar"
    },
    {
      "name": "Lakhna",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Lakhnadon",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Lakhpat",
      "state": "Gujarat"
    },
    {
      "name": "Lakhtar",
      "state": "Gujarat"
    },
    {
      "name": "Laksar",
      "state": "Uttarakhand"
    },
    {
      "name": "Lakshadweep",
      "state": "Lakshadweep"
    },
    {
      "name": "Lakshettipet",
      "state": "Telangana"
    },
    {
      "name": "Lakshmeshwar",
      "state": "Karnataka"
    },
    {
      "name": "Lal Bahadur Nagar",
      "state": "Telangana"
    },
    {
      "name": "Lala",
      "state": "Assam"
    },
    {
      "name": "Lalam",
      "state": "Kerala"
    },
    {
      "name": "Lalganj",
      "state": "Bihar"
    },
    {
      "name": "Lalganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Lalgarh",
      "state": "West Bengal"
    },
    {
      "name": "Lalgola",
      "state": "West Bengal"
    },
    {
      "name": "Lalgudi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Lalitpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Lalpur",
      "state": "Gujarat"
    },
    {
      "name": "Lalsot",
      "state": "Rajasthan"
    },
    {
      "name": "Lambhua",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Langhnaj",
      "state": "Gujarat"
    },
    {
      "name": "Lanja",
      "state": "Maharashtra"
    },
    {
      "name": "Lansdowne",
      "state": "Uttarakhand"
    },
    {
      "name": "Lar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Lasalgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Latehar",
      "state": "Jharkhand"
    },
    {
      "name": "Lathi",
      "state": "Gujarat"
    },
    {
      "name": "Latur",
      "state": "Maharashtra"
    },
    {
      "name": "Laungowal",
      "state": "Punjab"
    },
    {
      "name": "Lavasa",
      "state": "Maharashtra"
    },
    {
      "name": "Lawar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Lawar Khas",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Lawngtlai",
      "state": "Mizoram"
    },
    {
      "name": "Leh",
      "state": "Ladakh"
    },
    {
      "name": "Leteri",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Limbdi",
      "state": "Gujarat"
    },
    {
      "name": "Limkheda",
      "state": "Gujarat"
    },
    {
      "name": "Lingsugur",
      "state": "Karnataka"
    },
    {
      "name": "Lodhikheda",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Lohaghat",
      "state": "Uttarakhand"
    },
    {
      "name": "Lohardaga",
      "state": "Jharkhand"
    },
    {
      "name": "Loharu",
      "state": "Haryana"
    },
    {
      "name": "Lohit District",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Lohogaon",
      "state": "Maharashtra"
    },
    {
      "name": "Lonar",
      "state": "Maharashtra"
    },
    {
      "name": "Lonavla",
      "state": "Maharashtra"
    },
    {
      "name": "Londa",
      "state": "Karnataka"
    },
    {
      "name": "Loni",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Lormi",
      "state": "Chhattisgarh"
    },
    {
      "name": "Losal",
      "state": "Rajasthan"
    },
    {
      "name": "Lower Dibang Valley",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Lower Subansiri",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Loyabad",
      "state": "West Bengal"
    },
    {
      "name": "Luckeesarai",
      "state": "Bihar"
    },
    {
      "name": "Lucknow",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Lucknow District",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ludhiana",
      "state": "Punjab"
    },
    {
      "name": "Lumding Railway Colony",
      "state": "Assam"
    },
    {
      "name": "Lunavada",
      "state": "Gujarat"
    },
    {
      "name": "Lunglei",
      "state": "Mizoram"
    },
    {
      "name": "Machalpur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Macherla",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Machhali Shahar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Machhiwara",
      "state": "Punjab"
    },
    {
      "name": "Machhlishahr",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Machilipatnam",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Madambakkam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Madanapalle",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Madanpur",
      "state": "West Bengal"
    },
    {
      "name": "Maddagiri",
      "state": "Karnataka"
    },
    {
      "name": "Maddur",
      "state": "Karnataka"
    },
    {
      "name": "Madgaon",
      "state": "Goa"
    },
    {
      "name": "Madhavpur Ghed",
      "state": "Gujarat"
    },
    {
      "name": "Madhepura",
      "state": "Bihar"
    },
    {
      "name": "Madhi",
      "state": "Gujarat"
    },
    {
      "name": "Madhipura",
      "state": "Bihar"
    },
    {
      "name": "Madhoganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Madhogarh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Madhogarh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Madhubani",
      "state": "Bihar"
    },
    {
      "name": "Madhupur",
      "state": "Jharkhand"
    },
    {
      "name": "Madhyamgram",
      "state": "West Bengal"
    },
    {
      "name": "Madikeri",
      "state": "Karnataka"
    },
    {
      "name": "Madipakkam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Madugula",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Madukkarai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Madukkur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Madurai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Madurantakam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Magadi",
      "state": "Karnataka"
    },
    {
      "name": "Magam",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Maghar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mahabaleshwar",
      "state": "Maharashtra"
    },
    {
      "name": "Mahaban",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mahabubabad",
      "state": "Telangana"
    },
    {
      "name": "Mahad",
      "state": "Maharashtra"
    },
    {
      "name": "Mahalingpur",
      "state": "Karnataka"
    },
    {
      "name": "Maham",
      "state": "Haryana"
    },
    {
      "name": "Mahape",
      "state": "Maharashtra"
    },
    {
      "name": "Maharajgani",
      "state": "Bihar"
    },
    {
      "name": "Maharajganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mahasamund",
      "state": "Chhattisgarh"
    },
    {
      "name": "Mahbubnagar",
      "state": "Telangana"
    },
    {
      "name": "Mahe",
      "state": "Puducherry"
    },
    {
      "name": "Mahemdavad",
      "state": "Gujarat"
    },
    {
      "name": "Mahendragarh",
      "state": "Haryana"
    },
    {
      "name": "Mahesana",
      "state": "Gujarat"
    },
    {
      "name": "Maheshwar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Mahgawan",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Mahiari",
      "state": "West Bengal"
    },
    {
      "name": "Mahim",
      "state": "Maharashtra"
    },
    {
      "name": "Mahisa",
      "state": "Gujarat"
    },
    {
      "name": "Mahishadal community development block",
      "state": "West Bengal"
    },
    {
      "name": "Mahmudabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mahoba",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Maholi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mahrajganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mahrajganj (Raebareli)",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mahroni",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mahudha",
      "state": "Gujarat"
    },
    {
      "name": "Mahul",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mahur",
      "state": "Assam"
    },
    {
      "name": "Mahuva",
      "state": "Gujarat"
    },
    {
      "name": "Mahuva (Surat)",
      "state": "Gujarat"
    },
    {
      "name": "Mahwah",
      "state": "Rajasthan"
    },
    {
      "name": "Mahē",
      "state": "Kerala"
    },
    {
      "name": "Maibong",
      "state": "Assam"
    },
    {
      "name": "Maihar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Mailani",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mainaguri",
      "state": "West Bengal"
    },
    {
      "name": "Maindargi",
      "state": "Maharashtra"
    },
    {
      "name": "Mainpuri",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mairang",
      "state": "Meghalaya"
    },
    {
      "name": "Mairwa",
      "state": "Bihar"
    },
    {
      "name": "Majalgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Majholi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Majhupur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Majitha",
      "state": "Punjab"
    },
    {
      "name": "Makanpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Makhjan",
      "state": "Maharashtra"
    },
    {
      "name": "Makhu",
      "state": "Punjab"
    },
    {
      "name": "Makrana",
      "state": "Rajasthan"
    },
    {
      "name": "Maksi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Makum",
      "state": "Assam"
    },
    {
      "name": "Malabar Hill",
      "state": "Maharashtra"
    },
    {
      "name": "Malad",
      "state": "Maharashtra"
    },
    {
      "name": "Malakanagiri",
      "state": "Odisha"
    },
    {
      "name": "Malappuram",
      "state": "Kerala"
    },
    {
      "name": "Malasa",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Malaut",
      "state": "Punjab"
    },
    {
      "name": "Malavalli",
      "state": "Karnataka"
    },
    {
      "name": "Malegaon",
      "state": "Maharashtra"
    },
    {
      "name": "Malerkotla",
      "state": "Punjab"
    },
    {
      "name": "Malhargarh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Malihabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Malkajgiri",
      "state": "Telangana"
    },
    {
      "name": "Malkangiri",
      "state": "Odisha"
    },
    {
      "name": "Malkapur",
      "state": "Maharashtra"
    },
    {
      "name": "Malkera",
      "state": "Jharkhand"
    },
    {
      "name": "Mallapuram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Mallasamudram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Mallur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Malpe",
      "state": "Karnataka"
    },
    {
      "name": "Malpur",
      "state": "Gujarat"
    },
    {
      "name": "Malpura",
      "state": "Rajasthan"
    },
    {
      "name": "Malur",
      "state": "Karnataka"
    },
    {
      "name": "Malvan",
      "state": "Maharashtra"
    },
    {
      "name": "Mamit",
      "state": "Mizoram"
    },
    {
      "name": "Manali",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Manali",
      "state": "Tamil Nadu"
    },
    {
      "name": "Manalurpettai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Manamadurai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Manappakkam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Manapparai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Manasa",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Manavadar",
      "state": "Gujarat"
    },
    {
      "name": "Manavalakurichi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Manawar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Manchar",
      "state": "Maharashtra"
    },
    {
      "name": "Mancheral",
      "state": "Telangana"
    },
    {
      "name": "Mandal",
      "state": "Gujarat"
    },
    {
      "name": "Mandal",
      "state": "Rajasthan"
    },
    {
      "name": "Mandalgarh",
      "state": "Rajasthan"
    },
    {
      "name": "Mandamarri",
      "state": "Telangana"
    },
    {
      "name": "Mandangad",
      "state": "Maharashtra"
    },
    {
      "name": "Mandapam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Mandapeta",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Mandasa",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Mandawar",
      "state": "Rajasthan"
    },
    {
      "name": "Mandawar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mandhal",
      "state": "Maharashtra"
    },
    {
      "name": "Mandholi Kalan",
      "state": "Haryana"
    },
    {
      "name": "Mandi",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Mandideep",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Mandla",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Mandleshwar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Mandsaur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Mandvi",
      "state": "Gujarat"
    },
    {
      "name": "Mandvi (Surat)",
      "state": "Gujarat"
    },
    {
      "name": "Mandwa",
      "state": "Maharashtra"
    },
    {
      "name": "Mandya",
      "state": "Karnataka"
    },
    {
      "name": "Maner",
      "state": "Bihar"
    },
    {
      "name": "Mangalagiri",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Mangalam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Mangaldai",
      "state": "Assam"
    },
    {
      "name": "Mangaluru",
      "state": "Karnataka"
    },
    {
      "name": "Mangan",
      "state": "Sikkim"
    },
    {
      "name": "Mangaon",
      "state": "Maharashtra"
    },
    {
      "name": "Mangawan",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Manglaur",
      "state": "Uttarakhand"
    },
    {
      "name": "Mangrol",
      "state": "Gujarat"
    },
    {
      "name": "Mangrol",
      "state": "Rajasthan"
    },
    {
      "name": "Mangrol (Junagadh)",
      "state": "Gujarat"
    },
    {
      "name": "Mangrul Pir",
      "state": "Maharashtra"
    },
    {
      "name": "Maniar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Manihari",
      "state": "Bihar"
    },
    {
      "name": "Manikpara",
      "state": "West Bengal"
    },
    {
      "name": "Manikpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Manipal",
      "state": "Karnataka"
    },
    {
      "name": "Manjeri",
      "state": "Kerala"
    },
    {
      "name": "Manjhanpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Manjlegaon",
      "state": "Maharashtra"
    },
    {
      "name": "Manjēshvar",
      "state": "Kerala"
    },
    {
      "name": "Mankachar",
      "state": "Meghalaya"
    },
    {
      "name": "Mankapur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mankeshwar",
      "state": "Maharashtra"
    },
    {
      "name": "Mankhurd",
      "state": "Maharashtra"
    },
    {
      "name": "Manmad",
      "state": "Maharashtra"
    },
    {
      "name": "Mannarakkat",
      "state": "Kerala"
    },
    {
      "name": "Mannargudi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Manohar Thana",
      "state": "Rajasthan"
    },
    {
      "name": "Manoharpur",
      "state": "Jharkhand"
    },
    {
      "name": "Manoharpur",
      "state": "Rajasthan"
    },
    {
      "name": "Manor",
      "state": "Maharashtra"
    },
    {
      "name": "Manpur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Mansa",
      "state": "Gujarat"
    },
    {
      "name": "Mansa",
      "state": "Punjab"
    },
    {
      "name": "Mansar",
      "state": "Maharashtra"
    },
    {
      "name": "Manthani",
      "state": "Telangana"
    },
    {
      "name": "Manuguru",
      "state": "Telangana"
    },
    {
      "name": "Manvi",
      "state": "Karnataka"
    },
    {
      "name": "Manwat",
      "state": "Maharashtra"
    },
    {
      "name": "Manwath",
      "state": "Maharashtra"
    },
    {
      "name": "Mapuca",
      "state": "Goa"
    },
    {
      "name": "Marahra",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Marakkanam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Marandahalli",
      "state": "Tamil Nadu"
    },
    {
      "name": "Marayur",
      "state": "Kerala"
    },
    {
      "name": "Maregaon",
      "state": "Maharashtra"
    },
    {
      "name": "Margherita",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Marhaura",
      "state": "Bihar"
    },
    {
      "name": "Mariahu",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mariani",
      "state": "Assam"
    },
    {
      "name": "Markapur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Masaurhi Buzurg",
      "state": "Bihar"
    },
    {
      "name": "Masila",
      "state": "West Bengal"
    },
    {
      "name": "Masinigudi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Mastiholi",
      "state": "Maharashtra"
    },
    {
      "name": "Masur, India",
      "state": "Maharashtra"
    },
    {
      "name": "Mataundh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mathabhanga",
      "state": "West Bengal"
    },
    {
      "name": "Matheran",
      "state": "Maharashtra"
    },
    {
      "name": "Mathura",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Matiali community development block",
      "state": "West Bengal"
    },
    {
      "name": "Matigara community development block",
      "state": "West Bengal"
    },
    {
      "name": "Mattanur",
      "state": "Kerala"
    },
    {
      "name": "Mattur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Matunga",
      "state": "Maharashtra"
    },
    {
      "name": "Mau",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Mau",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mau Aima",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mau Aimma",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Maudaha",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mauganj",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Maur Mandi",
      "state": "Punjab"
    },
    {
      "name": "Mauranwan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Maurawan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mavelikara",
      "state": "Kerala"
    },
    {
      "name": "Mavoor",
      "state": "Kerala"
    },
    {
      "name": "Mawana",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mawar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mayakonda",
      "state": "Karnataka"
    },
    {
      "name": "Mayang Imphal",
      "state": "Manipur"
    },
    {
      "name": "Mayiladuthurai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Mayurbhanj",
      "state": "Odisha"
    },
    {
      "name": "Mazagaon",
      "state": "Maharashtra"
    },
    {
      "name": "Medak",
      "state": "Telangana"
    },
    {
      "name": "Medchal",
      "state": "Telangana"
    },
    {
      "name": "Medchal Malkajgiri",
      "state": "Telangana"
    },
    {
      "name": "Medinipur",
      "state": "West Bengal"
    },
    {
      "name": "Meerut",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Meethari Marwar",
      "state": "Rajasthan"
    },
    {
      "name": "Meghraj",
      "state": "Gujarat"
    },
    {
      "name": "Mehdawal",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mehekar",
      "state": "Maharashtra"
    },
    {
      "name": "Mehergaon",
      "state": "Maharashtra"
    },
    {
      "name": "Mehkar",
      "state": "Maharashtra"
    },
    {
      "name": "Mehnagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mehndawal",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mehsana",
      "state": "Gujarat"
    },
    {
      "name": "Mejia community development block",
      "state": "West Bengal"
    },
    {
      "name": "Melukote",
      "state": "Karnataka"
    },
    {
      "name": "Melur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Memari",
      "state": "West Bengal"
    },
    {
      "name": "Mendarda",
      "state": "Gujarat"
    },
    {
      "name": "Merta",
      "state": "Rajasthan"
    },
    {
      "name": "Mettuppalaiyam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Mettur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Mhasla",
      "state": "Maharashtra"
    },
    {
      "name": "Mhasvad",
      "state": "Maharashtra"
    },
    {
      "name": "Mihona",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Milak",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Milkipur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Minjur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Miraj",
      "state": "Maharashtra"
    },
    {
      "name": "Miranpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Miranpur Katra",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mirganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mirialguda",
      "state": "Telangana"
    },
    {
      "name": "Mirik",
      "state": "West Bengal"
    },
    {
      "name": "Mirzapur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Misrikh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mithapur",
      "state": "Gujarat"
    },
    {
      "name": "Modasa",
      "state": "Gujarat"
    },
    {
      "name": "Moga",
      "state": "Punjab"
    },
    {
      "name": "Mohadi",
      "state": "Maharashtra"
    },
    {
      "name": "Mohali",
      "state": "Punjab"
    },
    {
      "name": "Mohan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mohanpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mohanpur community development block",
      "state": "West Bengal"
    },
    {
      "name": "Mohanur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Mohgaon",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Mohiuddinnagar",
      "state": "Bihar"
    },
    {
      "name": "Mohol",
      "state": "Maharashtra"
    },
    {
      "name": "Mohpa",
      "state": "Maharashtra"
    },
    {
      "name": "Moirang",
      "state": "Manipur"
    },
    {
      "name": "Mokameh",
      "state": "Bihar"
    },
    {
      "name": "Mokhada taluka",
      "state": "Maharashtra"
    },
    {
      "name": "Mokokchung",
      "state": "Nagaland"
    },
    {
      "name": "Mon",
      "state": "Nagaland"
    },
    {
      "name": "Monghyr",
      "state": "Bihar"
    },
    {
      "name": "Monoharpur",
      "state": "West Bengal"
    },
    {
      "name": "Mora, Maharashtra",
      "state": "Maharashtra"
    },
    {
      "name": "Moradabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Moram",
      "state": "Maharashtra"
    },
    {
      "name": "Moranha",
      "state": "Assam"
    },
    {
      "name": "Morar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Morbi",
      "state": "Gujarat"
    },
    {
      "name": "Morena",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Morigaon",
      "state": "Assam"
    },
    {
      "name": "Morinda",
      "state": "Punjab"
    },
    {
      "name": "Morjim",
      "state": "Goa"
    },
    {
      "name": "Mormugao",
      "state": "Goa"
    },
    {
      "name": "Morsi",
      "state": "Maharashtra"
    },
    {
      "name": "Morva (Hadaf)",
      "state": "Gujarat"
    },
    {
      "name": "Morwa",
      "state": "Gujarat"
    },
    {
      "name": "Moth",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mothihari",
      "state": "Bihar"
    },
    {
      "name": "Mowad",
      "state": "Maharashtra"
    },
    {
      "name": "Mubarakpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mudbidri",
      "state": "Karnataka"
    },
    {
      "name": "Muddebihal",
      "state": "Karnataka"
    },
    {
      "name": "Mudgal",
      "state": "Karnataka"
    },
    {
      "name": "Mudgere",
      "state": "Karnataka"
    },
    {
      "name": "Mudhol",
      "state": "Karnataka"
    },
    {
      "name": "Mudkhed",
      "state": "Maharashtra"
    },
    {
      "name": "Mudukulattur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Mughal Sarai",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mugma",
      "state": "Jharkhand"
    },
    {
      "name": "Muhammadabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mukerian",
      "state": "Punjab"
    },
    {
      "name": "Mukher",
      "state": "Maharashtra"
    },
    {
      "name": "Mukteshwar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mul",
      "state": "Maharashtra"
    },
    {
      "name": "Mulanur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Mulbagal",
      "state": "Karnataka"
    },
    {
      "name": "Mulgund",
      "state": "Karnataka"
    },
    {
      "name": "Mulher",
      "state": "Maharashtra"
    },
    {
      "name": "Mulki",
      "state": "Karnataka"
    },
    {
      "name": "Multai",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Mulund",
      "state": "Maharashtra"
    },
    {
      "name": "Muluppilagadu",
      "state": "Kerala"
    },
    {
      "name": "Mumbai",
      "state": "Maharashtra"
    },
    {
      "name": "Mumbai Suburban",
      "state": "Maharashtra"
    },
    {
      "name": "Mundargi",
      "state": "Karnataka"
    },
    {
      "name": "Mundgod",
      "state": "Karnataka"
    },
    {
      "name": "Mundi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Mundra",
      "state": "Gujarat"
    },
    {
      "name": "Mundwa",
      "state": "Rajasthan"
    },
    {
      "name": "Mungaoli",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Mungeli",
      "state": "Chhattisgarh"
    },
    {
      "name": "Munger",
      "state": "Bihar"
    },
    {
      "name": "Mungra Badshahpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Munirabad",
      "state": "Karnataka"
    },
    {
      "name": "Munnar",
      "state": "Kerala"
    },
    {
      "name": "Munsyari",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Muradabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Muradnagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Muragacha",
      "state": "West Bengal"
    },
    {
      "name": "Murbad",
      "state": "Maharashtra"
    },
    {
      "name": "Murgud",
      "state": "Maharashtra"
    },
    {
      "name": "Muri",
      "state": "West Bengal"
    },
    {
      "name": "Murliganj",
      "state": "Bihar"
    },
    {
      "name": "Mursan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Murshidabad",
      "state": "West Bengal"
    },
    {
      "name": "Murtajapur",
      "state": "Maharashtra"
    },
    {
      "name": "Murud (Raigad)",
      "state": "Maharashtra"
    },
    {
      "name": "Murud (Ratnagiri)",
      "state": "Maharashtra"
    },
    {
      "name": "Murudeshwara",
      "state": "Karnataka"
    },
    {
      "name": "Murum",
      "state": "Maharashtra"
    },
    {
      "name": "Murwara",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Musafir-Khana",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Musafirkhana",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Mushabani",
      "state": "Jharkhand"
    },
    {
      "name": "Musiri",
      "state": "Tamil Nadu"
    },
    {
      "name": "Mussoorie",
      "state": "Uttarakhand"
    },
    {
      "name": "Mustafabad",
      "state": "Haryana"
    },
    {
      "name": "Muttupet",
      "state": "Tamil Nadu"
    },
    {
      "name": "Muvattupula",
      "state": "Kerala"
    },
    {
      "name": "Muvattupuzha",
      "state": "Kerala"
    },
    {
      "name": "Muzaffarnagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Muzaffarpur",
      "state": "Bihar"
    },
    {
      "name": "Mysuru",
      "state": "Karnataka"
    },
    {
      "name": "Nabadwip",
      "state": "West Bengal"
    },
    {
      "name": "Nabagram",
      "state": "West Bengal"
    },
    {
      "name": "Nabarangpur",
      "state": "Odisha"
    },
    {
      "name": "Nabha",
      "state": "Punjab"
    },
    {
      "name": "Nabinagar",
      "state": "Bihar"
    },
    {
      "name": "Nadapuram",
      "state": "Kerala"
    },
    {
      "name": "Nadaun",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Nadbai",
      "state": "Rajasthan"
    },
    {
      "name": "Nadgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Nadia district",
      "state": "West Bengal"
    },
    {
      "name": "Nadiad",
      "state": "Gujarat"
    },
    {
      "name": "Nadiad,",
      "state": "Gujarat"
    },
    {
      "name": "Nadigaon",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Naduvannur",
      "state": "Kerala"
    },
    {
      "name": "Naduvattam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Nagamangala",
      "state": "Karnataka"
    },
    {
      "name": "Nagaon",
      "state": "Assam"
    },
    {
      "name": "Nagapattinam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Nagapur",
      "state": "Maharashtra"
    },
    {
      "name": "Nagar",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Nagar",
      "state": "Rajasthan"
    },
    {
      "name": "Nagar Karnul",
      "state": "Telangana"
    },
    {
      "name": "Nagari",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Nagarukhra",
      "state": "West Bengal"
    },
    {
      "name": "Nagaur",
      "state": "Rajasthan"
    },
    {
      "name": "Nagda",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Nagercoil",
      "state": "Tamil Nadu"
    },
    {
      "name": "Nagina",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nagireddipalli",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Nagla",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nagod",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Nagothana",
      "state": "Maharashtra"
    },
    {
      "name": "Nagpur",
      "state": "Maharashtra"
    },
    {
      "name": "Nagpur Division",
      "state": "Maharashtra"
    },
    {
      "name": "Nagrakata",
      "state": "West Bengal"
    },
    {
      "name": "Nagram",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nagrota",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Nagwa",
      "state": "Gujarat"
    },
    {
      "name": "Nahan",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Naharlagun",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Nahorkatiya",
      "state": "Assam"
    },
    {
      "name": "Naigarhi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Naihati",
      "state": "West Bengal"
    },
    {
      "name": "Naini Tal",
      "state": "Uttarakhand"
    },
    {
      "name": "Nainpur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Nainwa",
      "state": "Rajasthan"
    },
    {
      "name": "Najafgarh",
      "state": "Delhi"
    },
    {
      "name": "Najibabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nakodar",
      "state": "Punjab"
    },
    {
      "name": "Naksalbari",
      "state": "West Bengal"
    },
    {
      "name": "Nakur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nala Sopara",
      "state": "Maharashtra"
    },
    {
      "name": "Nalagarh",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Nalanda",
      "state": "Bihar"
    },
    {
      "name": "Nalbari",
      "state": "Assam"
    },
    {
      "name": "Naldhara",
      "state": "Gujarat"
    },
    {
      "name": "Naldurg",
      "state": "Maharashtra"
    },
    {
      "name": "Nalegaon",
      "state": "Maharashtra"
    },
    {
      "name": "Nalgonda",
      "state": "Telangana"
    },
    {
      "name": "Nalhati",
      "state": "West Bengal"
    },
    {
      "name": "Naliya",
      "state": "Gujarat"
    },
    {
      "name": "Nalpur",
      "state": "West Bengal"
    },
    {
      "name": "Namagiripettai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Namakkal",
      "state": "Tamil Nadu"
    },
    {
      "name": "Nambiyur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Nambutalai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Namchi",
      "state": "Sikkim"
    },
    {
      "name": "Namkhana community development block",
      "state": "West Bengal"
    },
    {
      "name": "Namli",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Nampur",
      "state": "Maharashtra"
    },
    {
      "name": "Namrup",
      "state": "Assam"
    },
    {
      "name": "Nanauta",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nandambakkam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Nanded",
      "state": "Maharashtra"
    },
    {
      "name": "Nandgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Nandgaon",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nandigama",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Nandigram",
      "state": "West Bengal"
    },
    {
      "name": "Nandikotkur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Nandnee",
      "state": "Maharashtra"
    },
    {
      "name": "Nandura",
      "state": "Maharashtra"
    },
    {
      "name": "Nandura Buzurg",
      "state": "Maharashtra"
    },
    {
      "name": "Nandurbar",
      "state": "Maharashtra"
    },
    {
      "name": "Nandyal",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Nangal",
      "state": "Punjab"
    },
    {
      "name": "Nangavalli",
      "state": "Tamil Nadu"
    },
    {
      "name": "Nangi",
      "state": "West Bengal"
    },
    {
      "name": "Nangilickondan",
      "state": "Tamil Nadu"
    },
    {
      "name": "Nangloi Jat",
      "state": "Delhi"
    },
    {
      "name": "Nanguneri",
      "state": "Tamil Nadu"
    },
    {
      "name": "Nanjangud",
      "state": "Karnataka"
    },
    {
      "name": "Nannilam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Nanpara",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Napasar",
      "state": "Rajasthan"
    },
    {
      "name": "Naraina",
      "state": "Rajasthan"
    },
    {
      "name": "Naraini",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Narasannapeta",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Narasapur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Narasaraopet",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Narasimharajapura",
      "state": "Karnataka"
    },
    {
      "name": "Narasingapuram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Narauli",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Naraura",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Naravarikuppam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Narayanavanam",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Narayangaon",
      "state": "Maharashtra"
    },
    {
      "name": "Narayangarh",
      "state": "Haryana"
    },
    {
      "name": "Narayangarh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Narayanpet",
      "state": "Telangana"
    },
    {
      "name": "Narayanpur",
      "state": "Chhattisgarh"
    },
    {
      "name": "Nardana",
      "state": "Maharashtra"
    },
    {
      "name": "Naregal",
      "state": "Karnataka"
    },
    {
      "name": "Narela",
      "state": "Delhi"
    },
    {
      "name": "Narendranagar",
      "state": "Uttarakhand"
    },
    {
      "name": "Nargol",
      "state": "Gujarat"
    },
    {
      "name": "Nargund",
      "state": "Karnataka"
    },
    {
      "name": "Narharpur",
      "state": "Chhattisgarh"
    },
    {
      "name": "Nariman Point",
      "state": "Maharashtra"
    },
    {
      "name": "Narkhed",
      "state": "Maharashtra"
    },
    {
      "name": "Narmada",
      "state": "Gujarat"
    },
    {
      "name": "Narnaul",
      "state": "Haryana"
    },
    {
      "name": "Narnaund",
      "state": "Haryana"
    },
    {
      "name": "Naroda",
      "state": "Gujarat"
    },
    {
      "name": "Narora",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Narsimhapur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Narsinghgarh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Narsingi",
      "state": "Telangana"
    },
    {
      "name": "Narsipatnam",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Narwana",
      "state": "Haryana"
    },
    {
      "name": "Narwar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Nashik",
      "state": "Maharashtra"
    },
    {
      "name": "Nashik Division",
      "state": "Maharashtra"
    },
    {
      "name": "Nasirabad",
      "state": "Rajasthan"
    },
    {
      "name": "Naspur",
      "state": "Telangana"
    },
    {
      "name": "Nasriganj",
      "state": "Bihar"
    },
    {
      "name": "Nasrullahganj",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Nathdwara",
      "state": "Rajasthan"
    },
    {
      "name": "Nattam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Nattarasankottai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Naugachhia",
      "state": "Bihar"
    },
    {
      "name": "Naugama",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Naurangpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nautanwa",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Navalgund",
      "state": "Karnataka"
    },
    {
      "name": "Navapur",
      "state": "Maharashtra"
    },
    {
      "name": "Navelim",
      "state": "Goa"
    },
    {
      "name": "Navi Mumbai",
      "state": "Maharashtra"
    },
    {
      "name": "Navsari",
      "state": "Gujarat"
    },
    {
      "name": "Nawa",
      "state": "Rajasthan"
    },
    {
      "name": "Nawabganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nawabganj (Barabanki)",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nawabganj (Bareilly)",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nawada",
      "state": "Bihar"
    },
    {
      "name": "Nawalgarh",
      "state": "Rajasthan"
    },
    {
      "name": "Nawanshahr",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Nawanshahr",
      "state": "Punjab"
    },
    {
      "name": "Naya Bazar",
      "state": "Sikkim"
    },
    {
      "name": "Nayagarh",
      "state": "Odisha"
    },
    {
      "name": "Nayagarh District",
      "state": "Odisha"
    },
    {
      "name": "Nayagram community development block",
      "state": "West Bengal"
    },
    {
      "name": "Nayudupet",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Nazira",
      "state": "Assam"
    },
    {
      "name": "Nedumangad",
      "state": "Kerala"
    },
    {
      "name": "Needamangalam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Neelankarai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Neem ka Thana",
      "state": "Rajasthan"
    },
    {
      "name": "Neemuch",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Negapatam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Nelamangala",
      "state": "Karnataka"
    },
    {
      "name": "Nellikkuppam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Nellore",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Nepanagar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Neral",
      "state": "Maharashtra"
    },
    {
      "name": "Nerur",
      "state": "Maharashtra"
    },
    {
      "name": "Neturhat",
      "state": "Jharkhand"
    },
    {
      "name": "Nevasa",
      "state": "Maharashtra"
    },
    {
      "name": "New Delhi",
      "state": "Delhi"
    },
    {
      "name": "Newara",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Neyyattinkara",
      "state": "Kerala"
    },
    {
      "name": "Nichlaul",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nicobar",
      "state": "Andaman and Nicobar Islands"
    },
    {
      "name": "Nidadavole",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Nighoj",
      "state": "Maharashtra"
    },
    {
      "name": "Nigoh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nihtaur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nikora",
      "state": "Gujarat"
    },
    {
      "name": "Nilakottai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Nilanga",
      "state": "Maharashtra"
    },
    {
      "name": "Nilgiri",
      "state": "Odisha"
    },
    {
      "name": "Nilgiris",
      "state": "Tamil Nadu"
    },
    {
      "name": "Nilokheri",
      "state": "Haryana"
    },
    {
      "name": "Nilēshwar",
      "state": "Kerala"
    },
    {
      "name": "Nimaj",
      "state": "Rajasthan"
    },
    {
      "name": "Nimaparha",
      "state": "Odisha"
    },
    {
      "name": "Nimbahera",
      "state": "Rajasthan"
    },
    {
      "name": "Nipani",
      "state": "Maharashtra"
    },
    {
      "name": "Niphad",
      "state": "Maharashtra"
    },
    {
      "name": "Nira Narsingpur",
      "state": "Maharashtra"
    },
    {
      "name": "Nirmal",
      "state": "Telangana"
    },
    {
      "name": "Nirmali",
      "state": "Bihar"
    },
    {
      "name": "Nirsa",
      "state": "Jharkhand"
    },
    {
      "name": "Niwai",
      "state": "Rajasthan"
    },
    {
      "name": "Niwari",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nizamabad",
      "state": "Telangana"
    },
    {
      "name": "Nizamabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nizar",
      "state": "Gujarat"
    },
    {
      "name": "Noamundi",
      "state": "Jharkhand"
    },
    {
      "name": "Nohar",
      "state": "Rajasthan"
    },
    {
      "name": "Noida",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nokha",
      "state": "Rajasthan"
    },
    {
      "name": "Nongpoh",
      "state": "Meghalaya"
    },
    {
      "name": "Nongstoin",
      "state": "Meghalaya"
    },
    {
      "name": "Noria",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "North 24 Parganas district",
      "state": "West Bengal"
    },
    {
      "name": "North Delhi",
      "state": "Delhi"
    },
    {
      "name": "North District",
      "state": "Sikkim"
    },
    {
      "name": "North East Delhi",
      "state": "Delhi"
    },
    {
      "name": "North Garo Hills",
      "state": "Meghalaya"
    },
    {
      "name": "North Goa",
      "state": "Goa"
    },
    {
      "name": "North Guwahati",
      "state": "Assam"
    },
    {
      "name": "North Lakhimpur",
      "state": "Assam"
    },
    {
      "name": "North Tripura",
      "state": "Tripura"
    },
    {
      "name": "North Vanlaiphai",
      "state": "Mizoram"
    },
    {
      "name": "North West Delhi",
      "state": "Delhi"
    },
    {
      "name": "Nowrangapur",
      "state": "Odisha"
    },
    {
      "name": "Nuapada",
      "state": "Odisha"
    },
    {
      "name": "Nuh",
      "state": "Haryana"
    },
    {
      "name": "Numaligarh",
      "state": "Assam"
    },
    {
      "name": "Nurmahal",
      "state": "Punjab"
    },
    {
      "name": "Nurpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Nurpur Kalan",
      "state": "Punjab"
    },
    {
      "name": "Nuzvid",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Nyamti",
      "state": "Karnataka"
    },
    {
      "name": "Obra",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Odadar",
      "state": "Gujarat"
    },
    {
      "name": "Odlabari",
      "state": "West Bengal"
    },
    {
      "name": "Odugattur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Okha",
      "state": "Gujarat"
    },
    {
      "name": "Olpad",
      "state": "Gujarat"
    },
    {
      "name": "Omalur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Ongole",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Ooty",
      "state": "Tamil Nadu"
    },
    {
      "name": "Orai",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Oran",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Orchha",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Osmanabad",
      "state": "Maharashtra"
    },
    {
      "name": "Ottappalam",
      "state": "Kerala"
    },
    {
      "name": "Ozar",
      "state": "Maharashtra"
    },
    {
      "name": "Pabal",
      "state": "Maharashtra"
    },
    {
      "name": "Pachmarhi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Pachora",
      "state": "Maharashtra"
    },
    {
      "name": "Pachperwa",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Padam",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Padampur",
      "state": "Odisha"
    },
    {
      "name": "Padampur",
      "state": "Rajasthan"
    },
    {
      "name": "Paddhari",
      "state": "Gujarat"
    },
    {
      "name": "Padmanabhapuram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Padra",
      "state": "Gujarat"
    },
    {
      "name": "Padrauna",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Pahasu",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Pahlgam",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Pahur, Maharashtra",
      "state": "Maharashtra"
    },
    {
      "name": "Paigaon",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Paikpara",
      "state": "West Bengal"
    },
    {
      "name": "Paithan",
      "state": "Maharashtra"
    },
    {
      "name": "Pakala",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Pakur",
      "state": "Jharkhand"
    },
    {
      "name": "Palackattumala",
      "state": "Kerala"
    },
    {
      "name": "Palakkad district",
      "state": "Kerala"
    },
    {
      "name": "Palakkodu",
      "state": "Tamil Nadu"
    },
    {
      "name": "Palakollu",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Palamedu",
      "state": "Tamil Nadu"
    },
    {
      "name": "Palampur",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Palamu",
      "state": "Jharkhand"
    },
    {
      "name": "Palani",
      "state": "Tamil Nadu"
    },
    {
      "name": "Palanpur",
      "state": "Gujarat"
    },
    {
      "name": "Palanswa",
      "state": "Gujarat"
    },
    {
      "name": "Palasa",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Palasbari",
      "state": "Assam"
    },
    {
      "name": "Palavakkam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Palera",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Palghar",
      "state": "Maharashtra"
    },
    {
      "name": "Palghat",
      "state": "Kerala"
    },
    {
      "name": "Pali",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Pali",
      "state": "Rajasthan"
    },
    {
      "name": "Pali",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Pali, Raigad",
      "state": "Maharashtra"
    },
    {
      "name": "Palia Kalan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Palitana",
      "state": "Gujarat"
    },
    {
      "name": "Paliyad",
      "state": "Gujarat"
    },
    {
      "name": "Paliyad (Bhavnagar)",
      "state": "Gujarat"
    },
    {
      "name": "Palkonda",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Palladam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Pallappatti",
      "state": "Tamil Nadu"
    },
    {
      "name": "Pallattur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Pallavaram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Palle",
      "state": "Goa"
    },
    {
      "name": "Pallevada",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Pallikondai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Pallipattu",
      "state": "Tamil Nadu"
    },
    {
      "name": "Pallippatti",
      "state": "Tamil Nadu"
    },
    {
      "name": "Palmaner",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Paloncha",
      "state": "Telangana"
    },
    {
      "name": "Palsana",
      "state": "Gujarat"
    },
    {
      "name": "Palso",
      "state": "Maharashtra"
    },
    {
      "name": "Palwal",
      "state": "Haryana"
    },
    {
      "name": "Palwancha",
      "state": "Telangana"
    },
    {
      "name": "Panagar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Panagarh",
      "state": "West Bengal"
    },
    {
      "name": "Panaji",
      "state": "Goa"
    },
    {
      "name": "Panamaram",
      "state": "Kerala"
    },
    {
      "name": "Panara",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Panch Mahals",
      "state": "Gujarat"
    },
    {
      "name": "Panchgani",
      "state": "Maharashtra"
    },
    {
      "name": "Panchkula",
      "state": "Haryana"
    },
    {
      "name": "Panchla",
      "state": "West Bengal"
    },
    {
      "name": "Panchmahal district",
      "state": "Gujarat"
    },
    {
      "name": "Panchmura",
      "state": "West Bengal"
    },
    {
      "name": "Pandaria",
      "state": "Chhattisgarh"
    },
    {
      "name": "Pandatarai",
      "state": "Chhattisgarh"
    },
    {
      "name": "Pandhana",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Pandharpur",
      "state": "Maharashtra"
    },
    {
      "name": "Pandhurli",
      "state": "Maharashtra"
    },
    {
      "name": "Pandhurna",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Pandoh",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Pandua",
      "state": "West Bengal"
    },
    {
      "name": "Pangala",
      "state": "Karnataka"
    },
    {
      "name": "Panhala",
      "state": "Maharashtra"
    },
    {
      "name": "Panihati",
      "state": "West Bengal"
    },
    {
      "name": "Panipat",
      "state": "Haryana"
    },
    {
      "name": "Panna",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Panruti",
      "state": "Tamil Nadu"
    },
    {
      "name": "Pansemal",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Panskura",
      "state": "West Bengal"
    },
    {
      "name": "Panvel",
      "state": "Maharashtra"
    },
    {
      "name": "Paonta Sahib",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Papanasam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Papireddippatti",
      "state": "Tamil Nadu"
    },
    {
      "name": "Papparappatti",
      "state": "Tamil Nadu"
    },
    {
      "name": "Pappinisshēri",
      "state": "Kerala"
    },
    {
      "name": "Paradip Garh",
      "state": "Odisha"
    },
    {
      "name": "Paramagudi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Paras Rampur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Parasia",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Paravur Tekkumbhagam",
      "state": "Kerala"
    },
    {
      "name": "Parbatipur",
      "state": "West Bengal"
    },
    {
      "name": "Parbhani",
      "state": "Maharashtra"
    },
    {
      "name": "Pardi",
      "state": "Gujarat"
    },
    {
      "name": "Parel",
      "state": "Maharashtra"
    },
    {
      "name": "Parichha",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Parichhatgarh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Pariyapuram",
      "state": "Kerala"
    },
    {
      "name": "Parlakimidi",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Parli Vaijnath",
      "state": "Maharashtra"
    },
    {
      "name": "Parner",
      "state": "Maharashtra"
    },
    {
      "name": "Parnera",
      "state": "Gujarat"
    },
    {
      "name": "Parol",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Parola",
      "state": "Maharashtra"
    },
    {
      "name": "Parseoni",
      "state": "Maharashtra"
    },
    {
      "name": "Parshadepur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Partapur",
      "state": "Rajasthan"
    },
    {
      "name": "Partur",
      "state": "Maharashtra"
    },
    {
      "name": "Parvatipuram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Parvatsar",
      "state": "Rajasthan"
    },
    {
      "name": "Parwanoo",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Pasan",
      "state": "Chhattisgarh"
    },
    {
      "name": "Paschim Medinipur district",
      "state": "West Bengal"
    },
    {
      "name": "Pashchim Champaran",
      "state": "Bihar"
    },
    {
      "name": "Pashchim Singhbhum",
      "state": "Jharkhand"
    },
    {
      "name": "Pasighat",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Patamundai",
      "state": "Odisha"
    },
    {
      "name": "Patan",
      "state": "Chhattisgarh"
    },
    {
      "name": "Patan",
      "state": "Gujarat"
    },
    {
      "name": "Patan",
      "state": "Maharashtra"
    },
    {
      "name": "Patan",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Patancheru",
      "state": "Telangana"
    },
    {
      "name": "Pataudi",
      "state": "Haryana"
    },
    {
      "name": "Pathakpura",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Pathalgaon",
      "state": "Chhattisgarh"
    },
    {
      "name": "Pathanamthitta",
      "state": "Kerala"
    },
    {
      "name": "Pathankot",
      "state": "Punjab"
    },
    {
      "name": "Pathardi",
      "state": "Maharashtra"
    },
    {
      "name": "Pathardih",
      "state": "Jharkhand"
    },
    {
      "name": "Patharia",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Pathri",
      "state": "Maharashtra"
    },
    {
      "name": "Patiala",
      "state": "Punjab"
    },
    {
      "name": "Patiali",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Patiram",
      "state": "West Bengal"
    },
    {
      "name": "Patna",
      "state": "Bihar"
    },
    {
      "name": "Patnagarh",
      "state": "Odisha"
    },
    {
      "name": "Patrasaer",
      "state": "West Bengal"
    },
    {
      "name": "Pattan",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Pattanamtitta",
      "state": "Kerala"
    },
    {
      "name": "Patti",
      "state": "Punjab"
    },
    {
      "name": "Patti",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Pattukkottai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Patuli",
      "state": "West Bengal"
    },
    {
      "name": "Patur",
      "state": "Maharashtra"
    },
    {
      "name": "Paturda",
      "state": "Maharashtra"
    },
    {
      "name": "Paud",
      "state": "Maharashtra"
    },
    {
      "name": "Pauni",
      "state": "Maharashtra"
    },
    {
      "name": "Pauri",
      "state": "Uttarakhand"
    },
    {
      "name": "Pavi Jetpur",
      "state": "Gujarat"
    },
    {
      "name": "Pavugada",
      "state": "Karnataka"
    },
    {
      "name": "Pavuluru",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Pawai",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Pawayan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Pawni",
      "state": "Maharashtra"
    },
    {
      "name": "Payagpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Payyannur",
      "state": "Kerala"
    },
    {
      "name": "Pedana",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Peddapalli",
      "state": "Telangana"
    },
    {
      "name": "Peddapuram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Pedgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Pehowa",
      "state": "Haryana"
    },
    {
      "name": "Peint",
      "state": "Maharashtra"
    },
    {
      "name": "Pen",
      "state": "Maharashtra"
    },
    {
      "name": "Pendra",
      "state": "Chhattisgarh"
    },
    {
      "name": "Pennadam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Pennagaram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Pennathur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Penugonda",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Penukonda",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Peraiyur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Perambalur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Peranamallur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Peranampattu",
      "state": "Tamil Nadu"
    },
    {
      "name": "Peravurani",
      "state": "Tamil Nadu"
    },
    {
      "name": "Peren",
      "state": "Nagaland"
    },
    {
      "name": "Periyakulam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Periyanayakkanpalaiyam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Periyanegamam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Periyapatti",
      "state": "Tamil Nadu"
    },
    {
      "name": "Periyapattinam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Pernem",
      "state": "Goa"
    },
    {
      "name": "Perumbavoor",
      "state": "Kerala"
    },
    {
      "name": "Perumpavur",
      "state": "Kerala"
    },
    {
      "name": "Perundurai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Perungudi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Perur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Perya",
      "state": "Kerala"
    },
    {
      "name": "Petlad",
      "state": "Gujarat"
    },
    {
      "name": "Petlawad",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Phagwara",
      "state": "Punjab"
    },
    {
      "name": "Phalauda",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Phalodi",
      "state": "Rajasthan"
    },
    {
      "name": "Phaltan",
      "state": "Maharashtra"
    },
    {
      "name": "Phaphamau",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Phaphund",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Phariha",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Phek",
      "state": "Manipur"
    },
    {
      "name": "Phek",
      "state": "Nagaland"
    },
    {
      "name": "Pheona",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Phillaur",
      "state": "Punjab"
    },
    {
      "name": "Phirangipuram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Phulambri",
      "state": "Maharashtra"
    },
    {
      "name": "Phulbani",
      "state": "Odisha"
    },
    {
      "name": "Phulera",
      "state": "Rajasthan"
    },
    {
      "name": "Phulpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Pichhaura",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Pihani",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Pilani",
      "state": "Rajasthan"
    },
    {
      "name": "Pilibangan",
      "state": "Rajasthan"
    },
    {
      "name": "Pilibhit",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Piliv",
      "state": "Maharashtra"
    },
    {
      "name": "Pilkhua",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Pilkhuwa",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Pimpalgaon Baswant",
      "state": "Maharashtra"
    },
    {
      "name": "Pimpalgaon Raja",
      "state": "Maharashtra"
    },
    {
      "name": "Pimpri",
      "state": "Maharashtra"
    },
    {
      "name": "Pimpri-Chinchwad",
      "state": "Maharashtra"
    },
    {
      "name": "Pinahat",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Pindwara",
      "state": "Rajasthan"
    },
    {
      "name": "Pinjaur",
      "state": "Haryana"
    },
    {
      "name": "Pipalkoti",
      "state": "Uttarakhand"
    },
    {
      "name": "Pipar",
      "state": "Rajasthan"
    },
    {
      "name": "Pipavav",
      "state": "Gujarat"
    },
    {
      "name": "Pipili",
      "state": "Odisha"
    },
    {
      "name": "Piplod",
      "state": "Gujarat"
    },
    {
      "name": "Piploda",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Pippara",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Pipraich",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Pipri",
      "state": "Maharashtra"
    },
    {
      "name": "Pipri",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Piravam",
      "state": "Kerala"
    },
    {
      "name": "Pirawa",
      "state": "Rajasthan"
    },
    {
      "name": "Piriyapatna",
      "state": "Karnataka"
    },
    {
      "name": "Piro",
      "state": "Bihar"
    },
    {
      "name": "Pitampura",
      "state": "Delhi"
    },
    {
      "name": "Pithampur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Pithapuram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Pithora",
      "state": "Chhattisgarh"
    },
    {
      "name": "Pithoragarh",
      "state": "Uttarakhand"
    },
    {
      "name": "Pokaran",
      "state": "Rajasthan"
    },
    {
      "name": "Polasara",
      "state": "Odisha"
    },
    {
      "name": "Polavaram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Pollachi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Polur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Ponda",
      "state": "Goa"
    },
    {
      "name": "Ponmana",
      "state": "Kerala"
    },
    {
      "name": "Ponnamaravati",
      "state": "Tamil Nadu"
    },
    {
      "name": "Ponnampet",
      "state": "Karnataka"
    },
    {
      "name": "Ponnani",
      "state": "Kerala"
    },
    {
      "name": "Ponneri",
      "state": "Tamil Nadu"
    },
    {
      "name": "Ponnur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Ponnuru",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Poonamalle",
      "state": "Tamil Nadu"
    },
    {
      "name": "Porbandar",
      "state": "Gujarat"
    },
    {
      "name": "Porsa",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Port Blair",
      "state": "Andaman and Nicobar Islands"
    },
    {
      "name": "Porur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Powai",
      "state": "Maharashtra"
    },
    {
      "name": "Prabhadevi",
      "state": "Maharashtra"
    },
    {
      "name": "Prabhas Patan",
      "state": "Gujarat"
    },
    {
      "name": "Prakasam",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Prakasha",
      "state": "Maharashtra"
    },
    {
      "name": "Prantij",
      "state": "Gujarat"
    },
    {
      "name": "Pratapgarh",
      "state": "Rajasthan"
    },
    {
      "name": "Pratapgarh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Prayagraj (Allahabad)",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Proddatur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Puducherry",
      "state": "Puducherry"
    },
    {
      "name": "Pudukkottai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Puduppatti",
      "state": "Tamil Nadu"
    },
    {
      "name": "Pudur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Puduvayal",
      "state": "Tamil Nadu"
    },
    {
      "name": "Pujali",
      "state": "West Bengal"
    },
    {
      "name": "Pukhrayan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Pulgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Pulivendla",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Puliyangudi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Puliyur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Pullambadi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Pulwama",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Punahana",
      "state": "Haryana"
    },
    {
      "name": "Punalur",
      "state": "Kerala"
    },
    {
      "name": "Punasa",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Punch",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Puncha community development block",
      "state": "West Bengal"
    },
    {
      "name": "Pundri",
      "state": "Haryana"
    },
    {
      "name": "Pune",
      "state": "Maharashtra"
    },
    {
      "name": "Pune Division",
      "state": "Maharashtra"
    },
    {
      "name": "Punganuru",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Punjai Puliyampatti",
      "state": "Tamil Nadu"
    },
    {
      "name": "Puntamba",
      "state": "Maharashtra"
    },
    {
      "name": "Pupri",
      "state": "Bihar"
    },
    {
      "name": "Pural",
      "state": "Maharashtra"
    },
    {
      "name": "Puranpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Purba Champaran",
      "state": "Bihar"
    },
    {
      "name": "Purba Medinipur district",
      "state": "West Bengal"
    },
    {
      "name": "Purba Singhbhum",
      "state": "Jharkhand"
    },
    {
      "name": "Puri",
      "state": "Odisha"
    },
    {
      "name": "Purmafi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Purna",
      "state": "Maharashtra"
    },
    {
      "name": "Purnia",
      "state": "Bihar"
    },
    {
      "name": "Purulia",
      "state": "West Bengal"
    },
    {
      "name": "Purushottampur",
      "state": "Odisha"
    },
    {
      "name": "Purwa",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Pusad",
      "state": "Maharashtra"
    },
    {
      "name": "Pushkar",
      "state": "Rajasthan"
    },
    {
      "name": "Puttaparthi",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Puttur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Puttur",
      "state": "Karnataka"
    },
    {
      "name": "Qadian",
      "state": "Punjab"
    },
    {
      "name": "Qadirganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Qazigund",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Quepem",
      "state": "Goa"
    },
    {
      "name": "Queula",
      "state": "Goa"
    },
    {
      "name": "Quthbullapur",
      "state": "Telangana"
    },
    {
      "name": "Rabkavi",
      "state": "Karnataka"
    },
    {
      "name": "Rabupura",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Radaur",
      "state": "Haryana"
    },
    {
      "name": "Radha Kund",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Radhakund",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Radhanagari",
      "state": "Maharashtra"
    },
    {
      "name": "Radhanpur",
      "state": "Gujarat"
    },
    {
      "name": "Raebareli",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Rafiganj",
      "state": "Bihar"
    },
    {
      "name": "Raghogarh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Raghudebbati",
      "state": "West Bengal"
    },
    {
      "name": "Raghunathpur",
      "state": "Bihar"
    },
    {
      "name": "Raghunathpur",
      "state": "West Bengal"
    },
    {
      "name": "Raha",
      "state": "Assam"
    },
    {
      "name": "Rahata",
      "state": "Maharashtra"
    },
    {
      "name": "Rahatgarh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Rahimatpur",
      "state": "Maharashtra"
    },
    {
      "name": "Rahon",
      "state": "Punjab"
    },
    {
      "name": "Rahuri",
      "state": "Maharashtra"
    },
    {
      "name": "Raia",
      "state": "Goa"
    },
    {
      "name": "Raichur",
      "state": "Karnataka"
    },
    {
      "name": "Raiganj",
      "state": "West Bengal"
    },
    {
      "name": "Raigarh",
      "state": "Chhattisgarh"
    },
    {
      "name": "Raigarh",
      "state": "Maharashtra"
    },
    {
      "name": "Raikot",
      "state": "Punjab"
    },
    {
      "name": "Raipur",
      "state": "Chhattisgarh"
    },
    {
      "name": "Raipur",
      "state": "Rajasthan"
    },
    {
      "name": "Raipur",
      "state": "Uttarakhand"
    },
    {
      "name": "Raireshwar",
      "state": "Maharashtra"
    },
    {
      "name": "Raisen",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Raisinghnagar",
      "state": "Rajasthan"
    },
    {
      "name": "Raiwala Bara",
      "state": "Uttarakhand"
    },
    {
      "name": "Raj Nandgaon",
      "state": "Chhattisgarh"
    },
    {
      "name": "Raj-Nandgaon",
      "state": "Chhattisgarh"
    },
    {
      "name": "Rajahmundry",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Rajakhera",
      "state": "Rajasthan"
    },
    {
      "name": "Rajaldesar",
      "state": "Rajasthan"
    },
    {
      "name": "Rajanna Sircilla",
      "state": "Telangana"
    },
    {
      "name": "Rajaori",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Rajapalaiyam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Rajapur",
      "state": "Maharashtra"
    },
    {
      "name": "Rajapur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Rajasansi",
      "state": "Punjab"
    },
    {
      "name": "Rajauri",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Rajgarh",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Rajgarh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Rajgarh",
      "state": "Rajasthan"
    },
    {
      "name": "Rajgir",
      "state": "Bihar"
    },
    {
      "name": "Rajgurunagar",
      "state": "Maharashtra"
    },
    {
      "name": "Rajkot",
      "state": "Gujarat"
    },
    {
      "name": "Rajmahal",
      "state": "West Bengal"
    },
    {
      "name": "Rajnagar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Rajnagar community development block",
      "state": "West Bengal"
    },
    {
      "name": "Rajpipla",
      "state": "Gujarat"
    },
    {
      "name": "Rajpur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Rajpura",
      "state": "Punjab"
    },
    {
      "name": "Rajsamand",
      "state": "Rajasthan"
    },
    {
      "name": "Rajula",
      "state": "Gujarat"
    },
    {
      "name": "Rajur",
      "state": "Maharashtra"
    },
    {
      "name": "Rajura",
      "state": "Maharashtra"
    },
    {
      "name": "Ralegaon",
      "state": "Maharashtra"
    },
    {
      "name": "Ram Das",
      "state": "Punjab"
    },
    {
      "name": "Ramachandrapuram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Ramagundam",
      "state": "Telangana"
    },
    {
      "name": "Ramamangalam",
      "state": "Kerala"
    },
    {
      "name": "Ramanagara",
      "state": "Karnataka"
    },
    {
      "name": "Ramanathapuram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Ramanayyapeta",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Ramanuj Ganj",
      "state": "Chhattisgarh"
    },
    {
      "name": "Ramapuram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Ramban",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Rambha",
      "state": "Odisha"
    },
    {
      "name": "Ramchandrapur",
      "state": "West Bengal"
    },
    {
      "name": "Rameswaram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Ramewadi",
      "state": "Maharashtra"
    },
    {
      "name": "Ramganj Mandi",
      "state": "Rajasthan"
    },
    {
      "name": "Ramgarh",
      "state": "Jharkhand"
    },
    {
      "name": "Ramgarh",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Ramgarh",
      "state": "Rajasthan"
    },
    {
      "name": "Ramgundam",
      "state": "Telangana"
    },
    {
      "name": "Ramjibanpur",
      "state": "West Bengal"
    },
    {
      "name": "Ramkola",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ramnagar",
      "state": "Bihar"
    },
    {
      "name": "Ramnagar",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Ramnagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ramnagar",
      "state": "Uttarakhand"
    },
    {
      "name": "Ramnagar",
      "state": "West Bengal"
    },
    {
      "name": "Rampachodavaram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Rampur",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Rampur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Rampur Hat",
      "state": "West Bengal"
    },
    {
      "name": "Rampura",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Rampura",
      "state": "Punjab"
    },
    {
      "name": "Rampura",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ramtek",
      "state": "Maharashtra"
    },
    {
      "name": "Ranaghat",
      "state": "West Bengal"
    },
    {
      "name": "Ranapur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Ranavav",
      "state": "Gujarat"
    },
    {
      "name": "Ranchi",
      "state": "Jharkhand"
    },
    {
      "name": "Rangapara",
      "state": "Assam"
    },
    {
      "name": "Rangareddi",
      "state": "Telangana"
    },
    {
      "name": "Rangia",
      "state": "Assam"
    },
    {
      "name": "Rangpo",
      "state": "Sikkim"
    },
    {
      "name": "Rani",
      "state": "Rajasthan"
    },
    {
      "name": "Rania",
      "state": "Haryana"
    },
    {
      "name": "Ranibennur",
      "state": "Karnataka"
    },
    {
      "name": "Raniganj",
      "state": "West Bengal"
    },
    {
      "name": "Ranikhet",
      "state": "Uttarakhand"
    },
    {
      "name": "Ranipet",
      "state": "Tamil Nadu"
    },
    {
      "name": "Ranipur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ranipur Barsi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ranir Bazar",
      "state": "Tripura"
    },
    {
      "name": "Ranpur",
      "state": "Gujarat"
    },
    {
      "name": "Rapar",
      "state": "Gujarat"
    },
    {
      "name": "Rasipuram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Rasra",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Rasulabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ratangarh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Ratangarh",
      "state": "Rajasthan"
    },
    {
      "name": "Ratanpur",
      "state": "Chhattisgarh"
    },
    {
      "name": "Rath",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ratia",
      "state": "Haryana"
    },
    {
      "name": "Ratlam",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Ratnagiri",
      "state": "Maharashtra"
    },
    {
      "name": "Raurkela",
      "state": "Odisha"
    },
    {
      "name": "Raver",
      "state": "Maharashtra"
    },
    {
      "name": "Rawatbhata",
      "state": "Rajasthan"
    },
    {
      "name": "Rawatsar",
      "state": "Rajasthan"
    },
    {
      "name": "Raxaul",
      "state": "Bihar"
    },
    {
      "name": "Ray",
      "state": "Jharkhand"
    },
    {
      "name": "Raya",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Rayachoti",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Rayadrug",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Rayagada",
      "state": "Odisha"
    },
    {
      "name": "Raybag",
      "state": "Karnataka"
    },
    {
      "name": "Raypur",
      "state": "West Bengal"
    },
    {
      "name": "Razam",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Razampeta",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Razole",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Reha",
      "state": "Gujarat"
    },
    {
      "name": "Rehar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Rehli",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Rehti",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Remuna",
      "state": "Odisha"
    },
    {
      "name": "Renapur",
      "state": "Maharashtra"
    },
    {
      "name": "Renavi",
      "state": "Maharashtra"
    },
    {
      "name": "Rengali",
      "state": "Odisha"
    },
    {
      "name": "Renigunta",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Renukoot",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Renukut",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Reoti",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Reotipur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Repalle",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Revadanda",
      "state": "Maharashtra"
    },
    {
      "name": "Revdanda",
      "state": "Maharashtra"
    },
    {
      "name": "Revelganj",
      "state": "Bihar"
    },
    {
      "name": "Rewa",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Rewari",
      "state": "Haryana"
    },
    {
      "name": "Rewari District",
      "state": "Haryana"
    },
    {
      "name": "Ri-Bhoi",
      "state": "Meghalaya"
    },
    {
      "name": "Riasi",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Richha",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ringas",
      "state": "Rajasthan"
    },
    {
      "name": "Rishikesh",
      "state": "Uttarakhand"
    },
    {
      "name": "Rishra",
      "state": "West Bengal"
    },
    {
      "name": "Risod",
      "state": "Maharashtra"
    },
    {
      "name": "Robertsganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Robertsonpet",
      "state": "Karnataka"
    },
    {
      "name": "Roha",
      "state": "Gujarat"
    },
    {
      "name": "Roha",
      "state": "Maharashtra"
    },
    {
      "name": "Rohini",
      "state": "Delhi"
    },
    {
      "name": "Rohru",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Rohtak",
      "state": "Haryana"
    },
    {
      "name": "Rohtas",
      "state": "Bihar"
    },
    {
      "name": "Ron",
      "state": "Karnataka"
    },
    {
      "name": "Roorkee",
      "state": "Uttarakhand"
    },
    {
      "name": "Rudarpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Rudauli",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Rudraprayag",
      "state": "Uttarakhand"
    },
    {
      "name": "Rupnagar",
      "state": "Punjab"
    },
    {
      "name": "Rura",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Rusera",
      "state": "Bihar"
    },
    {
      "name": "Sabalgarh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Sabalpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sabar Kantha",
      "state": "Gujarat"
    },
    {
      "name": "Sabathu",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Sabrum",
      "state": "Tripura"
    },
    {
      "name": "Sachendi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sachin",
      "state": "Gujarat"
    },
    {
      "name": "Sadabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sadalgi",
      "state": "Karnataka"
    },
    {
      "name": "Sadaseopet",
      "state": "Telangana"
    },
    {
      "name": "Sadat",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sadri",
      "state": "Rajasthan"
    },
    {
      "name": "Safidon",
      "state": "Haryana"
    },
    {
      "name": "Safipur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sagar",
      "state": "Karnataka"
    },
    {
      "name": "Sagar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Sagauli",
      "state": "Bihar"
    },
    {
      "name": "Sahapur",
      "state": "West Bengal"
    },
    {
      "name": "Saharanpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Saharsa",
      "state": "Bihar"
    },
    {
      "name": "Sahaspur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sahaswan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sahawar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sahibabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sahibganj",
      "state": "Jharkhand"
    },
    {
      "name": "Sahpau",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Saidpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Saiha",
      "state": "Mizoram"
    },
    {
      "name": "Sailana",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Sailu",
      "state": "Maharashtra"
    },
    {
      "name": "Saint Thomas Mount",
      "state": "Tamil Nadu"
    },
    {
      "name": "Sainthia",
      "state": "West Bengal"
    },
    {
      "name": "Sairang",
      "state": "Mizoram"
    },
    {
      "name": "Saitlaw",
      "state": "Mizoram"
    },
    {
      "name": "Sakhanu",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sakit",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sakleshpur",
      "state": "Karnataka"
    },
    {
      "name": "Sakol",
      "state": "Maharashtra"
    },
    {
      "name": "Sakoli",
      "state": "Maharashtra"
    },
    {
      "name": "Sakri",
      "state": "Maharashtra"
    },
    {
      "name": "Sakti",
      "state": "Chhattisgarh"
    },
    {
      "name": "Salanpur community development block",
      "state": "West Bengal"
    },
    {
      "name": "Salaya",
      "state": "Gujarat"
    },
    {
      "name": "Salem",
      "state": "Tamil Nadu"
    },
    {
      "name": "Salempur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Saligao",
      "state": "Goa"
    },
    {
      "name": "Salon",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Salumbar",
      "state": "Rajasthan"
    },
    {
      "name": "Salur",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Samakhiali",
      "state": "Gujarat"
    },
    {
      "name": "Samalkha",
      "state": "Haryana"
    },
    {
      "name": "Samalkot",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Samastipur",
      "state": "Bihar"
    },
    {
      "name": "Samba",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Sambalpur",
      "state": "Odisha"
    },
    {
      "name": "Sambhal",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sambhar",
      "state": "Rajasthan"
    },
    {
      "name": "Samdari",
      "state": "Rajasthan"
    },
    {
      "name": "Samrala",
      "state": "Punjab"
    },
    {
      "name": "Samthar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Samudrapur",
      "state": "Maharashtra"
    },
    {
      "name": "Sanand",
      "state": "Gujarat"
    },
    {
      "name": "Sanaur",
      "state": "Punjab"
    },
    {
      "name": "Sanawad",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Sanchi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Sanchor",
      "state": "Rajasthan"
    },
    {
      "name": "Sancoale",
      "state": "Goa"
    },
    {
      "name": "Sandi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sandila",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sandur",
      "state": "Karnataka"
    },
    {
      "name": "Sangameshwar",
      "state": "Maharashtra"
    },
    {
      "name": "Sangamner",
      "state": "Maharashtra"
    },
    {
      "name": "Sangareddi",
      "state": "Telangana"
    },
    {
      "name": "Sangaria",
      "state": "Rajasthan"
    },
    {
      "name": "Sangli",
      "state": "Maharashtra"
    },
    {
      "name": "Sangod",
      "state": "Rajasthan"
    },
    {
      "name": "Sangola",
      "state": "Maharashtra"
    },
    {
      "name": "Sangole",
      "state": "Maharashtra"
    },
    {
      "name": "Sangrampur, Maharashtra",
      "state": "Maharashtra"
    },
    {
      "name": "Sangrur",
      "state": "Punjab"
    },
    {
      "name": "Sanguem",
      "state": "Goa"
    },
    {
      "name": "Sanivarsante",
      "state": "Karnataka"
    },
    {
      "name": "Sankarpur",
      "state": "West Bengal"
    },
    {
      "name": "Sankeshwar",
      "state": "Karnataka"
    },
    {
      "name": "Sankheda",
      "state": "Gujarat"
    },
    {
      "name": "Sankrail",
      "state": "West Bengal"
    },
    {
      "name": "Sanquelim",
      "state": "Goa"
    },
    {
      "name": "Sant Kabir Nagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sant Ravi Das Nagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Santipur",
      "state": "West Bengal"
    },
    {
      "name": "Santokhgarh",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Santoshpur",
      "state": "West Bengal"
    },
    {
      "name": "Santuri community development block",
      "state": "West Bengal"
    },
    {
      "name": "Sanvordem",
      "state": "Goa"
    },
    {
      "name": "Sanwer",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Saoli",
      "state": "Maharashtra"
    },
    {
      "name": "Saoner",
      "state": "Maharashtra"
    },
    {
      "name": "Sapatgram",
      "state": "Assam"
    },
    {
      "name": "Sarahan",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Sarai Akil",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sarai Ekdil",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sarai Mir",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Saraikela",
      "state": "Jharkhand"
    },
    {
      "name": "Saraipali",
      "state": "Chhattisgarh"
    },
    {
      "name": "Saran",
      "state": "Bihar"
    },
    {
      "name": "Sarangarh",
      "state": "Chhattisgarh"
    },
    {
      "name": "Sarangkheda",
      "state": "Maharashtra"
    },
    {
      "name": "Sarangpur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Sarauli",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sarbhon",
      "state": "Gujarat"
    },
    {
      "name": "Sardarshahr",
      "state": "Rajasthan"
    },
    {
      "name": "Sardhana",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sardoi",
      "state": "Gujarat"
    },
    {
      "name": "Sardulgarh",
      "state": "Punjab"
    },
    {
      "name": "Sarenga",
      "state": "West Bengal"
    },
    {
      "name": "Sargur",
      "state": "Karnataka"
    },
    {
      "name": "Sarila",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sarka Ghat",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Sarkhej",
      "state": "Gujarat"
    },
    {
      "name": "Sarubera",
      "state": "Jharkhand"
    },
    {
      "name": "Sarupathar",
      "state": "Assam"
    },
    {
      "name": "Sarurpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sarwar",
      "state": "Rajasthan"
    },
    {
      "name": "Sasni",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sasvad",
      "state": "Maharashtra"
    },
    {
      "name": "Saswad",
      "state": "Maharashtra"
    },
    {
      "name": "Satana",
      "state": "Maharashtra"
    },
    {
      "name": "Satara",
      "state": "Maharashtra"
    },
    {
      "name": "Satara Division",
      "state": "Maharashtra"
    },
    {
      "name": "Sathamba",
      "state": "Gujarat"
    },
    {
      "name": "Sathankulam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Sathupalli",
      "state": "Telangana"
    },
    {
      "name": "Sathyamangalam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Satna",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Satpati",
      "state": "Maharashtra"
    },
    {
      "name": "Satrikh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sattenapalle",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Sattur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Satwas",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Saugor",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Saundatti",
      "state": "Karnataka"
    },
    {
      "name": "Saurikh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sausar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Savantvadi",
      "state": "Maharashtra"
    },
    {
      "name": "Savanur",
      "state": "Karnataka"
    },
    {
      "name": "Savarkundla",
      "state": "Gujarat"
    },
    {
      "name": "Savda",
      "state": "Maharashtra"
    },
    {
      "name": "Savlaj",
      "state": "Maharashtra"
    },
    {
      "name": "Savli",
      "state": "Gujarat"
    },
    {
      "name": "Sawai Madhopur",
      "state": "Rajasthan"
    },
    {
      "name": "Sawantvadi",
      "state": "Maharashtra"
    },
    {
      "name": "Sayalkudi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Sayla",
      "state": "Gujarat"
    },
    {
      "name": "Sector",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Secunderabad",
      "state": "Telangana"
    },
    {
      "name": "Sehore",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Selu",
      "state": "Maharashtra"
    },
    {
      "name": "Senapati",
      "state": "Manipur"
    },
    {
      "name": "Sendhwa",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Seohara",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Seondha",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Seoni",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Seoni",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Seoni Malwa",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Seorinarayan",
      "state": "Chhattisgarh"
    },
    {
      "name": "Seram",
      "state": "Karnataka"
    },
    {
      "name": "Serampore",
      "state": "West Bengal"
    },
    {
      "name": "Serchhip",
      "state": "Mizoram"
    },
    {
      "name": "Serilingampalle",
      "state": "Telangana"
    },
    {
      "name": "Serpur",
      "state": "West Bengal"
    },
    {
      "name": "Serula",
      "state": "Goa"
    },
    {
      "name": "Sevagram",
      "state": "Maharashtra"
    },
    {
      "name": "Seven Pagodas",
      "state": "Tamil Nadu"
    },
    {
      "name": "Sewri",
      "state": "Maharashtra"
    },
    {
      "name": "Shadipur Julana",
      "state": "Haryana"
    },
    {
      "name": "Shahabad",
      "state": "Haryana"
    },
    {
      "name": "Shahabad",
      "state": "Karnataka"
    },
    {
      "name": "Shahabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Shahada",
      "state": "Maharashtra"
    },
    {
      "name": "Shahapur",
      "state": "Maharashtra"
    },
    {
      "name": "Shahbazpur",
      "state": "Bihar"
    },
    {
      "name": "Shahdol",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Shahganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Shahgarh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Shahi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Shahid Bhagat Singh Nagar",
      "state": "Punjab"
    },
    {
      "name": "Shahjahanpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Shahkot",
      "state": "Punjab"
    },
    {
      "name": "Shahpur",
      "state": "Bihar"
    },
    {
      "name": "Shahpur",
      "state": "Gujarat"
    },
    {
      "name": "Shahpur",
      "state": "Karnataka"
    },
    {
      "name": "Shahpur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Shahpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Shahpura",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Shahpura",
      "state": "Rajasthan"
    },
    {
      "name": "Shajapur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Sham Churasi",
      "state": "Punjab"
    },
    {
      "name": "Shamgarh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Shamli",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Shamsabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Shankargarh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Shedbal",
      "state": "Maharashtra"
    },
    {
      "name": "Shegaon",
      "state": "Maharashtra"
    },
    {
      "name": "Sheikhpura",
      "state": "Bihar"
    },
    {
      "name": "Sheoganj",
      "state": "Rajasthan"
    },
    {
      "name": "Sheohar",
      "state": "Bihar"
    },
    {
      "name": "Sheopur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Shergarh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sherghati",
      "state": "Bihar"
    },
    {
      "name": "Sherkot",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Shertallai",
      "state": "Kerala"
    },
    {
      "name": "Shevgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Shibnagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Shiggaon",
      "state": "Karnataka"
    },
    {
      "name": "Shikarpur",
      "state": "Karnataka"
    },
    {
      "name": "Shikarpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Shikarpur (Bulandshahr)",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Shikohabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Shikrapur",
      "state": "Maharashtra"
    },
    {
      "name": "Shillong",
      "state": "Meghalaya"
    },
    {
      "name": "Shimla",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Shimoga",
      "state": "Karnataka"
    },
    {
      "name": "Shiraguppi",
      "state": "Maharashtra"
    },
    {
      "name": "Shirala",
      "state": "Maharashtra"
    },
    {
      "name": "Shirdi",
      "state": "Maharashtra"
    },
    {
      "name": "Shirgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Shirhatti",
      "state": "Karnataka"
    },
    {
      "name": "Shirol",
      "state": "Maharashtra"
    },
    {
      "name": "Shirpur",
      "state": "Maharashtra"
    },
    {
      "name": "Shirud",
      "state": "Maharashtra"
    },
    {
      "name": "Shirwal",
      "state": "Maharashtra"
    },
    {
      "name": "Shishgarh",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Shivaji Nagar",
      "state": "Maharashtra"
    },
    {
      "name": "Shivpuri",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Shivrajpur",
      "state": "Gujarat"
    },
    {
      "name": "Shivrajpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sholinghur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Shorapur",
      "state": "Karnataka"
    },
    {
      "name": "Shrawasti",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Shrigonda",
      "state": "Maharashtra"
    },
    {
      "name": "Shrirangapattana",
      "state": "Karnataka"
    },
    {
      "name": "Shujalpur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Shupiyan",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Shyamnagar, West Bengal",
      "state": "West Bengal"
    },
    {
      "name": "Shōranur",
      "state": "Kerala"
    },
    {
      "name": "Sibsagar",
      "state": "Assam"
    },
    {
      "name": "Siddapur",
      "state": "Karnataka"
    },
    {
      "name": "Siddharthnagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Siddhaur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Siddhpur",
      "state": "Gujarat"
    },
    {
      "name": "Siddipet",
      "state": "Telangana"
    },
    {
      "name": "Sidhauli",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sidhi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Sidhpura",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sidlaghatta",
      "state": "Karnataka"
    },
    {
      "name": "Sihor",
      "state": "Gujarat"
    },
    {
      "name": "Sihora",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Sijua",
      "state": "Jharkhand"
    },
    {
      "name": "Sikandarabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sikandarpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sikandra",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sikandra Rao",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sikandrabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sikar",
      "state": "Rajasthan"
    },
    {
      "name": "Sikka",
      "state": "Gujarat"
    },
    {
      "name": "Silao",
      "state": "Bihar"
    },
    {
      "name": "Silapathar",
      "state": "Assam"
    },
    {
      "name": "Silchar",
      "state": "Assam"
    },
    {
      "name": "Siliguri",
      "state": "West Bengal"
    },
    {
      "name": "Sillod",
      "state": "Maharashtra"
    },
    {
      "name": "Silvassa",
      "state": "Dadra and Nagar Haveli and Daman and Diu"
    },
    {
      "name": "Simaria",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Simdega",
      "state": "Jharkhand"
    },
    {
      "name": "Simga",
      "state": "Chhattisgarh"
    },
    {
      "name": "Sindewahi",
      "state": "Maharashtra"
    },
    {
      "name": "Sindgi",
      "state": "Karnataka"
    },
    {
      "name": "Sindhnur",
      "state": "Karnataka"
    },
    {
      "name": "Sindhudurg",
      "state": "Maharashtra"
    },
    {
      "name": "Sindi",
      "state": "Maharashtra"
    },
    {
      "name": "Sindkheda",
      "state": "Maharashtra"
    },
    {
      "name": "Singanallur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Singapperumalkovil",
      "state": "Tamil Nadu"
    },
    {
      "name": "Singapur",
      "state": "Telangana"
    },
    {
      "name": "Singarayakonda",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Singoli",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Singrauli",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Singtam",
      "state": "Sikkim"
    },
    {
      "name": "Singur",
      "state": "West Bengal"
    },
    {
      "name": "Sini",
      "state": "Jharkhand"
    },
    {
      "name": "Sinnar",
      "state": "Maharashtra"
    },
    {
      "name": "Sinor",
      "state": "Gujarat"
    },
    {
      "name": "Sion, Mumbai",
      "state": "Maharashtra"
    },
    {
      "name": "Sira",
      "state": "Karnataka"
    },
    {
      "name": "Sirathu",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sirhind-Fategarh",
      "state": "Punjab"
    },
    {
      "name": "Sirkazhi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Sirmaur",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Sirmaur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Sirohi",
      "state": "Rajasthan"
    },
    {
      "name": "Sironcha",
      "state": "Maharashtra"
    },
    {
      "name": "Sironj",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Sirpur",
      "state": "Telangana"
    },
    {
      "name": "Sirsa",
      "state": "Haryana"
    },
    {
      "name": "Sirsa",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sirsaganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sirsi",
      "state": "Karnataka"
    },
    {
      "name": "Sirsi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sirsilla",
      "state": "Telangana"
    },
    {
      "name": "Siruguppa",
      "state": "Karnataka"
    },
    {
      "name": "Sirumugai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Sirur",
      "state": "Maharashtra"
    },
    {
      "name": "Sisauli",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Siswa Bazar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sitamarhi",
      "state": "Bihar"
    },
    {
      "name": "Sitamau",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Sitapur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sitarganj",
      "state": "Uttarakhand"
    },
    {
      "name": "Sivaganga",
      "state": "Tamil Nadu"
    },
    {
      "name": "Sivagiri",
      "state": "Tamil Nadu"
    },
    {
      "name": "Sivakasi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Sivala, East Godavari district",
      "state": "Maharashtra"
    },
    {
      "name": "Siwan",
      "state": "Bihar"
    },
    {
      "name": "Siwana",
      "state": "Rajasthan"
    },
    {
      "name": "Soalkuchi",
      "state": "Assam"
    },
    {
      "name": "Sodpur",
      "state": "West Bengal"
    },
    {
      "name": "Sohagi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Sohagpur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Sohna",
      "state": "Haryana"
    },
    {
      "name": "Sojat",
      "state": "Rajasthan"
    },
    {
      "name": "Sojitra",
      "state": "Gujarat"
    },
    {
      "name": "Solan",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Solap",
      "state": "West Bengal"
    },
    {
      "name": "Solapur",
      "state": "Maharashtra"
    },
    {
      "name": "Solim",
      "state": "Goa"
    },
    {
      "name": "Someshwar",
      "state": "Karnataka"
    },
    {
      "name": "Sompeta",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Somvarpet",
      "state": "Karnataka"
    },
    {
      "name": "Sonada",
      "state": "West Bengal"
    },
    {
      "name": "Sonala",
      "state": "Maharashtra"
    },
    {
      "name": "Sonamukhi",
      "state": "West Bengal"
    },
    {
      "name": "Sonamura",
      "state": "Tripura"
    },
    {
      "name": "Sonari",
      "state": "Assam"
    },
    {
      "name": "Sonarpur community development block",
      "state": "West Bengal"
    },
    {
      "name": "Sonbhadra",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sonegaon",
      "state": "Maharashtra"
    },
    {
      "name": "Sonepur",
      "state": "Odisha"
    },
    {
      "name": "Songadh",
      "state": "Gujarat"
    },
    {
      "name": "Songir",
      "state": "Maharashtra"
    },
    {
      "name": "Sonipat",
      "state": "Haryana"
    },
    {
      "name": "Sonitpur",
      "state": "Assam"
    },
    {
      "name": "Sonvad",
      "state": "Maharashtra"
    },
    {
      "name": "Sopur",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Sorab",
      "state": "Karnataka"
    },
    {
      "name": "Sorada",
      "state": "Odisha"
    },
    {
      "name": "Sorbhog",
      "state": "Assam"
    },
    {
      "name": "Soro",
      "state": "Odisha"
    },
    {
      "name": "Soron",
      "state": "Uttar Pradesh"
    },
    {
      "name": "South 24 Parganas district",
      "state": "West Bengal"
    },
    {
      "name": "South Andaman",
      "state": "Andaman and Nicobar Islands"
    },
    {
      "name": "South Delhi",
      "state": "Delhi"
    },
    {
      "name": "South District",
      "state": "Sikkim"
    },
    {
      "name": "South Garo Hills",
      "state": "Meghalaya"
    },
    {
      "name": "South Goa",
      "state": "Goa"
    },
    {
      "name": "South Tripura",
      "state": "Tripura"
    },
    {
      "name": "South West Delhi",
      "state": "Delhi"
    },
    {
      "name": "South West Garo Hills",
      "state": "Meghalaya"
    },
    {
      "name": "South West Khasi Hills",
      "state": "Meghalaya"
    },
    {
      "name": "Soygaon",
      "state": "Maharashtra"
    },
    {
      "name": "Soyibug",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Sravana Belgola",
      "state": "Karnataka"
    },
    {
      "name": "Sri Dungargarh",
      "state": "Rajasthan"
    },
    {
      "name": "Sri Madhopur",
      "state": "Rajasthan"
    },
    {
      "name": "Sri Muktsar Sahib",
      "state": "Punjab"
    },
    {
      "name": "Srikakulam",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Srikhanda",
      "state": "West Bengal"
    },
    {
      "name": "Srimushnam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Srinagar",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Srinagar",
      "state": "Uttarakhand"
    },
    {
      "name": "Sringeri",
      "state": "Karnataka"
    },
    {
      "name": "Srinivaspur",
      "state": "Karnataka"
    },
    {
      "name": "Sriperumbudur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Sriramnagar",
      "state": "Telangana"
    },
    {
      "name": "Srirampur",
      "state": "West Bengal"
    },
    {
      "name": "Srisailain",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Srivaikuntam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Srivardhan",
      "state": "Maharashtra"
    },
    {
      "name": "Srivilliputhur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Suar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Subarnapur",
      "state": "Odisha"
    },
    {
      "name": "Suchindram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Sujangarh",
      "state": "Rajasthan"
    },
    {
      "name": "Suket",
      "state": "Rajasthan"
    },
    {
      "name": "Sultanpur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Sultanpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Sultanpur",
      "state": "Uttarakhand"
    },
    {
      "name": "Sultanpur Lodhi",
      "state": "Punjab"
    },
    {
      "name": "Sulur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Suluru",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Sulya",
      "state": "Karnataka"
    },
    {
      "name": "Sumbal",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Sunam",
      "state": "Punjab"
    },
    {
      "name": "Sundargarh",
      "state": "Odisha"
    },
    {
      "name": "Sundarnagar",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Sunel",
      "state": "Rajasthan"
    },
    {
      "name": "Suntikoppa",
      "state": "Karnataka"
    },
    {
      "name": "Supaul",
      "state": "Bihar"
    },
    {
      "name": "Supedi",
      "state": "Gujarat"
    },
    {
      "name": "Surajgarh",
      "state": "Rajasthan"
    },
    {
      "name": "Surandai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Surat",
      "state": "Gujarat"
    },
    {
      "name": "Suratgarh",
      "state": "Rajasthan"
    },
    {
      "name": "Surendranagar",
      "state": "Gujarat"
    },
    {
      "name": "Surgana",
      "state": "Maharashtra"
    },
    {
      "name": "Surguja",
      "state": "Chhattisgarh"
    },
    {
      "name": "Suri",
      "state": "West Bengal"
    },
    {
      "name": "Surianwan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Suriapet",
      "state": "Telangana"
    },
    {
      "name": "Susner",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Sutrapada",
      "state": "Gujarat"
    },
    {
      "name": "Swamimalai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Swarupnagar community development block",
      "state": "West Bengal"
    },
    {
      "name": "Tadepalle",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Tadepallegudem",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Tadpatri",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Tajpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Takdah",
      "state": "West Bengal"
    },
    {
      "name": "Takhatgarh",
      "state": "Rajasthan"
    },
    {
      "name": "Takhatpur",
      "state": "Chhattisgarh"
    },
    {
      "name": "Taki",
      "state": "West Bengal"
    },
    {
      "name": "Taklibhan",
      "state": "Maharashtra"
    },
    {
      "name": "Tal",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Talaja",
      "state": "Gujarat"
    },
    {
      "name": "Talbahat",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Talbid",
      "state": "Maharashtra"
    },
    {
      "name": "Talcher",
      "state": "Odisha"
    },
    {
      "name": "Talegaon Dabhade",
      "state": "Maharashtra"
    },
    {
      "name": "Talegaon Dhamdhere",
      "state": "Maharashtra"
    },
    {
      "name": "Taleigao",
      "state": "Goa"
    },
    {
      "name": "Talen",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Talgram",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Talikota",
      "state": "Karnataka"
    },
    {
      "name": "Talipparamba",
      "state": "Kerala"
    },
    {
      "name": "Taloda",
      "state": "Maharashtra"
    },
    {
      "name": "Talode",
      "state": "Maharashtra"
    },
    {
      "name": "Talwandi Bhai",
      "state": "Punjab"
    },
    {
      "name": "Talwara",
      "state": "Punjab"
    },
    {
      "name": "Tambaram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tamenglong",
      "state": "Manipur"
    },
    {
      "name": "Tamluk",
      "state": "West Bengal"
    },
    {
      "name": "Tanakpur",
      "state": "Uttarakhand"
    },
    {
      "name": "Tanda",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Tandur",
      "state": "Telangana"
    },
    {
      "name": "Tanjore",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tankara",
      "state": "Gujarat"
    },
    {
      "name": "Tanuku",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Taoru",
      "state": "Haryana"
    },
    {
      "name": "Tapi",
      "state": "Gujarat"
    },
    {
      "name": "Tarabha",
      "state": "Odisha"
    },
    {
      "name": "Tarakeswar",
      "state": "West Bengal"
    },
    {
      "name": "Taramangalam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tarana",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Taranagar",
      "state": "Rajasthan"
    },
    {
      "name": "Tarapur",
      "state": "Maharashtra"
    },
    {
      "name": "Tardeo",
      "state": "Maharashtra"
    },
    {
      "name": "Tarikere",
      "state": "Karnataka"
    },
    {
      "name": "Tarn Taran Sahib",
      "state": "Punjab"
    },
    {
      "name": "Tasgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Tattayyangarpettai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tawang",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Teghra",
      "state": "Bihar"
    },
    {
      "name": "Tehri",
      "state": "Uttarakhand"
    },
    {
      "name": "Tehri-Garhwal",
      "state": "Uttarakhand"
    },
    {
      "name": "Tekanpur",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Tekari",
      "state": "Bihar"
    },
    {
      "name": "Tekkalakote",
      "state": "Karnataka"
    },
    {
      "name": "Tekkali",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Telhara",
      "state": "Maharashtra"
    },
    {
      "name": "Tellicherry",
      "state": "Kerala"
    },
    {
      "name": "Tendukheda",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Teonthar",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Terdal",
      "state": "Karnataka"
    },
    {
      "name": "Terha",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Tezpur",
      "state": "Assam"
    },
    {
      "name": "Tezu",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Thakurdwara",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Thakurganj",
      "state": "Bihar"
    },
    {
      "name": "Thalner",
      "state": "Maharashtra"
    },
    {
      "name": "Than",
      "state": "Gujarat"
    },
    {
      "name": "Thana Bhawan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Thandla",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Thane",
      "state": "Maharashtra"
    },
    {
      "name": "Thanesar",
      "state": "Haryana"
    },
    {
      "name": "Thang",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Thangadh",
      "state": "Gujarat"
    },
    {
      "name": "Thanjavur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Thanna Mandi",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Thanniyam",
      "state": "Kerala"
    },
    {
      "name": "Tharad",
      "state": "Gujarat"
    },
    {
      "name": "Tharangambadi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Thasra",
      "state": "Gujarat"
    },
    {
      "name": "The Dangs",
      "state": "Gujarat"
    },
    {
      "name": "Theni",
      "state": "Tamil Nadu"
    },
    {
      "name": "Thenkasi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Thenzawl",
      "state": "Mizoram"
    },
    {
      "name": "Theog",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Thirukattupalli",
      "state": "Tamil Nadu"
    },
    {
      "name": "Thiruthani",
      "state": "Tamil Nadu"
    },
    {
      "name": "Thiruvaiyaru",
      "state": "Tamil Nadu"
    },
    {
      "name": "Thiruvallur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Thiruvananthapuram",
      "state": "Kerala"
    },
    {
      "name": "Thiruvarur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Thiruvidaimaruthur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Thoothukudi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Thoubal",
      "state": "Manipur"
    },
    {
      "name": "Thrissur",
      "state": "Kerala"
    },
    {
      "name": "Thrissur District",
      "state": "Kerala"
    },
    {
      "name": "Tigri",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Tijara",
      "state": "Rajasthan"
    },
    {
      "name": "Tikaitnagar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Tikamgarh",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Tikri",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Tilhar",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Tilsahri",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Tindivanam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tindwari",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Tinnanur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tinsukia",
      "state": "Assam"
    },
    {
      "name": "Tiptur",
      "state": "Karnataka"
    },
    {
      "name": "Tira Sujanpur",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Tirap",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Tirodi",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Tirthahalli",
      "state": "Karnataka"
    },
    {
      "name": "Tiruchchendur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tiruchengode",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tiruchirappalli",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tirukkoyilur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tirumakudal Narsipur",
      "state": "Karnataka"
    },
    {
      "name": "Tirumala",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Tirumullaivasal",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tirunelveli",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tirunelveli Kattabo",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tirupati",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Tiruppalaikudi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tirupparangunram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tiruppur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tiruppuvanam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tirur",
      "state": "Kerala"
    },
    {
      "name": "Tiruttangal",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tiruvalla",
      "state": "Kerala"
    },
    {
      "name": "Tiruvannamalai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tiruvottiyur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tisaiyanvilai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Titabar",
      "state": "Assam"
    },
    {
      "name": "Titagarh",
      "state": "West Bengal"
    },
    {
      "name": "Titlagarh",
      "state": "Odisha"
    },
    {
      "name": "Titron",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Todabhim",
      "state": "Rajasthan"
    },
    {
      "name": "Todaraisingh",
      "state": "Rajasthan"
    },
    {
      "name": "Tohana",
      "state": "Haryana"
    },
    {
      "name": "Tondi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Tonk",
      "state": "Rajasthan"
    },
    {
      "name": "Topchanchi",
      "state": "Jharkhand"
    },
    {
      "name": "Tori Fatehpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Tori-Fatehpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Tosham",
      "state": "Haryana"
    },
    {
      "name": "Tral",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Trimbak",
      "state": "Maharashtra"
    },
    {
      "name": "Trombay",
      "state": "Maharashtra"
    },
    {
      "name": "Tsrar Sharif",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Tuensang",
      "state": "Nagaland"
    },
    {
      "name": "Tuensang District",
      "state": "Nagaland"
    },
    {
      "name": "Tufanganj",
      "state": "West Bengal"
    },
    {
      "name": "Tulin",
      "state": "West Bengal"
    },
    {
      "name": "Tuljapur",
      "state": "Maharashtra"
    },
    {
      "name": "Tulsipur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Tumakuru",
      "state": "Karnataka"
    },
    {
      "name": "Tumsar",
      "state": "Maharashtra"
    },
    {
      "name": "Tundla",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Tuni",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Tura",
      "state": "Meghalaya"
    },
    {
      "name": "Turaiyur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Turuvekere",
      "state": "Karnataka"
    },
    {
      "name": "Uchalan",
      "state": "West Bengal"
    },
    {
      "name": "Udaipur",
      "state": "Rajasthan"
    },
    {
      "name": "Udaipur",
      "state": "Tripura"
    },
    {
      "name": "Udaipura",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Udalguri",
      "state": "Assam"
    },
    {
      "name": "Udangudi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Udayagiri",
      "state": "Odisha"
    },
    {
      "name": "Udgir",
      "state": "Maharashtra"
    },
    {
      "name": "Udham Singh Nagar",
      "state": "Uttarakhand"
    },
    {
      "name": "Udhampur",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Udpura",
      "state": "Rajasthan"
    },
    {
      "name": "Udumalaippettai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Udupi",
      "state": "Karnataka"
    },
    {
      "name": "Ugu",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ujhani",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Ujjain",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Ukhrul",
      "state": "Manipur"
    },
    {
      "name": "Uklana",
      "state": "Haryana"
    },
    {
      "name": "Ukwa",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Ula",
      "state": "West Bengal"
    },
    {
      "name": "Ulhasnagar",
      "state": "Maharashtra"
    },
    {
      "name": "Ullal",
      "state": "Karnataka"
    },
    {
      "name": "Uluberia",
      "state": "West Bengal"
    },
    {
      "name": "Umarga",
      "state": "Maharashtra"
    },
    {
      "name": "Umaria",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Umaria District",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Umarkhed",
      "state": "Maharashtra"
    },
    {
      "name": "Umarkot",
      "state": "Chhattisgarh"
    },
    {
      "name": "Umarpada",
      "state": "Gujarat"
    },
    {
      "name": "Umrala",
      "state": "Gujarat"
    },
    {
      "name": "Umred",
      "state": "Maharashtra"
    },
    {
      "name": "Umreth",
      "state": "Gujarat"
    },
    {
      "name": "Umri",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Umri",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Un",
      "state": "Gujarat"
    },
    {
      "name": "Un",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Una",
      "state": "Gujarat"
    },
    {
      "name": "Una",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Unakoti",
      "state": "Tripura"
    },
    {
      "name": "Unhel",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Uniara",
      "state": "Rajasthan"
    },
    {
      "name": "Unjha",
      "state": "Gujarat"
    },
    {
      "name": "Unnao",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Upleta",
      "state": "Gujarat"
    },
    {
      "name": "Uppal Kalan",
      "state": "Telangana"
    },
    {
      "name": "Upper Siang",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Upper Subansiri",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Uppiliyapuram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Uran",
      "state": "Maharashtra"
    },
    {
      "name": "Uravakonda",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Uri",
      "state": "Jammu and Kashmir"
    },
    {
      "name": "Uruli Kanchan",
      "state": "Maharashtra"
    },
    {
      "name": "Usawan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Usehat",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Usilampatti",
      "state": "Tamil Nadu"
    },
    {
      "name": "Uska",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Utran",
      "state": "Gujarat"
    },
    {
      "name": "Utraula",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Uttamapalaiyam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Uttar Bastar Kanker",
      "state": "Chhattisgarh"
    },
    {
      "name": "Uttar Dinajpur district",
      "state": "West Bengal"
    },
    {
      "name": "Uttar Kannada",
      "state": "Karnataka"
    },
    {
      "name": "Uttarkashi",
      "state": "Uttarakhand"
    },
    {
      "name": "Uttarpara Kotrung",
      "state": "West Bengal"
    },
    {
      "name": "Uttiramerur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Uttukkuli",
      "state": "Tamil Nadu"
    },
    {
      "name": "V.S.K.Valasai (Dindigul-Dist.)",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vada",
      "state": "Maharashtra"
    },
    {
      "name": "Vadakku Valliyur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vadakku Viravanallur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vadamadurai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vadgam",
      "state": "Gujarat"
    },
    {
      "name": "Vadgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Vadigenhalli",
      "state": "Karnataka"
    },
    {
      "name": "Vadippatti",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vadlapudi",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Vadnagar",
      "state": "Gujarat"
    },
    {
      "name": "Vadner",
      "state": "Maharashtra"
    },
    {
      "name": "Vadodara",
      "state": "Gujarat"
    },
    {
      "name": "Vagator",
      "state": "Goa"
    },
    {
      "name": "Vaghodia",
      "state": "Gujarat"
    },
    {
      "name": "Vaghodia INA",
      "state": "Gujarat"
    },
    {
      "name": "Vaijapur",
      "state": "Maharashtra"
    },
    {
      "name": "Vaikam",
      "state": "Kerala"
    },
    {
      "name": "Vairag",
      "state": "Maharashtra"
    },
    {
      "name": "Vaishali",
      "state": "Bihar"
    },
    {
      "name": "Valangaiman",
      "state": "Tamil Nadu"
    },
    {
      "name": "Valavanur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vallabh Vidyanagar",
      "state": "Gujarat"
    },
    {
      "name": "Vallabhipur",
      "state": "Gujarat"
    },
    {
      "name": "Vallam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Valparai",
      "state": "Tamil Nadu"
    },
    {
      "name": "Valpoy",
      "state": "Goa"
    },
    {
      "name": "Valsad",
      "state": "Gujarat"
    },
    {
      "name": "Valsang",
      "state": "Maharashtra"
    },
    {
      "name": "Vanala",
      "state": "Gujarat"
    },
    {
      "name": "Vandalur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vandavasi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vangaon",
      "state": "Maharashtra"
    },
    {
      "name": "Vaniyambadi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vansda",
      "state": "Gujarat"
    },
    {
      "name": "Vanthli",
      "state": "Gujarat"
    },
    {
      "name": "Vapi",
      "state": "Gujarat"
    },
    {
      "name": "Varanasi",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Varangaon",
      "state": "Maharashtra"
    },
    {
      "name": "Varca",
      "state": "Goa"
    },
    {
      "name": "Varkala",
      "state": "Kerala"
    },
    {
      "name": "Vartej",
      "state": "Gujarat"
    },
    {
      "name": "Vasa",
      "state": "Gujarat"
    },
    {
      "name": "Vasavad",
      "state": "Gujarat"
    },
    {
      "name": "Vasco da Gama",
      "state": "Goa"
    },
    {
      "name": "Vashi",
      "state": "Maharashtra"
    },
    {
      "name": "Vasind",
      "state": "Maharashtra"
    },
    {
      "name": "Vaso",
      "state": "Gujarat"
    },
    {
      "name": "Vasudevanallur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vataman",
      "state": "Gujarat"
    },
    {
      "name": "Vattalkundu",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vatul",
      "state": "Maharashtra"
    },
    {
      "name": "Vayalar",
      "state": "Kerala"
    },
    {
      "name": "Vedaraniyam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vedasandur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vejalpur",
      "state": "Gujarat"
    },
    {
      "name": "Velankanni",
      "state": "Tamil Nadu"
    },
    {
      "name": "Velas, Maharashtra",
      "state": "Maharashtra"
    },
    {
      "name": "Vellanur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vellore",
      "state": "Tamil Nadu"
    },
    {
      "name": "Velneshwar",
      "state": "Maharashtra"
    },
    {
      "name": "Velur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vemalwada",
      "state": "Telangana"
    },
    {
      "name": "Vengavasal",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vengurla",
      "state": "Maharashtra"
    },
    {
      "name": "Venkatagiri",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Vepagunta",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Veraval",
      "state": "Gujarat"
    },
    {
      "name": "Vetapalem",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Vettaikkaranpudur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vettavalam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vettur",
      "state": "Kerala"
    },
    {
      "name": "Vidisha",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Vijapur",
      "state": "Gujarat"
    },
    {
      "name": "Vijayapura",
      "state": "Karnataka"
    },
    {
      "name": "Vijayapuri",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vijayawada",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Vijaydurg",
      "state": "Maharashtra"
    },
    {
      "name": "Vikarabad",
      "state": "Telangana"
    },
    {
      "name": "Vikasnagar",
      "state": "Uttarakhand"
    },
    {
      "name": "Vikhroli",
      "state": "Maharashtra"
    },
    {
      "name": "Vikravandi",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vilattikulam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vile Parle",
      "state": "Maharashtra"
    },
    {
      "name": "Villupuram",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vinchhiya",
      "state": "Gujarat"
    },
    {
      "name": "Vinchia",
      "state": "Gujarat"
    },
    {
      "name": "Vinchur",
      "state": "Maharashtra"
    },
    {
      "name": "Vindhyachal",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Vinukonda",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Viraganur",
      "state": "Tamil Nadu"
    },
    {
      "name": "Viramgam",
      "state": "Gujarat"
    },
    {
      "name": "Virar",
      "state": "Maharashtra"
    },
    {
      "name": "Virarajendrapet",
      "state": "Karnataka"
    },
    {
      "name": "Virpur",
      "state": "Gujarat"
    },
    {
      "name": "Virudhunagar",
      "state": "Tamil Nadu"
    },
    {
      "name": "Visakhapatnam",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Visavadar",
      "state": "Gujarat"
    },
    {
      "name": "Visnagar",
      "state": "Gujarat"
    },
    {
      "name": "Vita, Maharashtra",
      "state": "Maharashtra"
    },
    {
      "name": "Vite",
      "state": "Maharashtra"
    },
    {
      "name": "Vizianagaram",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Vizianagaram District",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Vriddhachalam",
      "state": "Tamil Nadu"
    },
    {
      "name": "Vrindavan",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Vuyyuru",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Vyara",
      "state": "Gujarat"
    },
    {
      "name": "Wadala",
      "state": "Maharashtra"
    },
    {
      "name": "Wadgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Wadhai",
      "state": "Gujarat"
    },
    {
      "name": "Wadhwan",
      "state": "Gujarat"
    },
    {
      "name": "Wadi",
      "state": "Karnataka"
    },
    {
      "name": "Wadner",
      "state": "Maharashtra"
    },
    {
      "name": "Wadwani",
      "state": "Maharashtra"
    },
    {
      "name": "Waghai",
      "state": "Gujarat"
    },
    {
      "name": "Wagholi",
      "state": "Maharashtra"
    },
    {
      "name": "Wai",
      "state": "Maharashtra"
    },
    {
      "name": "Wakad",
      "state": "Maharashtra"
    },
    {
      "name": "Walajapet",
      "state": "Tamil Nadu"
    },
    {
      "name": "Walgaon",
      "state": "Maharashtra"
    },
    {
      "name": "Walki",
      "state": "Maharashtra"
    },
    {
      "name": "Wallajahbad",
      "state": "Tamil Nadu"
    },
    {
      "name": "Walterganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Wangjing",
      "state": "Manipur"
    },
    {
      "name": "Wani",
      "state": "Maharashtra"
    },
    {
      "name": "Wankaner",
      "state": "Gujarat"
    },
    {
      "name": "Wanparti",
      "state": "Telangana"
    },
    {
      "name": "Warangal",
      "state": "Telangana"
    },
    {
      "name": "Waraseoni",
      "state": "Madhya Pradesh"
    },
    {
      "name": "Wardha",
      "state": "Maharashtra"
    },
    {
      "name": "Waris Aliganj",
      "state": "Bihar"
    },
    {
      "name": "Warora",
      "state": "Maharashtra"
    },
    {
      "name": "Warud",
      "state": "Maharashtra"
    },
    {
      "name": "Washim",
      "state": "Maharashtra"
    },
    {
      "name": "Wayanad",
      "state": "Kerala"
    },
    {
      "name": "Wazirganj",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Wellington",
      "state": "Tamil Nadu"
    },
    {
      "name": "Wer",
      "state": "Rajasthan"
    },
    {
      "name": "West Delhi",
      "state": "Delhi"
    },
    {
      "name": "West District",
      "state": "Sikkim"
    },
    {
      "name": "West Garo Hills",
      "state": "Meghalaya"
    },
    {
      "name": "West Godavari",
      "state": "Andhra Pradesh"
    },
    {
      "name": "West Jaintia Hills",
      "state": "Meghalaya"
    },
    {
      "name": "West Kameng",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "West Khasi Hills",
      "state": "Meghalaya"
    },
    {
      "name": "West Siang",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "West Tripura",
      "state": "Tripura"
    },
    {
      "name": "Wokha",
      "state": "Nagaland"
    },
    {
      "name": "Worli",
      "state": "Maharashtra"
    },
    {
      "name": "Yadgir",
      "state": "Karnataka"
    },
    {
      "name": "Yairipok",
      "state": "Manipur"
    },
    {
      "name": "Yamunanagar",
      "state": "Haryana"
    },
    {
      "name": "Yanam",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Yanam",
      "state": "Puducherry"
    },
    {
      "name": "Yanamalakuduru",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Yarada",
      "state": "Andhra Pradesh"
    },
    {
      "name": "Yaval",
      "state": "Maharashtra"
    },
    {
      "name": "Yavatmal",
      "state": "Maharashtra"
    },
    {
      "name": "Yelahanka",
      "state": "Karnataka"
    },
    {
      "name": "Yelandur",
      "state": "Karnataka"
    },
    {
      "name": "Yelbarga",
      "state": "Karnataka"
    },
    {
      "name": "Yellandu",
      "state": "Telangana"
    },
    {
      "name": "Yellapur",
      "state": "Karnataka"
    },
    {
      "name": "Yeola",
      "state": "Maharashtra"
    },
    {
      "name": "Yol",
      "state": "Himachal Pradesh"
    },
    {
      "name": "Yusufpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Zafarabad",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Zahirabad",
      "state": "Telangana"
    },
    {
      "name": "Zaidpur",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Zamania",
      "state": "Uttar Pradesh"
    },
    {
      "name": "Zira",
      "state": "Punjab"
    },
    {
      "name": "Ziro",
      "state": "Arunachal Pradesh"
    },
    {
      "name": "Zunheboto",
      "state": "Nagaland"
    },
    {
      "name": "patamda",
      "state": "Jharkhand"
    },
    {
      "name": "pedda nakkalapalem",
      "state": "Andhra Pradesh"
    },
    {
      "name": "vadlamuru",
      "state": "Andhra Pradesh"
    }
  ];