import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import styles from "./Job.module.scss";
import AutoCompleteComponent from "../../components/common/AutoCompleteComponent";
import { cities } from "./CityStateName";
// import { Country, State, City } from 'country-state-city';
import veriryIcon from "../../assets/icons/successIcon.svg";
import { serviceProvider } from "../../provider/serviceProvider";
import { toast } from "react-toastify";
import EditorNew from "../../components/common/EditorNew";
import ButtonComponent from "../../components/common/ButtonComponent";
import { sessionUtility } from "../../utility/sessionUtility";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const initialUserInput = {
  jobTitle: "",
    jobType: "",
    location: [""],
    custUrl: "",
    experienceMin: "",
    experienceMinYears: "",
    experienceMinMonths: "",
    experienceMaxYears: "",
    experienceMaxMonths: "",
    experienceMax: "",
    description: "",
    salaryExpectation: "",
    showSalary: true,
};
// Sample job types for dropdown
const jobTypes = [
  { value: "FULL_TIME", label: "Full Time" },
  { value: "PART_TIME", label: "Part Time" },
  { value: "CONTRACT_BASED", label: "Contract Based" },
  { value: "INTERNSHIP", label: "Internship" },
  { value: "FREELANCE", label: "Freelance" },
];

const PostJob = () => {
  const [formData, setFormData] = useState({
    jobTitle: "",
    jobType: "",
    location: [""],
    custUrl: "",
    experienceMin: "",
    experienceMinYears: "",
    experienceMinMonths: "",
    experienceMaxYears: "",
    experienceMaxMonths: "",
    experienceMax: "",
    description: "",
    salaryExpectation: "",
    showSalary: true,
  });
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [loaders, setLoaders] = useState<any>({
      postJob:false,
      verfiyCustomUrl:false,
    });
const [isCustomUrlVerified, setIsCustomUrlVerified] =
    useState<boolean>(false);
      const [customUrl, setCustomUrl] = useState("");
  const handleInputChange = (name: string, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleMinExperienceChange = (field: string, value: string) => {
    let numMinValue = parseInt(value, 10) || 0;
  
    setFormData((prev) => {
      let minYears = parseInt(prev.experienceMinYears, 10) || 0;
      let minMonths = parseInt(prev.experienceMinMonths, 10) || 0;
      
  
      if (field === "experienceMinMonths") {
        minMonths = numMinValue;
        if (minMonths >= 12) {
          minYears += Math.floor(minMonths / 12);
          minMonths = minMonths % 12;
        }
      } else if (field === "experienceMinYears") {
        minYears = numMinValue;
      }
      return {
        ...prev,
        experienceMin: `${minYears}.${minMonths}`, // Keep final format consistent
        
      };
    });
  };
  const handleMaxExperienceChange = (field: string, value: string) => {
    let numMaxValue = parseInt(value, 10) || 0;
  
    setFormData((prev) => {
      
      let maxYears = parseInt(prev.experienceMaxYears, 10) || 0;
      let maxMonths = parseInt(prev.experienceMaxMonths, 10) || 0;
   if(field === "experienceMaxMonths"){
        maxMonths = numMaxValue;
        if (maxMonths >= 12) {
          maxYears += Math.floor(maxMonths / 12);
          maxMonths = maxMonths % 12;
        }
      }else if(field === "experienceMaxYears"){
        maxYears = numMaxValue;
      }
  
      return {
        ...prev,
        experienceMax: `${maxYears}.${maxMonths}`, // Keep final format consistent
      };
    });
  };
  
  
    const uId = sessionUtility.getAuthentication().session?.uId as string;
    const navigate = useNavigate();
  // Function to verify Custom URL
    const customUrlVerify = async (customUrl: string) => {
      setLoaders((prev:any)=>({ ...prev, verfiyCustomUrl: true }));
      try {
        const param = `customurls/valid/JOB?customUrl=${customUrl}`;
        const res: any = await serviceProvider.blogService.checkCustomUrl(param);
        if (res?.httpStatus === 200) {
          // setUrlVerified(true);
          setIsCustomUrlVerified(true);
          toast.success(res?.result || "Custom url verified.");
        } else {
          toast.error(res?.error?.message || "Something went wrong!");
        }
      } catch (err: any) {
        toast.error(err?.data?.error?.message || "Something went wrong!");
      } finally {
        setLoaders((prev:any)=>({ ...prev, verfiyCustomUrl: false }));
      }
    };

  // Handle form submission
   const handleSubmit = () => {
     if (
       !formData.jobTitle ||
       !formData.jobType ||
       !formData.location ||
       !formData.experienceMin ||
       !formData.experienceMax ||
       !formData.description 
       
     ) {
       setErrors({
         ...errors,
         // Set error messages for each field that is empty
         jobTitle: !formData.jobTitle ? "Job title is required" : "",
         jobType: !formData.jobType ? "Job type is required" : "",
         location: !formData.location[0] ? "Job Location is required" : "",
         experienceMin: !formData.experienceMin ? "Min experience is required" : "",
         experienceMax: !formData.experienceMax ? "Max experience is required" : "",
         description: !formData.description ? "Job description is required" : "",
       });
     } else {
       const payload = {
         ...formData,
         custUrl: isCustomUrlVerified ? customUrl : "",
       };
 
 
       if (customUrl && !isCustomUrlVerified) {
         toast.error("Please verify custom url.");
         return;
       }
 
       setLoaders((prev:any)=>({ ...prev, postBlog: true }));
       serviceProvider.jobService
         .postJob(uId,payload)
         .then((res: any) => {
           if (res?.responseCode === 200) {
             setFormData(initialUserInput);
 
             toast.success("Job Successfully saved as draft.");
            //  window.location.href = "/blogs-management?index=1";
             // window.location.reload();
             navigate("/jobs-management?index=1")
           } else {
             toast.error(res?.message || "Something went wrong!");
           }
         })
         .catch((err) => {
           toast.error(err?.data?.error?.message || "Something went wrong!");
         })
         .finally(() => {
           setLoaders((prev:any)=>({ ...prev, postJob: false }));
           
         });
     }
   };

  const [cityOptions, setCityOptions] = useState<{ name: string; state: string }[]>([]);

  useEffect(() => {
    const fetchCities = () => {
      setCityOptions(cities);
    };
    fetchCities();
  }, []); // Fetch only once when the component mounts
  
    useEffect(() => {
      setEditorLoaded(true);
    }, []);
  return (
    
      <Grid container className={styles.container}>
        <Grid container spacing={2} mb={4} className={styles.ItenaryConatiner}>
        <Grid item>
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/jobs-management")}
          sx={{
            textTransform: "none",
            color: "primary.main",
            fontWeight: "500",
          }}
        >
          Back
        </Button>
      </Grid>
          {/* Header */}
          <Grid item xs={12}>
            <Typography variant="h5" component="h1">
              Post a Job
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container className={styles.depatureArrivalAirportBox}>
              <Grid item xs={12} className={styles.border}>
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  value={formData.jobTitle}
                  onChange={(e) =>
                    handleInputChange("jobTitle", e.target.value)
                  }
                  variant="standard"
                  required
                  placeholder="Job Title"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>
              
            </Grid>
            {errors.jobTitle && (
      <span
        className="modalError"
      >
        {errors.jobTitle}
      </span>
    )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container className={styles.depatureArrivalAirportBox}>
              <Grid item xs={12} className={styles.border}>
              <AutoCompleteComponent
  options={jobTypes}
  getOptionLabel={(option) => option.label || ""} // Display label in the dropdown
  value={jobTypes.find((job) => job.value === formData.jobType) || null} // Match value to show the correct label
  onChange={(e, newValue) => {
    handleInputChange("jobType", newValue?.value || ""); // Pass the value to formData
  }}
  required
  placeholder="Job Type"
  freeSolo={false} // Disallow typing, only options should be selectable
/>

              </Grid>
            </Grid>
            {errors.jobType && (
      <span
        className="modalError"
      >
        {errors.jobType}
      </span>
    )}
          </Grid>

          {/* Location */}
          <Grid item xs={12} md={6}>
            <Grid container className={styles.depatureArrivalAirportBox}>
              {/* <Grid item xs={12} className={styles.border}>
              <AutoCompleteComponent
  options={cityOptions}
  getOptionLabel={(option) => {
    const label = `${option.name}, ${option.state}`;
    console.log(label); // Logs the label for debugging
    return label;
  }}
  value={
    formData.location && Array.isArray(formData.location)
      ? cityOptions.find(
          (city) => formData.location.includes(`${city.name}, ${city.state}`)
        ) || null
      : null
  }
  onChange={(e, newValue) => {
    const formattedValue = newValue
      ? `${newValue.name}, ${newValue.state}`
      : "";
    handleInputChange("location", formattedValue); // Set formatted value
  }}
  renderOption={(props, option: any) => (
    <MenuItem
      {...props}
      key={`${option.name}, ${option.state}`}
      value={`${option.name}, ${option.state}`}
    >
      {`${option.name}, ${option.state}`}
    </MenuItem>
  )}
  required
  placeholder="Location"
/>

              </Grid> */}
              
              <Grid item xs={12} className={styles.border}>
                
  <FormControl variant="standard" fullWidth required>
    <TextField
      id="location"
      name="location"
      fullWidth
      size="small"
      variant="standard"
      value={formData.location[0] || ""} // Access the first element of the array
      onChange={(e) => {
        const updatedLocation = [e.target.value]; // Wrap the value in an array
        setFormData((prevState) => ({
          ...prevState,
          location: updatedLocation,
        })); // Update the formData state
      }}
      placeholder="Enter Location"
      InputProps={{
        disableUnderline: true,
      }}
    />
  </FormControl>
  
</Grid>

            </Grid>
            {errors.location && (
      <span
        className="modalError"
      >
        {errors.location}
      </span>
    )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container className={styles.depatureArrivalAirportBox}>
              <Grid item xs={12} className={styles.border}>
                <TextField
                  fullWidth
                  size="small"
                  variant="standard"
                  value={customUrl}
                  onChange={(e) => {
                    setIsCustomUrlVerified(false);
                    setCustomUrl(e.target.value);
                  }}
                  placeholder="Enter custom url"
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <Button
                        className="common-button-underlined"
                        onClick={() => customUrlVerify(customUrl)} // Replace handleVerify with your verify logic function
                        sx={{ padding: "0" }}
                        disabled={isCustomUrlVerified || loaders.verfiyCustomUrl}
                      >
                        <p
                          style={{
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                            paddingLeft:"20px"
                          }}
                        >
                          <span>
                            {isCustomUrlVerified
                              ? "Verified"
                              : "Verify"}
                          </span>
                          {isCustomUrlVerified ? (
                            <img src={veriryIcon} alt="verify-icon" />
                          ) : (
                            ""
                          )}
                        </p>
                      </Button>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
          <Grid container className={styles.depatureArrivalAirportBox} alignItems="center">
  {/* Label */}
  <Grid item xs={4}>
    <Typography className={styles.label} sx={{color:"rgba(0,0,0,0.35)"}}>Enter Min Experience</Typography>
  </Grid>

  {/* Years Dropdown */}
  <Grid item xs={4} className={styles.border}>
    <TextField
      select
      fullWidth
      size="small"
      variant="standard"
      value={formData.experienceMinYears}
      onChange={(e) => handleMinExperienceChange("experienceMinYears", e.target.value)}
      InputProps={{
        disableUnderline: true,
      }}
    >
      {[...Array(41)].map((_, i) => (
        <MenuItem key={i} value={i.toString()}>
          {i} Years
        </MenuItem>
      ))}
    </TextField>
  </Grid>

  {/* Months Dropdown */}
  <Grid item xs={4} className={styles.border}>
    <TextField
      select
      fullWidth
      size="small"
      variant="standard"
      value={formData.experienceMinMonths}
      onChange={(e) => handleMinExperienceChange("experienceMinMonths", e.target.value)}
      InputProps={{
        disableUnderline: true,
      }}
    >
      {[...Array(11)].map((_, i) => (
        <MenuItem key={i + 1} value={(i + 1).toString()}>
          {i + 1} Months
        </MenuItem>
      ))}
    </TextField>
  </Grid>
</Grid>


  {errors.experienceMin && <span className="modalError">{errors.experienceMin}</span>}
</Grid>
          <Grid item xs={12} md={6}>
          <Grid container className={styles.depatureArrivalAirportBox} alignItems="center">
  {/* Label */}
  <Grid item xs={4}>
    <Typography className={styles.label} sx={{color:"rgba(0,0,0,0.35)"}}>Enter Max Experience:</Typography>
  </Grid>

  {/* Years Dropdown */}
  <Grid item xs={4} className={styles.border}>
    <TextField
      select
      fullWidth
      size="small"
      variant="standard"
      value={formData.experienceMaxYears}
      onChange={(e) => handleMaxExperienceChange("experienceMaxYears", e.target.value)}
      InputProps={{
        disableUnderline: true,
      }}
    >
      {[...Array(41)].map((_, i) => (
        <MenuItem key={i} value={i.toString()}>
          {i} Years
        </MenuItem>
      ))}
    </TextField>
  </Grid>

  {/* Months Dropdown */}
  <Grid item xs={4} className={styles.border}>
    <TextField
      select
      fullWidth
      size="small"
      variant="standard"
      value={formData.experienceMaxMonths}
      onChange={(e) => handleMaxExperienceChange("experienceMaxMonths", e.target.value)}
      InputProps={{
        disableUnderline: true,
      }}
    >
      {[...Array(11)].map((_, i) => (
        <MenuItem key={i + 1} value={(i + 1).toString()}>
          {i + 1} Months
        </MenuItem>
      ))}
    </TextField>
  </Grid>
</Grid>


  {errors.experienceMax && <span className="modalError">{errors.experienceMax}</span>}
</Grid>
          

          {/* <Grid item xs={12} md={6}>
            <Grid container className={styles.depatureArrivalAirportBox}>
              <Grid item xs={12} className={styles.border}>
              <TextField
                  fullWidth
                  size="small"
                  type="number"
                  variant="standard"
                  value={formData.experienceMax}
                  onChange={(e) =>
                    handleInputChange("experienceMax", e.target.value)
                  }
                  required
                  placeholder="Max Experience (Write in Years.Month)"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>
            </Grid>
            {errors.experienceMax && (
      <span
        className="modalError"
      >
        {errors.experienceMax}
      </span>
    )}
          </Grid> */}
<Grid item xs={12} md={6}>
          <Grid container className={styles.depatureArrivalAirportBox}>
          <Grid item xs={12} className={styles.border}>
            <TextField
              fullWidth
              size="small"
              type="number"
              variant="standard"
              value={formData.salaryExpectation}
              onChange={(e) =>
                handleInputChange("salaryExpectation", e.target.value)
              }
              required
              placeholder="Salary (in LPA)"
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Grid>
          </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.showSalary}
                  onChange={(e) =>
                    handleInputChange("showSalary", e.target.checked)
                  }
                  name="showSalary"
                />
              }
              label="Show Salary to Candidates"
            />
          </Grid>
          {/* Description */}
          <Grid item xs={12}>
            <Grid container className={styles.depatureArrivalAirportBox}>
              <Grid item xs={12} className={styles.border}>
              <EditorNew
                        editorLoaded={editorLoaded}
                        value={formData.description}
                        onChange={(data: string) => {
                          
                          setFormData({ ...formData, description: data });
                        }}
                       
                        placeholder="Write Job Description"
                      />
              </Grid>
            </Grid>
            {errors.description && (
      <span
        className="modalError"
      >
        {errors.description}
      </span>
    )}
          </Grid>
          <Grid item xs={12}>
            <ButtonComponent
              loader={loaders.postJob}
              disabled={loaders.postJob}
              fullWidth={false}
              onClick={handleSubmit}
              label="Post Job"
            />
          </Grid>
        </Grid>
      </Grid>
  );
};

export default PostJob;
